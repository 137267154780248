import React, { useContext } from "react";
import { Router } from "@reach/router";
import Layout from "../components/NewLayout";

import { AssetsContext } from "../context/AssetsContext";
import SplashScreen from "./SplashScreen";

const AuthenticatedApp = () => {
  const { dashboardAssets, routes } = useContext(AssetsContext);

  return (
    <>
      {!dashboardAssets ? <SplashScreen /> : null}

      {dashboardAssets && (
        <Layout>
          <Router>
            {routes.map(
              ({ path, component: Component, children, props = {} }, index) => (
                <Component key={index} path={path} {...props}>
                  {children &&
                    children.map((child, i) => (
                      <child.component
                        key={i}
                        path={child.path}
                        {...(child.props || {})}
                      />
                    ))}
                </Component>
              )
            )}
          </Router>
        </Layout>
      )}
    </>
  );
};

export default AuthenticatedApp;
