import React, { CSSProperties } from "react";
import "./style.scss";

interface CustomCardProps {
  header: any;
  children: React.ReactNode;
  contentStyle?: CSSProperties;
  containerStyle?: CSSProperties;
}

const CustomCard = ({ children, header, contentStyle, containerStyle }: CustomCardProps) => {
  return (
    <div className="custom-card-container" style={containerStyle}>
      <div className="custom-card-header">{header}</div>
      <div className="custom-card-content" style={contentStyle}>
        {children}
      </div>
    </div>
  );
};

export { CustomCard };
