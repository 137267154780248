import React, { CSSProperties } from "react";
import { CustomText } from "./CustomText";

interface SwobbeeLabelProps {
    text: string;
    style?: any;
    textColor?: string;
    textStyle?: CSSProperties;

}

 const SwobbeeLabel = ({ text, style, textColor, textStyle }: SwobbeeLabelProps) => {
    return (
      <div style={style} className="swobbee-label">
        <CustomText text={text} style={{color: textColor, ...textStyle}}/> 
      </div>
    );
  };


  export {SwobbeeLabel}