/** @format */

import React, { useContext, useEffect } from "react";
import {
  Container,
  Grid,
  Label,
  Card,
  GridColumn,
  Icon,
  Header,
} from "semantic-ui-react";
import { navigate } from "@reach/router";

import LocationMap from "./LocationMap";
import { CustomButton } from "../CustomComponents";
import { LocationContext } from "../../context";

function Result({
  setValidSteps,
  state,
  createNewLocation,
  setCurrentStep,
  locationHashKey,
}) {
  const navigateToLocationPage = () => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("edit") && !locationHashKey) {
      navigate("/locations");
    }
  };

  useEffect(() => {
    setCurrentStep("Result");
    navigateToLocationPage();
  }, []);

  const {isLoadingPostLocation} = useContext(LocationContext)

  const saveData = (event, data) => {
    createNewLocation({ location: state, locationHashKey: locationHashKey });

    setValidSteps((current) => {
      return {
        ...current,
        LocationData: false,
        OpeningHours: false,
        ContactData: false,
      };
    });
  };

  const onClickHandler = () => {
    navigate("step3");
  };

  return (
    <>
      <Container style={{ color: "black", width: "60%" }}>
        <Grid columns="1">
          <GridColumn style={{ height: "250px" }}>
            <LocationMap
              latLng={{
                lat: state.gpsLatitude,
                lng: state.gpsLongitude,
              }}
            />
          </GridColumn>

          <GridColumn>
            <Card
              style={{ textAlign: "left" }}
              fluid
              header={
                <Header as="h2">
                  {`${state.addressStreetName} ${state.addressStreetNumber}, ${state.addressZipcode} ${state.addressCity}  `}
                  <Label content={state.state} circular color="orange" />
                </Header>
              }
              description={
                <Grid style={{ marginTop: "25px" }} columns="1">
                  <GridColumn>
                    <Grid>
                      <GridColumn
                        style={{ padding: "14px 5px 5px 14px" }}
                        width="1"
                      >
                        <Icon name="point" />
                      </GridColumn>
                      <GridColumn
                        width="15"
                        style={{ padding: "14px 5px 5px 14px" }}
                      >
                        {state.gpsLatitude}, {state.gpsLongitude}{" "}
                        <span style={{ color: "grey", fontSize: "12px" }}>
                          (latitude,longitude)
                        </span>
                      </GridColumn>
                    </Grid>
                  </GridColumn>
                  <GridColumn>
                    <Grid>
                      <GridColumn
                        style={{ padding: "5px 5px 5px 14px" }}
                        width="1"
                      >
                        <Icon name="clock" />
                      </GridColumn>
                      <GridColumn width="15">
                        <Grid>
                          <OpeningHours
                            {...{ openingTimes: state.openingTimes }}
                          />
                        </Grid>
                      </GridColumn>
                    </Grid>
                  </GridColumn>
                  <GridColumn>
                    <Grid>
                      <GridColumn
                        width="1"
                        style={{ padding: "5px 5px 5px 14px" }}
                      >
                        <Icon name="user" />
                      </GridColumn>
                      <GridColumn
                        width="15"
                        style={{ padding: "5px 5px 5px 14px" }}
                      >
                        {state.contactFirstName} {state.contactFamilyName}
                      </GridColumn>
                    </Grid>
                  </GridColumn>
                  <GridColumn>
                    <Grid>
                      <GridColumn
                        width="1"
                        style={{ padding: "5px 5px 5px 14px" }}
                      >
                        <Icon name="mail" />
                      </GridColumn>
                      <GridColumn
                        width="15"
                        style={{ padding: "5px 5px 5px 14px" }}
                      >
                        {state.contactEmail}
                      </GridColumn>
                    </Grid>
                  </GridColumn>
                  <GridColumn>
                    <Grid>
                      <GridColumn
                        width="1"
                        style={{ padding: "5px 5px 30px 14px" }}
                      >
                        <Icon name="phone" />
                      </GridColumn>
                      <GridColumn
                        width="15"
                        style={{ padding: "5px 5px 30px 14px" }}
                      >
                        {state.contactPhoneNumber}
                      </GridColumn>
                    </Grid>
                  </GridColumn>
                </Grid>
              }
              extra={
                <>
                  <CustomButton
                    color="green"
                    onClick={saveData}
                    type="submit"
                    style={{ float: "right" }}
                    title={"save"}
                    isLoading={isLoadingPostLocation}
                  />

                  <CustomButton
                    basic
                    onClick={onClickHandler}
                    style={{ float: "left" }}
                    title={"back"}
                    negative
                  />
                </>
              }
            />
          </GridColumn>
        </Grid>
      </Container>
    </>
  );
}

const OpeningHours = ({ openingTimes }) => {
  return (
    openingTimes &&
    openingTimes.map((item) => (
      <>
        <GridColumn style={{ padding: "5px 5px 5px 14px" }} width="16">
          {item.day}
        </GridColumn>
        {item.openingHoures.map((item) => (
          <GridColumn style={{ padding: "5px 5px 5px 14px" }} width="16">
            {item.start} - {item.start}
          </GridColumn>
        ))}
      </>
    ))
  );
};

export default Result;
