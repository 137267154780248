import { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSharingPoint,
  getSharingPointsMapData,
  getSharingPointsTableData,
} from "../../store/actions";

function useSharingPoints() {
  const dispatch = useDispatch();
  const [firstLoad, setFirstLoad] = useState(true);

  const socket = useSelector((state) => state.session.socket);

  const {
    sharingPointsTableData,
    sharingPointsTableError,
    sharingPointsMapError,
    sharingPointsMapData,
    sharingPointsTotal,
  } = useSelector((state) => state.sharingPoints);

  const isFetchingTableData = useSelector(
    (state) => state.sharingPoints.fetchingTableData
  );
  const isFetchingMapData = useSelector(
    (state) => state.sharingPoints.fetchingMapData
  );

  const selectSharingPointCB = useCallback(
    (hashKey) => {
      dispatch(selectSharingPoint(hashKey));
    },
    [sharingPointsMapData, dispatch]
  );

  const selectedSharingPointHash = useSelector(
    (state) => state.sharingPoints.selectedSharingPointHashKey
  );

  /** fetch data first time */
  useEffect(() => {
    if (!socket || !firstLoad) return;

    if (!sharingPointsMapData && !isFetchingMapData)
      dispatch(getSharingPointsMapData());
    if (
      !sharingPointsTableData &&
      !isFetchingTableData &&
      !sharingPointsTableError
    ) {
      dispatch(getSharingPointsTableData());
    }
    setFirstLoad(false);
  }, [
    socket,
    sharingPointsTableData,
    sharingPointsTableError,
    sharingPointsMapData,
    dispatch,
    firstLoad,
    isFetchingTableData,
    isFetchingMapData,
  ]);

  const reloadTableData = useCallback(() => {
    dispatch(getSharingPointsTableData());
  }, [dispatch]);

  return {
    isFetchingTableData,
    isFetchingMapData,
    sharingPointsMapData,
    sharingPointsTableData,
    sharingPointsTableError,
    sharingPointsMapError,
    sharingPointsTotal,
    selectedSharingPointHash,
    reloadTableData,
    selectSharingPointCB,
  };
}

export { useSharingPoints };
