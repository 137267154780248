/** @format */

import React, { useContext, useEffect, useState } from "react";
import ContactData from "./contactData";
import LocationData from "./locationData";
import OpeningHours from "./openingHours";
import Result from "./result";
import { Router, navigate } from "@reach/router";
import { Container, Step, Icon, Grid, GridColumn } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import { AxiosContext } from "../../context/AxiosContext";

const defaultStart = "00:00";
const defaultEnd = "00:00";

const opening_hours = [
  {
    day: "Monday",
    openingHoures: [{ start: defaultStart, end: defaultEnd }],
    disabled: true,
  },
  {
    day: "Tuesday",
    openingHoures: [{ start: defaultStart, end: defaultEnd }],
    disabled: true,
  },
  {
    day: "Wednesday",
    openingHoures: [{ start: defaultStart, end: defaultEnd }],
    disabled: true,
  },
  {
    day: "Thursday",
    openingHoures: [{ start: defaultStart, end: defaultEnd }],
    disabled: true,
  },
  {
    day: "Friday",
    openingHoures: [{ start: defaultStart, end: defaultEnd }],
    disabled: true,
  },
  {
    day: "Saturday",
    openingHoures: [{ start: defaultStart, end: defaultEnd }],
    disabled: true,
  },
  {
    day: "Sunday",
    openingHoures: [{ start: defaultStart, end: defaultEnd }],
    disabled: true,
  },
];

const MultiStepLocationsEditForm = ({
  isEdit,
  createNewLocation,
  locationStates,
  locationsDetailData,
  locationHashKey,
  navigateToLocationPage,
}) => {
  return (
    <MultiStepLocationsForm
      navigateToLocationPage={navigateToLocationPage}
      locationHashKey={locationHashKey}
      isEdit={isEdit}
      locationsDetailData={locationsDetailData}
      createNewLocation={createNewLocation}
      locationStates={locationStates}
    />
  );
};

const MultiStepLocationsForm = ({
  isEdit,
  createNewLocation,
  locationStates,
  locationsDetailData,
  locationHashKey,
  navigateToLocationPage,
}) => {
  const { t } = useTranslation();

  const initLocation = {
    addressCity: locationsDetailData ? locationsDetailData.city : "",
    addressCountry: locationsDetailData ? locationsDetailData.country : "",
    addressStreetName: locationsDetailData ? locationsDetailData.street : "",
    addressStreetAdditional: null,
    addressStreetNumber: locationsDetailData
      ? locationsDetailData.street_number
      : "",
    addressZipcode: locationsDetailData ? locationsDetailData.zipcode : "",
    contactTitle: locationsDetailData ? locationsDetailData.title : "",
    contactEmail: locationsDetailData ? locationsDetailData.email : "",
    contactFirstName: locationsDetailData ? locationsDetailData.first_name : "",
    contactFamilyName: locationsDetailData
      ? locationsDetailData.family_name
      : "",
    state: locationsDetailData ? locationsDetailData.state : "",
    gpsLatitude: locationsDetailData ? locationsDetailData.gps_latitude : "",
    gpsLongitude: locationsDetailData ? locationsDetailData.gps_longitude : "",
    contactPhoneNumber: locationsDetailData
      ? locationsDetailData.phone_number
      : "",
    openingTimes: locationsDetailData
      ? locationsDetailData.opening_time
        ? locationsDetailData.opening_time
        : opening_hours
      : opening_hours,
  };

  const [state, setState] = useState(initLocation);
  const [currentStep, setCurrentStep] = useState();

  const [validSteps, setValidSteps] = useState({
    LocationData: false,
    OpeningHours: false,
    ContactData: false,
  });

  return (
    <>
      <Container
        style={{ padding: "30px" }}
        fluid>
        <Grid>
          <GridColumn textAlign="center">
            <Step.Group size="tiny">
              <Step
                completed={validSteps.LocationData}
                active={currentStep === "LocationData"}>
                <Icon name="map pin" />
                <Step.Content>
                  <Step.Title>{t("locations.locationData")}</Step.Title>
                  <Step.Description>{`Enter location information`}</Step.Description>
                </Step.Content>
              </Step>
              <Step
                completed={validSteps.OpeningHours}
                disabled={!validSteps.OpeningHours}
                active={currentStep === "OpeningHours"}>
                <Icon name="clock" />
                <Step.Content>
                  <Step.Title>{t("locations.openingtimes.label")}</Step.Title>
                  <Step.Description>{`Enter Opening Hours Information`}</Step.Description>
                </Step.Content>
              </Step>
              <Step
                completed={validSteps.ContactData}
                disabled={!validSteps.ContactData}
                active={currentStep === "ContactData"}>
                <Icon name="user" />

                <Step.Content>
                  <Step.Title>{t("locations.contactData")}</Step.Title>
                  <Step.Description>{`Enter Contact Information`}</Step.Description>
                </Step.Content>
              </Step>
              <Step active={currentStep === "Result"}>
                <Step.Content>
                  <Step.Title>{t("locations.result")}</Step.Title>
                </Step.Content>
              </Step>
            </Step.Group>
          </GridColumn>
        </Grid>
      </Container>
      <Container
        fluid
        textAlign="left">
        <Router>
          <LocationData
            isEdit={isEdit}
            locationHashKey={locationHashKey}
            setValidSteps={setValidSteps}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            state={state}
            locationStates={locationStates}
            setState={setState}
            path="step1"
          />
          <OpeningHours
            navigateToLocationPage={navigateToLocationPage}
            isEdit={isEdit}
            locationHashKey={locationHashKey}
            setValidSteps={setValidSteps}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            state={state}
            setState={setState}
            path="step2"
          />
          <ContactData
            navigateToLocationPage={navigateToLocationPage}
            isEdit={isEdit}
            locationHashKey={locationHashKey}
            setValidSteps={setValidSteps}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            state={state}
            setState={setState}
            path="step3"
          />
          <Result
            navigateToLocationPage={navigateToLocationPage}
            isEdit={isEdit}
            locationHashKey={locationHashKey}
            validSteps={validSteps}
            setValidSteps={setValidSteps}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            createNewLocation={createNewLocation}
            state={state}
            setState={setState}
            path="result"
          />
        </Router>
      </Container>
    </>
  );
};

const LocationForm = (props) => {
  const { t } = useTranslation();
  const { createNewLocation, locationStates, locationHashKey } = props;
  const [locationsDetailData, setLocationsDetailData] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const {axiosInstance}=useContext(AxiosContext)
  const fetchLocationById = async (locationHashKey) => {
    const { data } = await axiosInstance.get(
      "/locations/" + locationHashKey
    );
    setLocationsDetailData(data.data);
  };

  useEffect(() => {
    if(!axiosInstance) return
    if (locationHashKey) {
      fetchLocationById(locationHashKey);
      setIsEdit(true);
    }
  }, [locationHashKey, axiosInstance]);

  const navigateToLocationPage = () => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("edit") && !locationHashKey) {
      navigate("/locations");
    }
  };

  return (
    <>
      {locationsDetailData ? (
        <MultiStepLocationsEditForm
          navigateToLocationPage={navigateToLocationPage}
          locationHashKey={locationHashKey}
          isEdit={isEdit}
          locationsDetailData={locationsDetailData}
          createNewLocation={createNewLocation}
          locationStates={locationStates}
        />
      ) : (
        <MultiStepLocationsForm
          navigateToLocationPage={navigateToLocationPage}
          isEdit={isEdit}
          locationsDetailData={locationsDetailData}
          createNewLocation={createNewLocation}
          locationStates={locationStates}
        />
      )}
    </>
  );
};

export default LocationForm;
