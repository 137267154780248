import React from "react";
import {
  Select,
  Button,
  FormField,
  Form,
  Radio,
  FormGroup,
  GridColumn,
  Grid,
} from "semantic-ui-react";
import { CustomButton } from "../../CustomComponents";

const BatteryForm = ({
  onSubmitStepTwo,
  submissions,
  setSubmissions,
  providerIds,
  batteryPoolIds,
  batteryTypeIds,
  manufacturerIds,
  regionIds,
  formStep,
  setFormStep,
}) => {
  return (
    <Grid columns="1">
      <GridColumn>
        <Form size="big">
          <FormGroup widths="equal">
            <FormField>
              <label>Read</label>

              <Radio
                defaultChecked={submissions.battery_read_permission}
                slider
                onChange={(_, { checked }) => {
                  setSubmissions({
                    ...submissions,
                    battery_read_permission: checked,
                  });
                }}
              />
            </FormField>

            <FormField>
              <label>Write</label>

              <Radio
                defaultChecked={submissions.battery_write_permission}
                slider
                onChange={(_, { checked }) => {
                  setSubmissions({
                    ...submissions,
                    battery_write_permission: checked,
                  });
                }}
              />
            </FormField>
          </FormGroup>
          <FormGroup widths="equal">
            <FormField>
              <label>Provider</label>

              <Select
                allowAdditions
                fluid
                defaultValue={submissions.provider_name}
                placeholder="Provider name"
                options={providerIds}
                onChange={(_, { value }) => {
                  setSubmissions({
                    ...submissions,
                    provider_name: value,
                  });
                }}
                search
                selection
                multiple
              />
            </FormField>
          </FormGroup>
          <FormGroup widths="equal">
            <FormField>
              <label>Pool</label>

              <Select
                allowAdditions
                fluid
                defaultValue={submissions.battery_pool}
                placeholder="Pool"
                options={batteryPoolIds}
                onChange={(_, { value }) => {
                  setSubmissions({
                    ...submissions,
                    battery_pool: value,
                  });
                }}
                search
                selection
                multiple
              />
            </FormField>

            <FormField>
              <label>Battery Typ</label>

              <Select
                allowAdditions
                fluid
                defaultValue={submissions.battery_type_name}
                placeholder="Battery Typ"
                options={batteryTypeIds}
                onChange={(_, { value }) => {
                  setSubmissions({
                    ...submissions,
                    battery_type_name: value,
                  });
                }}
                search
                selection
                multiple
              />
            </FormField>
          </FormGroup>
          <FormGroup widths="equal">
            <FormField>
              <label>Manufacturer</label>

              <Select
                allowAdditions
                fluid
                defaultValue={submissions.manufacturer_name}
                placeholder="Manufacturer"
                options={manufacturerIds}
                onChange={(_, { value }) => {
                  setSubmissions({
                    ...submissions,
                    manufacturer_name: value,
                  });
                }}
                search
                selection
                multiple
              />
            </FormField>

            <FormField>
              <label>Region</label>

              <Select
                allowAdditions
                fluid
                defaultValue={submissions.region_name}
                placeholder="Region"
                options={regionIds}
                onChange={(_, { value }) => {
                  setSubmissions({
                    ...submissions,
                    region_name: value,
                  });
                }}
                search
                selection
                multiple
              />
            </FormField>
          </FormGroup>
        </Form>
      </GridColumn>

      <GridColumn width="16">
        <CustomButton
          title="Next"
          onClick={() => onSubmitStepTwo()}
          type="button"
          primary
          style={{ width: "100%" }}
        />
      </GridColumn>
      <GridColumn width="16">
        <CustomButton
          title="Back"
          type="button"
          onClick={() => setFormStep(formStep - 1)}
          style={{ width: "100%" }}
          negative
        />
      </GridColumn>
    </Grid>
  );
};

export default BatteryForm;
