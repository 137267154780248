import React, { useEffect, useState } from "react";
import { Container, Grid } from "semantic-ui-react";
import { isAllowedToRead } from "../helper/permissions";
import FilesTable from "../components/Files/FilesTable";
import "./../assets/styles/sharing-points.scss";

interface FilesScreenProps {
  spSerialNumber?: string;
  axiosInstance: any;
  path: any;
}

const FilesScreen = (props: FilesScreenProps) => {
  const { spSerialNumber, axiosInstance } = props;
  const [uploadUrl, setUploadUrl] = useState([]);

  const [files, setFiles] = useState([]);

  const download = (dataUrl: string) => {
    var a = document.createElement("a");
    a.href = dataUrl;
    a.click();
  };

  const fetchFiles = async () => {
    try {
      let params = {
        context: "SharingPoints",
        context_hash_key: spSerialNumber,
      };
      const { data } = await axiosInstance.get("files", { params });
      setFiles(data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getDownloadFileUrl = async (body: any) => {
    try {
      let params: any = {};

      params["hash_key"] = body.hash_key;
      console.log("paramssuper", params);
      const { data } = await axiosInstance.get("files/get_download_url", {
        params,
      });
      download(data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getUploadFileUrl = async (body: any) => {
    try {
      body["context"] = "SharingPoints";
      body["context_hash_key"] = spSerialNumber;
      const { data } = await axiosInstance.post("files/get_upload_url", body);
      const newData = data.data;
      setUploadUrl(newData.uploadUrl);
    } catch (error) {
      console.log("error", error);
    }
  };

  const fileToDelete = async (body: any) => {
    try {
      let params: any = {};
      params["context_hash_key"] = spSerialNumber;
      params["hash_key"] = body.hash_key;
      await axiosInstance.delete("files", {
        params,
      });
      fetchFiles();
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  return (
    <Container fluid>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <FilesTable
              files={files}
              fileToDelete={fileToDelete}
              uploadUrl={uploadUrl}
              getUploadFileUrl={getUploadFileUrl}
              getDownloadFileUrl={getDownloadFileUrl}
              fetchFiles={fetchFiles}
            />
          </Grid.Column>
        </Grid.Row>
    </Container>
  );
};

export default FilesScreen;
