import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import { noop } from "lodash";

const propTypes = {
  isSaving: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool
};



const SaveButton = ({
  isSaving = false,
  onClick = noop,
  type = "submit",
  disabled = false,
}) => {
  return (
    <Button
      fluid
      type={type}
      icon="save"
      content={"Save"}
      primary
      loading={isSaving}
      onClick={()=>{
        onClick();
      }}
      disabled={disabled}
    />
  );
};

SaveButton.propTypes = propTypes;

export { SaveButton };
