import React, { useEffect } from "react";
import { SubNav } from "../../components/SharingPoints/SubNav";
import "./../../assets/styles/sharing-points.scss";
interface SharingPointShowScreenProps {
  location?: { pathname: string };
  spSerialNumber?: string | undefined | null;
  selectSharingPoint?: (serialNumber: string) => void;
  mapIsReady: boolean | undefined | null;
  children: React.ReactElement[];
  path: any;
}

const SharingPointShowScreen = (props: SharingPointShowScreenProps) => {
  const { location, spSerialNumber, selectSharingPoint, mapIsReady, children } =
    props;

  useEffect(() => {
    if (mapIsReady && spSerialNumber && selectSharingPoint) {
      selectSharingPoint(spSerialNumber);
    }
  }, [spSerialNumber, selectSharingPoint, mapIsReady]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className="screen screen--sharingpoints-show"
      data-testid="settings-screen"
    >
      <SubNav locationPath={location?.pathname || ""} />

      <div className="flex content">
        <div
          className="sub-router"
          style={{ boxShadow: "0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5" }}
        >
          {React.Children.map(children, (child) =>
            React.cloneElement(child, {
              spSerialNumber: spSerialNumber || undefined,
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default SharingPointShowScreen;
