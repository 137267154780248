import React, { useEffect, useState, useMemo, useContext } from "react";

import {
  Modal,
  ModalHeader,
  ModalContent,
  GridColumn,
  Grid,
  Loader,
  Step,
  Icon,
} from "semantic-ui-react";
import BatteryForm from "./stepsForm/batteriesData";
import ReservationForm from "./stepsForm/reservationData";
import StationForm from "./stepsForm/stationData";
import SubmitionConfirmation from "./stepsForm/submitionData";
import { useDispatch } from "react-redux";
import { restApiResponse } from "../../store/actions/restApiActions";
import { AxiosContext } from "../../context/AxiosContext";
import { NotifyContext } from "../../context";

const Group = ({
  batteryData,
  setIsOpen,
  isOpen,
  editData,
  groupName,
  reservationLimit,
}) => {
  const dispatch = useDispatch();
  const [formStep, setFormStep] = useState(0);
  const [userGroup, setUserGroup] = useState(0);
  const [batteryTypes, setBatteryTypes] = useState(0);
  const [isPostLoading, setIsPostLoading] = useState();
  const [userGroupOptions, setUserGroupOptions] = useState();
  const [errors, setErrors] = useState({});
  const { showErrorNotification, showSuccessNotification } =
    useContext(NotifyContext);
  const defaultValues = {
    group_name: userGroup.user_group_name ? userGroup.user_group_name : null,
    role: userGroup.allowed_to_fill_in ? userGroup.allowed_to_fill_in : null,
    parent_company: userGroup.parent_company ? userGroup.parent_company : null,
    user_group_category: userGroup.user_group_category
      ? userGroup.user_group_category
      : null,
    sharingpoints: userGroup.sharingpoints
      ? userGroup.sharingpoints.items
        ? userGroup.sharingpoints.items
        : []
      : [],
    allowed_to_fill_in: userGroup.allowed_to_fill_in
      ? userGroup.allowed_to_fill_in
      : false,
    allowed_to_force_swap: userGroup.allowed_to_force_swap
      ? userGroup.allowed_to_force_swap
      : false,
    allowed_to_swap_batteries: userGroup.allowed_to_swap_batteries
      ? userGroup.allowed_to_swap_batteries
      : [],
    use_public_stations: userGroup.use_public_stations
      ? userGroup.use_public_stations
      : false,
    battery_read_permission: userGroup.batteries
      ? userGroup.batteries.access_rights
        ? userGroup.batteries.access_rights.includes("GET")
        : false
      : false,
    battery_write_permission: userGroup.batteries
      ? userGroup.batteries.access_rights
        ? userGroup.batteries.access_rights.includes("POST") ||
          userGroup.batteries.access_rights.includes("PUT") ||
          userGroup.batteries.access_rights.includes("DELETE")
        : false
      : false,
    battery_pool: userGroup.batteries
      ? userGroup.batteries.items
        ? userGroup.batteries.items.battery_pool
          ? userGroup.batteries.items.battery_pool
          : []
        : []
      : [],
    battery_type_name: userGroup.batteries
      ? userGroup.batteries.items
        ? userGroup.batteries.items.battery_type_name
          ? userGroup.batteries.items.battery_type_name
          : []
        : []
      : [],
    manufacturer_name: userGroup.batteries
      ? userGroup.batteries.items
        ? userGroup.batteries.items.manufacturer_name
          ? userGroup.batteries.items.manufacturer_name
          : []
        : []
      : [],
    provider_name: userGroup.batteries
      ? userGroup.batteries.items
        ? userGroup.batteries.items.provider_name
          ? userGroup.batteries.items.provider_name
          : []
        : []
      : [],
    region_name: userGroup.batteries
      ? userGroup.batteries.items
        ? userGroup.batteries.items.region_name
          ? userGroup.batteries.items.region_name
          : []
        : []
      : [],
    reservation_interval: userGroup.reservation_interval
      ? userGroup.reservation_interval
      : "0",
    reservation_request_limit: userGroup.reservation_request_limit
      ? userGroup.reservation_request_limit
      : "0",
    "reservation_limit_aes_ebike_akku_2+0_48v":
      userGroup.reservation_limit &&
      userGroup.reservation_limit["AES eBike Akku 2.0 48V"]
        ? userGroup.reservation_limit["AES eBike Akku 2.0 48V"]
        : "0",
    reservation_limit_greenpack:
      userGroup.reservation_limit && userGroup.reservation_limit["greenpack"]
        ? userGroup.reservation_limit["greenpack"]
        : "0",
    reservation_limit_kumpan:
      userGroup.reservation_limit && userGroup.reservation_limit["kumpan"]
        ? userGroup.reservation_limit["kumpan"]
        : "0",
    reservation_limit_okai_a:
      userGroup.reservation_limit && userGroup.reservation_limit["Okai A"]
        ? userGroup.reservation_limit["Okai A"]
        : "0",
    reservation_limit_okai_b:
      userGroup.reservation_limit && userGroup.reservation_limit["Okai B"]
        ? userGroup.reservation_limit["Okai B"]
        : "0",
    reservation_limit_segway:
      userGroup.reservation_limit && userGroup.reservation_limit["Segway"]
        ? userGroup.reservation_limit["Segway"]
        : "0",
    reservation_limit_torrot:
      userGroup.reservation_limit && userGroup.reservation_limit["torrot"]
        ? userGroup.reservation_limit["torrot"]
        : "0",
  };
  const [submissions, setSubmissions] = useState({});

  const requiredAttributes = {
    sharingpoint: ["group_name", "role"],
    reservation: ["allowed_to_swap_batteries"],
  };

  const Sharingpoints = useMemo(() => {
    return submissions.sharingpoints;
  }, [submissions]);

  useEffect(() => {
    setSubmissions(defaultValues);
  }, [userGroup]);

  const [stationIds, setStationIds] = useState([]);
  const [roles, setRoles] = useState([]);

  const [manufacturerIds, setManufacturerIds] = useState([]);
  const [batteryPoolIds, setBatteryPoolIds] = useState([]);
  const [batteryTypeIds, setBatteryTypeIds] = useState([]);
  const [regionIds, setRegionIds] = useState([]);
  const [providerIds, setProviderIds] = useState([]);
  const [publicSharingpoints, setPublicSharingpoints] = useState();
  const [ownerIds, setOwnerIds] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const { axiosInstance } = useContext(AxiosContext);
  const fetchIsPublicSharingpoints = async () => {
    let sharingpoints = [];
    const { data } = await axiosInstance.get("public_sharingpoints");
    data.data.forEach((item) => sharingpoints.push(item.sp_serial_number));
    setPublicSharingpoints(sharingpoints);
  };

  const fetchManufacturerIds = async () => {
    const { data } = await axiosInstance.get("/manufacturer_ids");
    data.data.forEach((item) => (item["value"] = item["text"]));
    setManufacturerIds(data.data);
  };
  const fetchBatteryPoolIds = async () => {
    const { data } = await axiosInstance.get("/battery_pool_ids");
    data.data.forEach((item) => (item["value"] = item["text"]));
    setBatteryPoolIds(data.data);
  };
  const fetchBatteryTypeIds = async () => {
    const { data } = await axiosInstance.get("/battery_type_ids");
    data.data.forEach((item) => (item["value"] = item["text"]));
    setBatteryTypeIds(data.data);
  };
  const fetchRegionIds = async () => {
    const { data } = await axiosInstance.get("/region_ids");
    data.data.forEach((item) => (item["value"] = item["text"]));
    setRegionIds(data.data);
  };
  const fetchProviderIds = async () => {
    const { data } = await axiosInstance.get("/provider_ids");
    data.data.forEach((item) => (item["value"] = item["text"]));
    setProviderIds(data.data);
  };
  const fetchOwnerIds = async () => {
    const { data } = await axiosInstance.get("/owner_ids");
    data.data.forEach((item) => (item["value"] = item["text"]));
    setOwnerIds(data.data);
  };
  const fetchCompanyIds = async () => {
    const { data } = await axiosInstance.get("/company_ids");
    data.data.forEach((item) => (item["value"] = item["text"]));
  };
  const fetchStationIds = async () => {
    const { data } = await axiosInstance.get(
      `/sharingpoints/sharing_point_ids`
    );
    setStationIds(data.data);
  };

  const fetchBatteryTypes = async () => {
    const { data } = await axiosInstance.get(`/battery-types/`);
    setBatteryTypes(data.data);
  };

  const fetchRoles = async () => {
    const { data } = await axiosInstance.get(
      `/roles/`
    );
    setRoles(
      data.data.map((item) => {
        return { text: item, value: item, key: item };
      })
    );
  };

  const fetchUserGroupByName = async () => {
    setIsLoading(true);
    const { data } = await axiosInstance.get(
      `/user_groups/user-groups/${groupName}`
    );
    setUserGroup(data.data);
    setIsLoading(false);
  };

  const fetchUserGroupDropdownOptions = async () => {
    const { data } = await axiosInstance.get(
      `/user_groups/user_group_dropdown`
    );
    setUserGroupOptions(data.data);
  };

  const Categories = [
    { text: "caas", value: "caas", key: "caas" },
    { text: "vaas", value: "vaas", key: "vaas" },
    { text: "baas", value: "baas", key: "baas" },
  ];

  useEffect(() => {
    fetchUserGroupDropdownOptions();
    fetchStationIds();
    fetchRoles();
    fetchManufacturerIds();
    fetchBatteryPoolIds();
    fetchBatteryTypeIds();
    fetchRegionIds();
    fetchProviderIds();
    fetchOwnerIds();
    fetchCompanyIds();
    fetchBatteryTypes();
    fetchIsPublicSharingpoints();
    if (groupName) {
      fetchUserGroupByName();
    }
  }, [editData, groupName, batteryData, reservationLimit, groupName, isOpen]);

  const mode = useMemo(() => {
    return Boolean(userGroup) ? "edit" : "create";
  }, [userGroup]);

  const onSubmitStepZero = () => {
    setErrors({});
    let error = {};
    requiredAttributes.sharingpoint.forEach((item) => {
      if (mode === "edit" && item === "role") {
        return;
      }
      if (
        !submissions[item] ||
        (Array.isArray(submissions[item]) && submissions[item].length === 0)
      ) {
        error[`${item}`] = true;
        setErrors({ ...errors, ...error });
      }
    });
    if (Object.keys(error).length === 0) {
      setFormStep(formStep + 1);
    } else return;
  };

  const onSubmitStepOne = () => {
    setErrors({});
    let error = {};
    requiredAttributes.reservation.forEach((item) => {
      if (
        !submissions[item] ||
        (Array.isArray(submissions[item]) && submissions[item].length === 0)
      ) {
        error[`${item}`] = true;
        setErrors({ ...errors, ...error });
      }
    });
    if (Object.keys(error).length === 0) {
      setFormStep(formStep + 1);
    } else return;
  };

  const onSubmitStepTwo = () => {
    setFormStep(formStep + 1);
  };

  const onSubmitStepThree = async () => {
    setIsPostLoading(true);
    try {
      if (mode === "create") {
        await axiosInstance.post(`/user_groups/group`, {
          ...submissions,
        });
        setIsOpen(false);
        setUserGroup(null);
        setFormStep(0);
      } else {
        await axiosInstance.put(`/user_group`, {
          ...submissions,
        });
        setIsOpen(false);
        setUserGroup(null);
        setFormStep(0);
      }

      setIsPostLoading(false);
      showSuccessNotification(`Successfully ${mode} user group`);
    } catch (error) {
      showErrorNotification(
        `Failed to ${mode}: ${error?.response?.data?.message}`
      );

      setIsPostLoading(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        setUserGroup(null);
        setFormStep(0);
      }}
      closeIcon
      onOpen={() => setIsOpen(true)}
      size="small"
    >
      <ModalHeader>{editData ? "Edit" : "Create"} user group</ModalHeader>
      <ModalContent style={{ minHeight: 500 }}>
        <Grid columns="1">
          {editData && isLoading && (
            <GridColumn>
              <Loader active inline size="large" />{" "}
            </GridColumn>
          )}

          {!isLoading && (
            <>
              <GridColumn textAlign="center">
                <Step.Group size="small">
                  <Step active={formStep === 0} completed={formStep > 0}>
                    <Icon name="point" />
                    <Step.Content>
                      <Step.Title>Station</Step.Title>
                    </Step.Content>
                  </Step>
                  <Step active={formStep === 1} completed={formStep > 1}>
                    <Icon name="mobile" />
                    <Step.Content>
                      <Step.Title>Reservation</Step.Title>
                    </Step.Content>
                  </Step>

                  <Step active={formStep === 2} completed={formStep > 2}>
                    <Icon name="battery full" />
                    <Step.Content>
                      <Step.Title>Batteries</Step.Title>
                    </Step.Content>
                  </Step>
                  <Step active={formStep === 3} completed={formStep > 3}>
                    <Icon name="" />
                    <Step.Content>
                      <Step.Title>Confirmation</Step.Title>
                    </Step.Content>
                  </Step>
                </Step.Group>
              </GridColumn>
              <GridColumn>
                {!stationIds && <Loader inline active size="big" />}
                {stationIds && (
                  <>
                    {formStep === 0 && (
                      <StationForm
                        {...{
                          errors,
                          submissions,
                          mode,
                          setSubmissions,
                          stationIds,
                          userGroupOptions,
                          roles,
                          onSubmitStepZero,
                          setIsOpen,
                          publicSharingpoints,
                          Sharingpoints,
                          Categories,
                        }}
                      />
                    )}
                    {formStep === 1 && (
                      <ReservationForm
                        {...{
                          errors,
                          batteryTypes,
                          onSubmitStepOne,
                          formStep,
                          setFormStep,
                          submissions,
                          setSubmissions,
                        }}
                      />
                    )}
                    {formStep === 2 && (
                      <BatteryForm
                        {...{
                          onSubmitStepTwo,
                          submissions,
                          setSubmissions,
                          providerIds,
                          batteryPoolIds,
                          batteryTypeIds,
                          manufacturerIds,
                          regionIds,
                          formStep,
                          setFormStep,
                        }}
                      />
                    )}
                    {formStep === 3 && (
                      <SubmitionConfirmation
                        {...{
                          submissions,
                          formStep,
                          setFormStep,
                          onSubmitStepThree,
                          isPostLoading,
                          mode,
                        }}
                      />
                    )}
                  </>
                )}
              </GridColumn>
            </>
          )}
        </Grid>
      </ModalContent>
    </Modal>
  );
};

export default Group;
