/** @format */

import React, { useMemo, useState, useContext } from "react";
import loadable from "@loadable/component";

import { useSharingPoints } from "../hooks/store/use-sharing-points";

import { Router } from "@reach/router";

import "./../assets/styles/sharing-points.scss";

import StationDetail from "./sharingPoints/show";
import { AxiosContext } from "../context/AxiosContext";
import FilesScreen from "../components/FilesScreen";

const ErrorHistoryScreen = loadable(
  () => import("./sharingPoints/show/errorHistory")
);
const StationTab = loadable(
  () => import("./sharingPoints/show/StationDetailById")
);
const InteractionsScreen = loadable(
  () => import("./sharingPoints/show/interactions")
);
const SwapsScreen = loadable(() => import("./sharingPoints/show/swaps"));
const NotesScreen = loadable(() => import("./sharingPoints/show/notes"));

const SharingPointSettingsScreen = loadable(
  () => import("./sharingPoints/show/settings")
);


const StationList = loadable(() => import("./sharingPoints/index"));

const ReservationsScreen = loadable(() => import("../components/Reservations"));

/**
 * SharingPointsScreen - wrapper for data fetching
 * @param {} props
 */

interface SharingPointsScreenProps {
  location: any;
  path: string;
}

const SharingPointsScreen = ({ location }: SharingPointsScreenProps) => {
  const [station, setStation] = useState<
    { sp_serial_number: string } | undefined
  >();
  const { axiosInstance } = useContext(AxiosContext);

  const {
    sharingPointsMapData,
    selectedSharingPointHash,
    selectSharingPointCB,
  } = useSharingPoints();

  const mapIsReady = useMemo(() => {
    if (sharingPointsMapData) return true;
  }, [sharingPointsMapData]);

  return (
    <div
      className={"screen screen--sharingpoints"}
      data-testid="sharingpoints-screen"
    >
      <Router className="router-wrapper">
        <StationList
          selectSharingPoint={selectSharingPointCB}
          selectedSharingPointHash={selectedSharingPointHash}
          path="/"
        />
        <StationDetail path="/:spSerialNumber" mapIsReady={mapIsReady}>
          <StationTab
            {...{
              station,
              setStation,
              spSerialNumber: station?.sp_serial_number,
            }}
            path="/"
          />
          <SharingPointSettingsScreen
            {...{ station, spSerialNumber: station?.sp_serial_number }}
            path="/settings"
          />

          <InteractionsScreen path="/interactions" />
          <SwapsScreen path="/swaps" />
          <NotesScreen path="/notes" />
          <ErrorHistoryScreen path="/error-history" />
          <FilesScreen
            path="/files"
            {...{ axiosInstance, spSerialNumber: station?.sp_serial_number }}
          />

          <ReservationsScreen path="/reservations" />
        </StationDetail>
      </Router>
    </div>
  );
};

export default SharingPointsScreen;
