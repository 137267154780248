import _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Table,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Grid,
  Input,
  Button,
  Loader,
  Confirm,
  Label,
  TableCell,
  Dropdown,
} from "semantic-ui-react";
import CreateUser from "./CreateUser";
import { restApiResponse } from "../../store/actions";
import { Link } from "@reach/router";
import IsNewItem from "../IsNewItem";
import {
  ExportDataAsCsv,
  GroupsDropdownSearch,
  TableScreenTemplate,
  UsersDropdownSearch,
} from "../UtilComponents";
import moment from "moment";
import EditUser from "./EditUser";
import { AxiosContext } from "../../context/AxiosContext";
import { EditSwapperUserRestriction } from "./EditSwapperAccess";

import { UserManagementContext } from "../../context";

export default () => {
  const {
    fetchUsers,
    setName,
    name,
    selectedUsername,
    setSelectedUsername,
    selectedUserGroupName,
    setSelectedUserGroupName,
    email_verified,
    setEmailVerified,
    state,
    setState,
    enabled,
    setEnabled,
    page,
    setPage,
    exportedData,

    selectedUsernameRef,
    selectedUserGroupNameRef,
    nameRef,
    emailVerifiedRef,
    enabledRef,
    pageRef,

    setShowUserForm,
  } = useContext(UserManagementContext);

  const [userGroups, setUserGroups] = useState([]);
  const [isLoading, setIsLoading] = useState();

  const [submissions, setSubmissions] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState();

  const [showReInviteUser, setShowReInviteUser] = useState();
  const [deleteUserConfirmationShown, setDeleteUserConfirmationShown] =
    useState();
  const [showEditUsersGroup, setShowEditUsersGroup] = useState(false);
  const [showReactivateUser, setShowReactivateUser] = useState(false);

  const [isOpenEditSwapperAccessModal, setIsOpenEditSwapperAccessModal] =
    useState();
  const [limitedSwapAccess, setLimitedSwapAccess] = useState();

  const [userInfo, setUserInfo] = useState();
  const [user, setUser] = useState();

  const { column, data, sum_count } = state;

  const { axiosInstance } = useContext(AxiosContext);

  const dispatch = useDispatch();

  const fetchGroups = async () => {
    let { data } = await axiosInstance.get(`/user_groups/user_group_dropdown`);

    setUserGroups(data.data);
  };

  // Update refs when state changes
  useEffect(() => {
    selectedUsernameRef.current = selectedUsername;
  }, [selectedUsername]);

  useEffect(() => {
    selectedUserGroupNameRef.current = selectedUserGroupName;
  }, [selectedUserGroupName]);

  useEffect(() => {
    nameRef.current = name;
  }, [name]);

  useEffect(() => {
    emailVerifiedRef.current = email_verified;
  }, [email_verified]);

  useEffect(() => {
    enabledRef.current = enabled;
  }, [enabled]);

  useEffect(() => {
    pageRef.current = page;
  }, [page]);

  // Fetch users function

  // Event listener for Enter key
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        fetchUsers(false, false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (!axiosInstance) return;

    fetchGroups();
    return;
  }, [page, axiosInstance]);

  const submit = async () => {
    try {
      await axiosInstance.post(`/permissions`, submissions);
      setShowEditUsersGroup(false);
      fetchUsers();
      dispatch(
        restApiResponse({
          message: "Permissions successfully updated",
          request_parameter: "",
          status_code: 200,
        })
      );
    } catch (error) {
      dispatch(
        restApiResponse({
          message: "Permissions update failed",
          request_parameter: "",
          status_code: 500,
        })
      );
    }
  };

  const resetPermissions = async () => {
    try {
      await axiosInstance.delete(`/permissions/${user}`);
      fetchUsers();
      setShowDeleteConfirmation(false);

      dispatch(
        restApiResponse({
          message: "Permissions successfully reset",
          request_parameter: "",
          status_code: 200,
        })
      );
    } catch (error) {
      dispatch(
        restApiResponse({
          message: "Permissions failed to reset",
          request_parameter: "",
          status_code: 500,
        })
      );
    }
    return setState({
      ...state,
      column,
      searchResults: _.sortBy(state.data, [column]),
      direction: "ascending",
    });
  };

  const notification = (message, status_code) => {
    dispatch(
      restApiResponse({
        message,
        request_parameter: "",
        status_code,
      })
    );
  };

  const reInviteUser = async (username, name) => {
    try {
      await axiosInstance.post(`/re_invite_user`, {
        username,
        name,
      });

      dispatch(
        restApiResponse({
          message: "User successfully reinvited",
          request_parameter: "",
          status_code: 200,
        })
      );
    } catch (error) {
      dispatch(
        restApiResponse({
          message: "Failed to reinvite user",
          request_parameter: "",
          status_code: 400,
        })
      );
    }
  };

  const reactivateUser = async () => {
    try {
      await axiosInstance.post(`/cognito_users/${user}/reactivate`);
      fetchUsers();
      setShowReactivateUser(false);

      dispatch(
        restApiResponse({
          message: "User is successfully reactivated",
          request_parameter: "",
          status_code: 200,
        })
      );
    } catch (error) {
      dispatch(
        restApiResponse({
          message: "Failed to reactivate user",
          request_parameter: "",
          status_code: 500,
        })
      );
    }
  };

  const deleteUserByUsername = async (username) => {
    try {
      await axiosInstance.delete(`/users/${username}/delete`);
      setDeleteUserConfirmationShown(false);
      notification("user successfully deleted");
    } catch (error) {
      console.log(error);
      notification("user failed to deleted", 400);
    }
  };

  return (
    <Grid style={{ padding: "10px" }}>
      <TableScreenTemplate
        hasBreadCrumb={false}
        hasSegment={true}
        sum={sum_count}
        actions={
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <ExportDataAsCsv
              fetchData={() => fetchUsers(false, true)}
              filename="swobbee_users"
              data={exportedData}
            />
            <Button
              style={{ width: 130 }}
              icon="plus"
              basic
              content="Create user"
              size="tiny"
              onClick={() => {
                console.log("Create user");
                setShowUserForm(true);
              }}
            />
            <Button
              style={{ width: 130 }}
              icon="close"
              basic
              content="Clear filter"
              size="tiny"
              disabled={
                !(
                  name ||
                  email_verified ||
                  enabled ||
                  selectedUserGroupName ||
                  selectedUsername
                )
              }
              onClick={() => {
                setName("");
                setSelectedUserGroupName(null);
                setEmailVerified(null);
                setSelectedUsername(null);
                setEnabled(null);
              }}
            />
          </div>
        }
        header_name={"Users"}
        description={
          "Explore your user pool, add new users to it, assign user groups for existing users and export the shown data if needed."
        }
        filter_components={
          <div
            className="swap-table-filter-container"
            style={{ width: "100%" }}
          >
            <div className="filter-components">
              <UsersDropdownSearch
                {...{ selectedUsername, setSelectedUsername }}
              />
            </div>
            <div className="filter-components">
              <Input
                placeholder={"Name"}
                type="text"
                value={name}
                defaultValue={name}
                style={{ width: "100%" }}
                onChange={(_, { value }) => setName(value)}
              />
            </div>
            <div className="filter-components">
              <GroupsDropdownSearch
                {...{ selectedUserGroupName, setSelectedUserGroupName }}
              />
            </div>
            <div className="filter-components">
              <Dropdown
                style={{ width: "100%" }}
                selection
                placeholder="Email state"
                defaultValue={email_verified}
                value={email_verified}
                options={[
                  { key: "VERIFIED", text: "VERIFIED", value: true },
                  { key: "UNVERIFIED", text: "UNVERIFIED", value: false },
                ]}
                onChange={(_, { value }) => setEmailVerified(value)}
                clearable
              />
            </div>
            <div className="filter-components">
              <Dropdown
                style={{ width: "100%" }}
                selection
                placeholder="Account status"
                defaultValue={enabled}
                value={enabled}
                options={[
                  { key: "ENABLED", text: "ENABLED", value: true },
                  { key: "DISABLED", text: "DISABLED", value: false },
                ]}
                onChange={(_, { value }) => setEnabled(value)}
                clearable
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "150px",
              }}
            >
              <Button
                style={{ borderRadius: "10px" }}
                content="Search"
                basic
                fluid
                onClick={() => fetchUsers(true)}
              />
            </div>
          </div>
        }
        data={data && data}
        table={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <UserTable
              {...{
                data,
                isLoading,
                setUser,
                setDeleteUserConfirmationShown,
                setShowReInviteUser,
                setShowEditUsersGroup,
                setShowReactivateUser,
                setUserInfo,
                fetchUsers,
                axiosInstance,
                setIsOpenEditSwapperAccessModal,
                setLimitedSwapAccess,
              }}
            />

            {isLoading && <Loader inline active size="huge" />}

            {state.data && (
              <Button
                content={
                  state.data && `Load More (${state.data.length}/${sum_count})`
                }
                basic
                disabled={state.data.length === sum_count}
                circular
                loading={isLoading}
                onClick={() => {
                  setPage(page + 1);
                }}
                style={{ width: 180 }}
              />
            )}
          </div>
        }
      />

      <CreateUser user_groups={userGroups} />

      {showEditUsersGroup && (
        <EditUser
          {...{
            userInfo,
            setUserInfo,
            setSubmissions,
            showEditUsersGroup,
            setShowEditUsersGroup,
            submissions,
            userGroups,
            fetchUsers,
            submit,
          }}
        />
      )}

      <Confirm
        size="mini"
        open={showDeleteConfirmation}
        content={`Are you sure you want to remove ${user}'s permissions?`}
        header="Confirmation"
        onConfirm={() => {
          resetPermissions(user);
        }}
        onCancel={() => {
          setShowDeleteConfirmation(false);
        }}
      />

      <Confirm
        size="mini"
        open={showReactivateUser}
        content={`Are you sure you want to reactivate the user?`}
        header="Confirmation"
        onConfirm={() => {
          reactivateUser(user);
        }}
        onCancel={() => {
          setShowReactivateUser(false);
        }}
      />

      <Confirm
        size="mini"
        open={showReInviteUser}
        content={`Are you sure you want to resend user invitation?`}
        header="Confirmation"
        onConfirm={() => {
          reInviteUser(user, name);
        }}
        onCancel={() => {
          setShowReInviteUser(false);
        }}
      />
      <Confirm
        size="mini"
        open={deleteUserConfirmationShown}
        content={`Are you sure you want to delete user?`}
        header="Confirmation"
        onConfirm={() => {
          deleteUserByUsername(user);
        }}
        onCancel={() => {
          setDeleteUserConfirmationShown(false);
        }}
      />
      <EditSwapperUserRestriction
        username={user}
        limitedSwapAccess={limitedSwapAccess}
        isOpen={isOpenEditSwapperAccessModal}
        setIsOpen={setIsOpenEditSwapperAccessModal}
        fetchUpdate={fetchUsers}
      />
    </Grid>
  );
};

const UserTable = ({
  data,
  isLoading,
  setUser,
  setDeleteUserConfirmationShown,
  setShowReInviteUser,
  setShowEditUsersGroup,
  setShowReactivateUser,
  setUserInfo,
  fetchUsers,
  axiosInstance,
  setIsOpenEditSwapperAccessModal,
  setLimitedSwapAccess,
}) => {
  return (
    <Table fixed sortable>
      <TableHeader style={{ borderRadius: "150px !important" }}>
        <TableRow>
          <TableHeaderCell className="left-header">Username</TableHeaderCell>

          <TableHeaderCell className="inner-header">Name</TableHeaderCell>
          <TableHeaderCell className="inner-header">User group</TableHeaderCell>
          <TableHeaderCell className="inner-header">
            Service Password
          </TableHeaderCell>
          <TableHeaderCell className="inner-header">
            Email State
          </TableHeaderCell>

          <TableHeaderCell className="inner-header">Account</TableHeaderCell>
          <TableHeaderCell className="inner-header">Created At</TableHeaderCell>

          <TableHeaderCell className="inner-header">Updated At</TableHeaderCell>
          <TableHeaderCell className="right-header">Actions</TableHeaderCell>
        </TableRow>
      </TableHeader>
      {!isLoading && !data && <span>no data available</span>}

      {!isLoading && data && (
        <Table.Body>
          {data.map(
            (
              {
                created_at,
                created_by,
                email,
                email_verified,
                enabled,
                family_name,
                name,
                service_password,
                updated_at,
                updated_by,
                user_group_name,
                user_status,
                username,
                odoo_contact_profile_url,
                swapper_user_restriction_info,
              },
              index
            ) => (
              <TableRow key={index}>
                <TableCell>
                  <Link to={`/user-management/${username}`}>
                    {username}
                    <IsNewItem created_at={new Date(created_at).getTime()} />
                  </Link>
                </TableCell>
                <TableCell>{name}</TableCell>
                <TableCell>{user_group_name}</TableCell>
                <TableCell>{service_password}</TableCell>
                <TableCell>
                  <Label
                    color={email_verified ? "green" : "grey"}
                    content={email_verified ? "VERIFIED" : "NOT VERIFIED"}
                    circular
                    style={{ width: 100 }}
                  />
                </TableCell>
                <TableCell>
                  <Label
                    color={enabled ? "green" : "grey"}
                    content={enabled ? "ENABLED" : "DISABLED"}
                    circular
                    style={{ width: 100 }}
                  />
                </TableCell>

                <TableCell>
                  {moment(created_at).format("DD-MM-YYYY hh:mm")}
                </TableCell>
                <TableCell>
                  {moment(updated_at).format("DD-MM-YYYY hh:mm")}
                </TableCell>
                <TableCell>
                  <Button
                    icon="edit"
                    size="mini"
                    basic
                    circular
                    disabled={["Devel"].includes(username)}
                    title="edit user group"
                    floated="left"
                    onClick={() => {
                      setShowEditUsersGroup(true);
                      setUserInfo({
                        created_at,
                        created_by,
                        email,
                        email_verified,
                        enabled,
                        family_name,
                        name,
                        service_password,
                        updated_at,
                        updated_by,
                        user_group_name,
                        user_status,
                        username,
                      });
                    }}
                  />

                  {/* {enabled ? (
                    <UserDeactivation
                      user={username}
                      fetchUsers={fetchUsers}
                      {...{ axiosInstance }}
                    />
                  ) : (
                    <Button
                      title="reactivate user"
                      basic
                      circular
                      size="mini"
                      icon="user plus"
                      onClick={() => {
                        setShowReactivateUser(true);
                        setUser(username);
                      }}
                    />
                  )} */}

                  <Button
                    title={
                      swapper_user_restriction_info &&
                      swapper_user_restriction_info.is_limited
                        ? "reactivate swapper access"
                        : "deactivate swapper access"
                    }
                    basic
                    circular
                    size="mini"
                    icon={
                      swapper_user_restriction_info &&
                      swapper_user_restriction_info.is_limited
                        ? "checkmark"
                        : "ban"
                    }
                    floated="left"
                    onClick={() => {
                      setUser(username);
                      setLimitedSwapAccess(
                        swapper_user_restriction_info &&
                          swapper_user_restriction_info.is_limited
                          ? true
                          : false
                      );
                      setIsOpenEditSwapperAccessModal(true);
                    }}
                  />

                  <Button
                    title="resend invitation email"
                    basic
                    circular
                    size="mini"
                    icon="mail"
                    onClick={() => {
                      setShowReInviteUser(true);
                      setUser(username);
                    }}
                  />

                  <Button
                    title="delete user"
                    basic
                    circular
                    size="mini"
                    icon="close"
                    onClick={() => {
                      setUser(username);
                      setDeleteUserConfirmationShown(true);
                    }}
                  />
                  {odoo_contact_profile_url ? (
                    <Button
                      as="a"
                      title="redirect to Odoo CRM"
                      basic
                      circular
                      size="mini"
                      icon="external"
                      href={odoo_contact_profile_url}
                      target="_blank" // Optional: Opens the link in a new tab
                      rel="noopener noreferrer" // Optional: Security measure when using target="_blank"
                    />
                  ) : (
                    ""
                  )}
                </TableCell>
              </TableRow>
            )
          )}
        </Table.Body>
      )}
    </Table>
  );
};
