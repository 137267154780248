/** @format */

import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import LocationMap from "./LocationMap";
import InputSearchAwsLocation from "./InputSearchAwsLocation";
import {
  Dropdown,
  Grid,
  Form,
  Button,
  Container,
  Segment,
  Divider,
  FormField,
  FormGroup,
  GridColumn,
} from "semantic-ui-react";
import { navigate } from "@reach/router";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { useDropdownOptions } from "../../hooks/use-dropdown-options";
import { CustomButton } from "../CustomComponents";
import { SimpleMap } from "../helpers/maps/SimpleMarkerMap";
import { AssetsContext } from "../../context";

const validationSchema = yup.object().shape({
  addressStreetName: yup.string().required(),
  addressStreetNumber: yup.string().required(),
  addressZipcode: yup.string().required(),
  addressCity: yup.string().required(),
  addressCountry: yup.string().required(),

  gpsLongitude: yup.string().typeError().required(),
  gpsLatitude: yup.string().typeError().required(),
  state: yup.string().required(),
});

function LocationData({
  isEdit,
  state,
  setState,
  setCurrentStep,
  setValidSteps,
  locationStates,
}) {
  const { t } = useTranslation();
  const [location, setSelectedLocation] = useState();
  const {CustomMarker} = useContext(AssetsContext);


  const { control, handleSubmit, register, errors, setValue, watch } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: state,
  });

  useEffect(() => {
    setCurrentStep("LocationData");
  }, []);

  const onSubmit = (data) => {
    setValidSteps((current) => {
      return {
        ...current,
        LocationData: true,
      };
    });
    setState((current) => ({ ...current, ...data }));
    navigate(`step2`);
  };

  const algoliaChange = () => {
    let { item } = location;
    let { Place } = item;
    let street = Place.Street;
    if (Place.Street) {
      if (Place.Street.includes("straße")) {
        street = Place.Street.replace(" ", "");
      }
    }
    setValue("addressStreetName", street);
    setValue("addressStreetNumber", Place.AddressNumber);

    setValue("addressCity", Place.Municipality);
    setValue("addressCountry", Place.Country);
    setValue("addressZipcode", Place.PostalCode);
    setValue("gpsLongitude", Place.Geometry.Point[0]);
    setValue("gpsLatitude", Place.Geometry.Point[1]);
  };
  useEffect(() => {
    if (!location) return;

    algoliaChange();
    return () => {};
  }, [location]);

  const latitude = watch("gpsLatitude");
  const longitude = watch("gpsLongitude");

  return (
    <Container style={{ width: "850px" }}>
      <Grid textAlign="left">
        <Grid.Column
          style={{ height: "350px", width: "100%", padding: "20px" }}
          width={16}
        >
          {latitude && longitude ? (
            <LocationMap
              latLng={{ lat: state.gpsLatitude, lng: state.gpsLongitude }}
              latitude={latitude}
              longitude={longitude}
              changeLatLng={(latLng) => {
                setValue("gpsLongitude", latLng.lng);
                setValue("gpsLatitude", latLng.lat);
              }}
              {...{CustomMarker}}
            />
          ) : (
            <LocationMap latLng={{ lat: 52.4305068, lng: 13.5328499 }} />
          )}
        </Grid.Column>
        <GridColumn width="16">
          <Segment>
            <Grid.Row textAlign="center" style={{ padding: "0px" }}></Grid.Row>

            <Form
              style={{ padding: "20px", width: "100%" }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormField error={errors.suggestion}>
                <label style={{ marginTop: "10px" }}>
                  {t("locations.search")}
                </label>
                <InputSearchAwsLocation
                  disabled={isEdit}
                  {...{ location, setSelectedLocation }}
                />
              </FormField>
              <FormField>
                <label>{t("locations.state.label")}</label>
                <Controller
                  control={control}
                  name="state"
                  selection
                  render={({ onChange, value }) => (
                    <Dropdown
                      additionPosition="bottom"
                      error={errors.state}
                      style={
                        isEdit
                          ? {
                              pointerEvents: "none",
                              color: "#AAA",
                            }
                          : {}
                      }
                      fluid
                      clearable
                      selection
                      defaultValue={value}
                      placeholder="Auswählen"
                      options={[
                        { key: "active", value: "active", text: "active" },
                        {
                          key: "inactive",
                          value: "inactive",
                          text: "inactive",
                        },
                      ]}
                      onChange={(e, data) => onChange(data.value)}
                    />
                  )}
                />
              </FormField>

              <FormGroup widths="equal">
                <FormField error={errors.gpsLatitude}>
                  <label>Latitude</label>
                  <input
                    style={
                      isEdit
                        ? {
                            pointerEvents: "none",
                            color: "#AAA",
                          }
                        : {}
                    }
                    placeholder="Latitude"
                    type="text"
                    name="gpsLatitude"
                    ref={register}
                  />
                </FormField>
                <FormField error={errors.gpsLongitude}>
                  <label>Longitude</label>
                  <input
                    style={
                      isEdit
                        ? {
                            pointerEvents: "none",
                            color: "#AAA",
                          }
                        : {}
                    }
                    placeholder="Longitude"
                    type="text"
                    name="gpsLongitude"
                    ref={register}
                  />
                </FormField>
              </FormGroup>

              <FormGroup widths="equal">
                <FormField error={errors.addressStreetName}>
                  <label>Street</label>
                  <input
                    style={
                      isEdit
                        ? {
                            pointerEvents: "none",
                            color: "#AAA",
                          }
                        : {}
                    }
                    placeholder="Street"
                    type="text"
                    name="addressStreetName"
                    ref={register}
                  />
                </FormField>
                <FormField error={errors.addressStreetNumber}>
                  <label>Street Number</label>
                  <input
                    style={
                      isEdit
                        ? {
                            pointerEvents: "none",
                            color: "#AAA",
                          }
                        : {}
                    }
                    placeholder="Street Number"
                    type="text"
                    name="addressStreetNumber"
                    ref={register}
                  />
                </FormField>
              </FormGroup>

              <FormGroup widths="equal">
                <FormField error={errors.addressZipcode}>
                  <label>Zip Code</label>
                  <input
                    style={
                      isEdit
                        ? {
                            pointerEvents: "none",
                            color: "#AAA",
                          }
                        : {}
                    }
                    placeholder="Zip Code"
                    type="text"
                    name="addressZipcode"
                    ref={register}
                  />
                </FormField>
                <FormField error={errors.addressCity}>
                  <label>City</label>
                  <input
                    style={
                      isEdit
                        ? {
                            pointerEvents: "none",
                            color: "#AAA",
                          }
                        : {}
                    }
                    placeholder="City"
                    type="text"
                    name="addressCity"
                    ref={register}
                  />
                </FormField>
              </FormGroup>
              <FormField error={errors.addressCity}>
                <label>Country</label>
                <input
                  style={
                    isEdit
                      ? {
                          pointerEvents: "none",
                          color: "#AAA",
                        }
                      : {}
                  }
                  placeholder="Country"
                  type="text"
                  name="addressCountry"
                  ref={register}
                />
              </FormField>
            </Form>

            <Grid>
              <Grid.Column
                width={16}
                textAlign="right"
                style={{
                  padding: 30,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Divider />
                <CustomButton
                  onClick={() => handleSubmit(onSubmit)()}
                  type="submit"
                  primary
                  style={{
                    width: 100,
                  }}
                  title={"Next"}
                  translate
                />
              </Grid.Column>
            </Grid>
          </Segment>
        </GridColumn>
      </Grid>
    </Container>
  );
}

export default LocationData;
