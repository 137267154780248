/** @format */

import React, { useContext, useState } from "react";
import {
  Button,
  Form,
  Label,
  Message,
  Icon,
  FormField,
} from "semantic-ui-react";
import axios from "axios";
import awsconfig from "../../config/awsconfig";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AssetsContext } from "../../context/AssetsContext";

const defaultValues = {
  previous_password: "",
  proposed_password: "",
  proposed_password_confirm: "",
};

const validationSchema = yup.object().shape({
  previous_password: yup
    .string()
    .required()
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/
    ),
  proposed_password: yup
    .string()
    .required()
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/
    ),
  proposed_password_confirm: yup
    .string()
    .required()
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/
    ),
});

const ChangePassWord = (props) => {
  const { mainColor } = useContext(AssetsContext);
  const [state, setstate] = useState({});
  
  const [errorCondition, setErrorCondition] = useState();
  const { register, errors, handleSubmit, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });

  const onSubmit = async (data) => {
    setErrorCondition("");
    setstate({});
    const newData = {
      previous_password: data.previous_password,
      proposed_password: data.proposed_password,
    };
    if (
      data.previous_password !== data.proposed_password &&
      data.proposed_password === data.proposed_password_confirm
    )
      axios
        .post(awsconfig.REACT_APP_PUBLIC_API + "/change_password", {
          ...newData,
          access_token: localStorage.getItem(
            `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.token`
          ),
          client_id: awsconfig.REACT_APP_REST_CLIENT_ID,
        })
        .then((result) => {
          setstate({ ...state, success: true, isLoading: false });
          reset(defaultValues);
        })
        .catch((error) => {
          setstate({ ...state, success: false, isLoading: true });
        });
    else if (data.previous_password === data.proposed_password) {
      setErrorCondition("error_condition_1");
    } else {
      setErrorCondition("error_condition_2");
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField>
          {!state.isLoading && state.success && (
            <Message positive>
              <Icon name="check circle" /> Password Successfully Changed.
            </Message>
          )}

          {state.isLoading && !state.success && (
            <Message negative>The Operation Was Unsuccessful.</Message>
          )}

          {errorCondition === "error_condition_1" && (
            <Message negative>
              The current password and the new password must be different.
            </Message>
          )}

          {errorCondition === "error_condition_2" && (
            <Message negative>
              The new password and the password confirmation do not match.
            </Message>
          )}
        </FormField>

        <FormField>
          <Message
            size="mini"
            info
            icon="info"
            header="Password requirements"
            list={[
              "Contains at least 1 number",
              "Contains at least 1 special character (^ $ * . [ ] { } ( ) ? - \" ! @ # % & /  , > < ' : ;  _ ~ ` + =)",
              "Contains at least 1 uppercase letter",
              "Contains at least 1 lowercase letter",
            ]}
          />
        </FormField>

        <FormField>
          <label>Previous password</label>

          <input
            name="previous_password"
            type="password"
            placeholder="Previous password"
            ref={register}
          />
          {errors.previous_password && (
            <Label pointing basic color="red">
              Must Contain 8 Characters, One Uppercase, One Lowercase, One
              Number and one special case Character
            </Label>
          )}
        </FormField>

        <FormField>
          <label>Proposed password</label>

          <input
            name="proposed_password"
            type="password"
            placeholder="Proposed password"
            ref={register}
          />
          {errors.proposed_password && (
            <Label pointing basic color="red">
              Must Contain 8 Characters, One Uppercase, One Lowercase, One
              Number and one special case Character
            </Label>
          )}
        </FormField>

        <FormField>
          <label>Confirm Proposed password</label>
          <input
            name="proposed_password_confirm"
            type="password"
            placeholder="Confirm proposed password"
            ref={register}
          />
          {errors.proposed_password_confirm && (
            <Label pointing basic color="red">
              Must Contain 8 Characters, One Uppercase, One Lowercase, One
              Number and one special case Character
            </Label>
          )}
        </FormField>
        <FormField>
          <Button
            size="large"
            fluid
            type="submit"
            style={{ backgroundColor: mainColor, color: "#fff" }}
          >
            Submit
          </Button>
        </FormField>
      </Form>
    </>
  );
};

export default ChangePassWord;
