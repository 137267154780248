/** @format */

import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Dropdown,
  Form,
  Button,
  Container,
  Segment,
  Divider,
  FormField,
  Input,
} from "semantic-ui-react";
import "./style.scss";
import { navigate } from "@reach/router";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../CustomComponents";

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const validationSchema = yup.object().shape({
  contactFirstName: yup.string().required(),
  contactFamilyName: yup.string().required(),
  contactEmail: yup.string().email().required(),
  contactPhoneNumber: yup.string().matches(phoneRegExp),
  contactTitle: yup.string().required(),
});

function ContactData({
  state,
  setState,
  setCurrentStep,
  setValidSteps,
  locationHashKey,
}) {
  const { t } = useTranslation();
  const { control, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: state,
  });

  const navigateToLocationPage = () => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("edit") && !locationHashKey) {
      navigate("/locations");
    }
  };

  useEffect(() => {
    setCurrentStep("ContactData");
    navigateToLocationPage();
  }, []);
  const onSubmit = (data) => {
    setValidSteps((current) => {
      return {
        ...current,
        ContactData: true,
      };
    });
    setState((current) => ({ ...current, ...data }));
    navigate("result");
  };

  const Title = [
    { key: "", value: "", text: t("locations.contact_title.select") },
    {
      key: "Herr",
      value: "male",
      text: t("locations.contact_title.title.male"),
    },
    {
      key: "Frau",
      value: "female",
      text: t("locations.contact_title.title.female"),
    },
    {
      key: "Andere",
      value: "diverse",
      text: t("locations.contact_title.title.diverse"),
    },
  ];

  const onClickHandler = (event) => {
    event.preventDefault();
    navigate("step2");
  };

  return (
    <Container style={{ width: "820px" }}>
      <Segment>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormField error={errors.contactTitle}>
            <label>{t("locations.contact_title.label")}*</label>
            <Controller
              control={control}
              name="contactTitle"
              selection
              render={({ onChange, value }) => (
                <Dropdown
                  fluid
                  clearable
                  selection
                  defaultValue={value}
                  placeholder="Auswählen"
                  options={Title}
                  onChange={(e, data) => onChange(data.value)}
                />
              )}
            />
          </FormField>

          <FormField name="contactFirstName" error={errors.contactFirstName}>
            <label>{t("locations.contact_first_name.label")}*</label>
            <Controller
              control={control}
              name="contactFirstName"
              input
              render={({ onChange, value }) => (
                <Input
                  defaultValue={value}
                  type="text"
                  onChange={(e, data) => onChange(data.value)}
                  placeholder="John"
                />
              )}
            />
          </FormField>

          <FormField error={errors.contactFamilyName}>
            <label>{t("locations.contact_family_name.label")}*</label>

            <Controller
              control={control}
              name="contactFamilyName"
              input
              render={({ onChange, value }) => (
                <Input
                  defaultValue={value}
                  type="text"
                  onChange={(e, data) => onChange(data.value)}
                  placeholder="John"
                />
              )}
            />
          </FormField>

          <FormField error={errors.contactEmail}>
            <label>{t("locations.contact_mail.label")}*</label>
            <Controller
              control={control}
              name="contactEmail"
              input
              render={({ onChange, value }) => (
                <Input
                  defaultValue={value}
                  type="text"
                  onChange={(e, data) => onChange(data.value)}
                  placeholder="john.doe@swobbee.com"
                />
              )}
            />
          </FormField>

          <FormField error={errors.contactPhoneNumber}>
            <label>{t("locations.contact_phone.label")}*</label>

            <Controller
              control={control}
              name="contactPhoneNumber"
              input
              render={({ onChange, value }) => (
                <Input
                  defaultValue={value}
                  type="tel"
                  onChange={(e, data) => onChange(data.value)}
                  placeholder="+49"
                />
              )}
            />
          </FormField>

          {/*Input button*/}
          <Divider />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <CustomButton
              onClick={onClickHandler}
              type="submit"
              title={"Back"}
              negative
            />
            <CustomButton
              type="submit"
              primary
              title={"next"}
              floated="right"
            />
          </div>
        </Form>
      </Segment>
    </Container>
  );
}

export default ContactData;
