import React from "react";
import {
  Select,
  FormField,
  Form,
  FormGroup,
  GridColumn,
  Grid,
  Input,
} from "semantic-ui-react";
import { toLower } from "lodash";
import { CustomButton } from "../../CustomComponents";

const ReservationForm = ({
  errors,
  batteryTypes,
  onSubmitStepOne,
  formStep,
  setFormStep,
  submissions,
  setSubmissions,
}) => {
  let allowed_to_swap_batteries = submissions.allowed_to_swap_batteries;

  return (
    <Grid columns="1">
      <GridColumn>
        <Form size="big" style={{ paddingBottom: "20px" }}>
          <FormField
            error={
              submissions.allowed_to_swap_batteries
                ? submissions.allowed_to_swap_batteries.length === 0
                  ? errors.allowed_to_swap_batteries
                  : false
                : false
            }
          >
            <label>Allowed battery types</label>
            <Select
              allowAdditions
              fluid
              defaultValue={submissions.allowed_to_swap_batteries}
              placeholder="Allowed battery types"
              options={
                batteryTypes &&
                batteryTypes.map((item) => {
                  return { value: item, key: item, text: item };
                })
              }
              onChange={(_, { value }) => {
                setSubmissions({
                  ...submissions,
                  allowed_to_swap_batteries: value,
                });
              }}
              search
              selection
              multiple
            />
          </FormField>
          <FormGroup widths="equal">
            <FormField>
              <label htmlFor="name">Reservation request limit</label>
              <Input
                type="number"
                name="reservation_request_limit"
                placeholder={"Reservation request limit..."}
                onChange={(_, { value }) => {
                  setSubmissions({
                    ...submissions,
                    reservation_request_limit: value,
                  });
                }}
                defaultValue={submissions.reservation_request_limit}
              />
            </FormField>

            <FormField>
              <label>Reservation interval (h)</label>
              <Input
                type="number"
                name="reservation_interval"
                placeholder={"Reservation interval..."}
                id="name"
                onChange={(_, { value }) => {
                  setSubmissions({
                    ...submissions,
                    reservation_interval: value,
                  });
                }}
                defaultValue={submissions.reservation_interval}
              />
            </FormField>
          </FormGroup>
          {allowed_to_swap_batteries &&
            allowed_to_swap_batteries.map((battery_type) => (
              <FormField key={battery_type}>
                <label>{battery_type} reservation limit</label>
                <Input
                  type="number"
                  name={`reservation_limit_${toLower(battery_type)
                    .replaceAll(" ", "_")
                    .replace(".", "+")}`}
                  placeholder={`${battery_type}...`}
                  id="name"
                  defaultValue={
                    submissions[
                      `reservation_limit_${toLower(battery_type)
                        .replaceAll(" ", "_")
                        .replace(".", "+")}`
                    ]
                  }
                  onChange={(event, data) => {
                    let reservation_type = {};
                    reservation_type[`${data.name}`] = data.value;
                    setSubmissions({
                      ...submissions,
                      ...reservation_type,
                    });
                  }}
                />
              </FormField>
            ))}
        </Form>
      </GridColumn>

      <GridColumn>
        <CustomButton
          primary
          size="big"
          title="Next"
          type="button"
          onClick={() => onSubmitStepOne()}
          style={{ width: "100%" }}
        />
      </GridColumn>
      <GridColumn>
        <CustomButton
          title="Back"
          type="button"
          onClick={() => setFormStep(formStep - 1)}
          style={{ width: "100%" }}
          negative
        />
      </GridColumn>
    </Grid>
  );
};

export default ReservationForm;
