/** @format */

import React, { useContext } from "react";
import { Dropdown, Icon, Menu, Flag, FlagNameValues } from "semantic-ui-react";
import { logoutUser } from "../../store/actions";
import { Link } from "@reach/router";
import { useDispatch } from "react-redux";
import { AxiosContext } from "../../context/AxiosContext";
import awsconfig from "../../config/awsconfig";
import "./index.scss";
import { AssetsContext } from "../../context/AssetsContext";

interface ProfileDropdownProps {
  session: any;
  lang: string;
  setLang: any;
}

const langs: string[] = ["de", "en", "es"];

const ProfileDropdown = (props: ProfileDropdownProps) => {
  const { session, lang, setLang } = props;
  const dispatch = useDispatch();
  const { axiosInstance } = useContext(AxiosContext);
  const { mainColor } = useContext(AssetsContext);

  const postUserSettings = async (language: any) => {
    await axiosInstance.post("/users/settings", {
      username: session.userName,
      language,
    });
  };

  const name = localStorage.getItem(
    `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.name`
  );

  return (
    <nav
      className="nav nav--login"
      style={{ marginRight: "10px", height: "100%" }}
    >
      {name && (
        <Menu style={{ marginRight: "10px", height: "100%" }}>
          <Dropdown
            item
            icon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <img
                  alt=""
                  style={{
                    borderRadius: 40,
                    height: 25,
                    width: 25,
                    marginRight: 5,
                  }}
                  src={`https://eu.ui-avatars.com/api/?name=${name}&background=${mainColor?.replace(
                    "#",
                    ""
                  )}&color=fff&bold=true`}
                />
                <span>{name}</span>
              </div>
            }
          >
            <Dropdown.Menu style={{ backgroundColor: "#eee" }}>
              <Dropdown.Item>
                <Link to="/profile">
                  <div style={{ color: mainColor }}>
                    <Icon name="user" style={{ color: mainColor }} />
                    My profile
                  </div>
                </Link>
              </Dropdown.Item>

              <Dropdown
                item
                direction="left"
                icon={
                  <>
                    <Icon name="world" style={{ color: mainColor }} />
                    <span style={{ color: mainColor }}>Language</span>
                  </>
                }
              >
                <Dropdown.Menu style={{ color: mainColor }}>
                  {langs.map((l) => (
                    <Menu.Item
                      position="left"
                      key={l}
                      active={lang === l}
                      className={lang === l ? "selected-lang" : "lang"}
                      onClick={() => {
                        localStorage.setItem("i18n-language", l);
                        setLang(l);
                        postUserSettings(l);
                      }}
                    >
                      <span>
                        <Flag
                          name={l === "en" ? "us" : (l as FlagNameValues)}
                        />
                      </span>
                      {l}
                    </Menu.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown.Item
                onClick={() => {
                  dispatch(logoutUser());
                }}
              >
                <Link to="/">
                  <div style={{ color: mainColor }}>
                    <Icon name="log out" style={{ color: mainColor }} />
                    Logout
                  </div>
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu>
      )}
    </nav>
  );
};

export default ProfileDropdown;
