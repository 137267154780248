import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AxiosContext } from "./AxiosContext";
import { toast } from "react-toastify";
import { navigate } from "@reach/router";
import {
  OPEN_DOOR_REQUEST_TYPES,
  STATION_MODULE_TYPES,
} from "../constants/app-constants";
import { NotifyContext } from "./NotfyContext";

export const StationContext = createContext();

export const StationProvider = ({ children }) => {
  const [ipc_name, setSelectedIpcName] = useState();
  const [searchOnline, setSearchOnline] = useState();
  const [spType, setSpType] = useState();
  const [selectedUserGroupName, setSelectedUserGroupName] = useState();
  const [battery_type, setSelectedBatteryType] = useState();
  const [sp_serial_number, setSelectedStation] = useState();
  const [privacyStatus, setPrivacyStatus] = useState();
  const [location, setSelectedLocation] = useState();
  const [isLoadingStations, setIsLoadingStations] = useState();
  const [station, setStation] = useState();
  const [selectedSlot, setSelectedSlot] = useState();
  const [showCommands, setShowCommands] = useState();
  const {showErrorNotification, showSuccessNotification}=useContext(NotifyContext)


  const [isLoadingStation, setIsLoadingStation] = useState(false);

  const [state, setState] = useState({
    column: null,
    data: null,
    direction: null,
    searchResults: null,
    searchTerm: null,
    count: null,
  });

  const { axiosInstance } = useContext(AxiosContext);

  const fetchSharingPoints = useCallback(async () => {
    setIsLoadingStations(true);
    try {
      const { data } = await axiosInstance.get("/sharingpoints/", {
        params: {
          is_online: searchOnline,
          battery_type,
          user_groups: selectedUserGroupName,
          ipc_name,
          sharing_point_type: spType,
          sp_serial_number,
          is_public: privacyStatus,
          location,
        },
      });

      setState((prevState) => ({ ...prevState, searchResults: data.data }));
    } catch (error) {
      console.log(error);
      showErrorNotification(`Failed to fetch data: ${error?.response?.data?.message}`)
    }
    setIsLoadingStations(false);
  }, [
    axiosInstance,
    battery_type,
    selectedUserGroupName,
    ipc_name,
    searchOnline,
    spType,
    sp_serial_number,
    privacyStatus,
    location,
    showErrorNotification
  ]);

  const fetchSharingPointByStationId = useCallback(
    async (spSerialNumber) => {
      try {
        setIsLoadingStation(true);
        const { data } = await axiosInstance.get(
          "/sharingpoints/" + spSerialNumber
        );
        setStation(data.data);
        setIsLoadingStation(false);
      } catch (error) {
        showErrorNotification(`Failed to fetch data: ${error?.response?.data?.message}`)
        navigate("/sharingpoints");
        setIsLoadingStation(false);
        
      }
    },
    [axiosInstance]
  );

  useEffect(() => {
    if (!axiosInstance) return;
    fetchSharingPoints();
  }, [axiosInstance, fetchSharingPoints]);

  const station_count = useMemo(() => {
    if (!station) return;
    let temp = 0;
    station.modules.forEach((element) => {
      if (element.box_type === "ChargeModule") {
        temp = temp + Object.values(element.battery_state).length;
      }
    });
    return temp;
  }, [station]);

  const powerModule = useMemo(() => {
    if (!station) return;
    const temp = station.modules.filter((module) => {
      return module.box_type === "PowerModule";
    });
    return temp[0];
  }, [station]);

  const thermalModule = useMemo(() => {
    if (!station) return;
    const temp = station.modules.filter((module) => {
      return module.box_type === "ThermalModule";
    });
    return temp[0];
  }, [station]);

  const chargeModuleSlots = useMemo(() => {
    if (!station) {
      console.log("station is undefined or null");
      return;
    }

    let slot_count = 0;
    const mergedValues = [];

    const temp = station.modules.filter((module) => {
      return module.box_type === "ChargeModule";
    });

    if (temp.length === 0) {
      console.log("No ChargeModule found in station.modules");
    }

    for (let i = 0; i < temp.length; i++) {
      const module = temp[i];
      // console.log(
      //   "Processing module:",
      //   module,
      //   Object.values(module.battery_state).length
      // );

      for (let j = 0; j < Object.values(module.battery_state).length; j++) {
        slot_count += 1;
        let slotKey = `slot_${j + 1}`;
        const battery_type = module.box_sub_type;
        const state_of_charge = module.state_of_charge?.[slotKey]?.soc_value;
        const door_state = module.slot_door_state?.[slotKey];
        const slot_state = module.battery_state?.[slotKey]?.slot_state;
        const battery_id = module.battery_sn?.[slotKey];
        const cmcu_battery_state =
          module.cmcu_batt_state?.[slotKey]?.slot_state;
        const is_charging = module.is_charging_batt?.[slotKey]?.is_charging;
        const slot_blocked = module.slot_blocked?.[slotKey];
        const charge_current_batt = module.charge_current_batt?.[slotKey];
        const state_of_health = module.state_of_health?.[slotKey];
        const charge_cycle = module.charge_cycles?.[slotKey];
        const pack_voltage = module.pack_voltage?.[slotKey];
        const temperatures = module.temperatures?.[slotKey];
        const slot_status = module.module_overview_state_list?.[slotKey];
        const module_number = module.box_number
        const sp_serial_number = module.sp_serial_number


        // console.log(`Slot ${slot_count}:`, {
        //   state_of_charge,
        //   door_state,
        //   slot_state,
        // });

        mergedValues.push({
          type: "SLOT",
          legacy_slot_number: j+1,
          slot_number: slot_count,
          state_of_charge,
          door_state,
          slot_state,
          battery_id,
          cmcu_battery_state,
          is_charging,
          slot_blocked,
          charge_current_batt,
          battery_type,
          state_of_health,
          charge_cycle,
          pack_voltage,
          temperatures,
          slot_status,
          serial_number: module.module_serial_number,
          module_number,
          sp_serial_number
        });
      }
    }

    // console.log("mergedValues", mergedValues);

    return mergedValues;
  }, [station]);

  useEffect(() => {
    if (!station) return;
    if (!selectedSlot) {
      setSelectedSlot({
        type: STATION_MODULE_TYPES.POWER_MODULE,
        ...station.modules[0],
      });
    }
  }, [station]);

  const totalActivePower = useMemo(() => {
    if (!powerModule) return "---";
    return powerModule.active_power_total
      ? Number.parseFloat(powerModule.active_power_total / 1000).toFixed(2) +
          "kW"
      : "0.00kW";
  }, [powerModule]);

  const requestOpenSingleLockDoor = useCallback(async () => {
    try {
      await axiosInstance.post("/commands", {
        data: {
          sp_serial_number: station.sp_serial_number,
          charge_modul: selectedSlot.module_number,
          slot_number: selectedSlot.legacy_slot_number,
        },
        request: OPEN_DOOR_REQUEST_TYPES.SINGLE_LOCK_SLOT,
      });
    } catch (error) {}
  }, [axiosInstance, station, selectedSlot]);

  return (
    <StationContext.Provider
      value={{
        fetchSharingPoints,
        ipc_name,
        setSelectedIpcName,
        searchOnline,
        setSearchOnline,
        spType,
        setSpType,
        selectedUserGroupName,
        setSelectedUserGroupName,
        battery_type,
        setSelectedBatteryType,
        sp_serial_number,
        setSelectedStation,
        privacyStatus,
        setPrivacyStatus,
        location,
        setSelectedLocation,
        state,
        isLoadingStations,
        setIsLoadingStations,
        station,
        setStation,
        fetchSharingPointByStationId,
        isLoadingStation,
        selectedSlot,
        setSelectedSlot,
        station_count,
        powerModule,
        chargeModuleSlots,
        thermalModule,
        totalActivePower,
        showCommands,
        setShowCommands,
        requestOpenSingleLockDoor,
      }}
    >
      {children}
    </StationContext.Provider>
  );
};
