import { createAction } from "redux-starter-kit";
import axios from "axios";
import awsconfig from "../../config/awsconfig.ts";
import { navigate } from "@reach/router";

export const registrationLoading = createAction("REGISTRATION_LOADING");
export const registrationSuccess = createAction("REGISTRATION_SUCCESS");
export const registrationError = createAction("REGISTRATION_ERROR");

export const confirmLoading = createAction("CONFIRM_LOADING");
export const confirmSuccess = createAction("CONFIRM_SUCCESS");
export const confirmError = createAction("CONFIRM_ERROR");

/**
 * Thunk for registring a user.
 *
 * @param {Object} user - The user data object
 * @param {string} user.email - User email
 * @param {string} user.username - Name that is used for the login
 * @param {string} user.name - Displayed name for the user
 * @param {string} user.password - User password
 */
export const registerUser = user => async dispatch => {
  dispatch(registrationLoading());
  // const url = config.RestRegistrationApiRef
  const url = awsconfig.REACT_APP_PUBLIC_API + "/signup";

  try {
    const { email, username, name, password } = user;
    const req = await axios.post(url, {
      email,
      username,
      name,
      password,
      client_id: awsconfig.REACT_APP_REST_CLIENT_ID
    });
    const data = req.data;
    if (data.status_code === 200) {
      dispatch(registrationSuccess(username));
      navigate("/confirmation");
    } else if ([400, 401].includes(data.status_code)) {
      dispatch(registrationError(data.body));
    }
  } catch (error) {
    dispatch(registrationError(error.message));
  }
};

export const confirmRegistration = (username, code) => async dispatch => {
  dispatch(confirmLoading());
  // const url = config.RestConfirmationApiRef;
  const url = awsconfig.REACT_APP_PUBLIC_API + "/confirm";

  try {
    const req = await axios.post(url, {
      confirmation_code: code,
      username,
      client_id: awsconfig.REACT_APP_REST_CLIENT_ID
    });
    const data = req.data;
    if (data.status_code === 200) {
      dispatch(confirmSuccess());
      navigate("/login");
    }
    if ([400, 401].includes(data.status_code)) {
      const body = JSON.parse(data.body); // TODO backend should deliver JSON
      dispatch(confirmError(body.message || body));
    }
  } catch (error) {
    dispatch(confirmError(error.message));
  }
};
