/** @format */

import React, { useState, useContext } from "react";
import { Container, Grid, Loader, GridColumn, Tab } from "semantic-ui-react";
import SwapRequestPlot from "./SwapRequestPlot";
import ServiceRatingTable from "./ServiceRatingTable";

import SharingPointList from "./SharingPointList";
import { useTranslation } from "react-i18next";
import SwapTable from "./SwapTable";
import { useDispatch } from "react-redux";
import { restApiResponse } from "../../store/actions";
import { navigate } from "@reach/router";
import QueryString from "qs";
import { useMemo } from "react";
import StationUsageTable from "./StationUsageTable";
import { AxiosContext } from "../../context/AxiosContext";
import {
  RESOURCE_METHODS,
  SWOBBEE_RESOURCES,
} from "../../constants/app-constants";
import { AssetsContext } from "../../context";

const tabs = {
  0: "swapTable",
  1: "chart",
  2: "service-ratings",
  3: "station-usage",
};

const indexes = {
  swapTable: 0,
  chart: 1,
  "service-ratings": 2,
  "station-usage": 3,
};

const SwapsStatistics = () => {
  const defaultValues = {
    pageSize: 50,
  };
  const dispatch = useDispatch();
  const [groupBy, setGroupBy] = useState("week");
  const [itemList, setItemList] = useState([]);
  const [showItemList, setShowItemList] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [allParams, setAllParams] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [countAll, setCountAll] = useState();
  const [sortType, setSortType] = useState("normal");
  const [attributToSort, setAttributToSort] = useState();

  const [loadMore, setLoadMore] = useState(false);
  const [swapsExport, setSwapsExport] = useState([]);
  const [catchError, setCatchError] = useState(false);
  const { t } = useTranslation("sharingPointOverview");
  const { axiosInstance } = useContext(AxiosContext);

  const panes = [
    {
      menuItem: {
        key: "swapTable",
        icon: "sync",
        content: t("swaps.swaps"),
        resource: SWOBBEE_RESOURCES.SWAPS,
      },
      render: () => (
        <Tab.Pane>
          {tableData && tableData.length >= 0 && (
            <SwapTable
              {...{
                setLoadMore,
                loadMore,
                setSwapsExport,
                swapsExport,
                catchError,
                setLoadingData,
                loadingData,
                setSortType,
                setAttributToSort,
                attributToSort,
                sortType,
                setPageNumber,
                pageNumber,
                tableData,
                defaultValues,
                countAll,
                setAllParams,
                allParams,
                fetchSwaps,
                setCatchError,
              }}
            />
          )}
          {!tableData && (
            <Loader inline active size="large" style={{ marginTop: "20px" }} />
          )}
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: "chart",
        icon: "chart bar outline",
        content: "Chart",
        resource: SWOBBEE_RESOURCES.SWAPS,
      },
      render: () => (
        <Tab.Pane>
          <Grid verticalAlign="middle">
            <SwapRequestPlot
              groupBy={groupBy}
              setGroupBy={setGroupBy}
              setItemList={setItemList}
              setShowItemList={setShowItemList}
            />
            <Grid.Row>
              <SharingPointList setItemList={setItemList} itemList={itemList} />
            </Grid.Row>
          </Grid>
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: "service-ratings",
        icon: "star",
        content: "Service ratings",
        resource: SWOBBEE_RESOURCES.SERVICE_RATING,
      },
      render: () => (
        <Tab.Pane>
          <Grid verticalAlign="middle">
            <ServiceRatingTable
              groupBy={groupBy}
              setGroupBy={setGroupBy}
              setItemList={setItemList}
              setShowItemList={setShowItemList}
            />
          </Grid>
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: "station-usage",
        icon: "chart pie",
        content: "Station usage",
        resource: SWOBBEE_RESOURCES.STATION_USAGE,
      },
      render: () => (
        <Tab.Pane>
          <Grid verticalAlign="middle">
            <StationUsageTable />
          </Grid>
        </Tab.Pane>
      ),
    },
  ];

  const fetchSwaps = async () => {
    try {
      const { data } = await axiosInstance.get("/swaps/swap_actions");
      setCountAll(data.count);
      setTableData(data.data);
      setLoadingData(false);
    } catch (error) {
      const response = {
        message: "Fetch Swaps failed",
        request_parameter: "",
        status_code: 500,
      };
      dispatch(restApiResponse(response));
      setLoadingData(false);
      setCatchError(true);
    }
  };

  const [activeIndex, setActiveIndex] = useState(
    indexes[
      QueryString.parse(window.location.search, { ignoreQueryPrefix: true }).tab
    ]
  );
  const { isAllowedToAccess, userAllowedToAccess } = useContext(AssetsContext);

  const filteredPanes = useMemo(() => {
    if (!userAllowedToAccess) return;
    const res = panes.filter((item) => {
      return isAllowedToAccess(item.menuItem.resource, RESOURCE_METHODS.GET);
    });
    console.log("res", res);
    return res;
  }, [userAllowedToAccess, isAllowedToAccess]);

  const handleOnTabChange = (activeIndex) => {
    setActiveIndex(activeIndex);
    navigate(`/statistics?tab=${tabs[activeIndex]}`);
  };

  return (
    <Container
      className="statistic-container"
      style={{
        marginBottom: "50px",
      }}
    >
      <GridColumn width="16">
        <Tab
          style={{ marginTop: 14 }}
          renderActiveOnly
          activeIndex={activeIndex}
          panes={filteredPanes}
          onTabChange={(_, { activeIndex }) => handleOnTabChange(activeIndex)}
        />
      </GridColumn>
    </Container>
  );
};

export default SwapsStatistics;
