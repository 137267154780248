import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AxiosContext } from "./AxiosContext";
import { toast } from "react-toastify";
import { NotifyContext } from "./NotfyContext";

export const UserManagementContext = createContext({
  fetchUsers: () => {},
  isLoadingUserManagements: false,
  users: [],
  showUserForm: false,
  setShowUserForm: () => {},
});

export const UserManagementProvider = ({ children }) => {
  const [users, setUsers] = useState(null);
  const [user, setUser] = useState(null);
  const [isFetchingUserProfile, setIsFetchingUserProfile] = useState(false);
  const [isCreateUserLoading, setIsCreateUserLoading] = useState(false);
  const [showUserForm, setShowUserForm] = useState(false);

  const { showErrorNotification, showSuccessNotification } =
    useContext(NotifyContext);

  const [userGroups, setUserGroups] = useState({
    column: null,
    data: null,
    searchResults: null,
    direction: null,
  });
  const [isLoadingUserGroups, setIsLoadingUserGroups] = useState(false);

  const [isLoadingUsers, setIsLoadingUsers] = useState(false);

  const { axiosInstance } = useContext(AxiosContext);

  const [name, setName] = useState();
  const [selectedUsername, setSelectedUsername] = useState();
  const [selectedUserGroupName, setSelectedUserGroupName] = useState();

  const [email_verified, setEmailVerified] = useState();
  const [state, setState] = useState({});

  const [enabled, setEnabled] = useState();
  const [page, setPage] = useState(0);
  const [exportedData, setExportedData] = useState();
  const selectedUsernameRef = useRef(selectedUsername);
  const selectedUserGroupNameRef = useRef(selectedUserGroupName);
  const nameRef = useRef(name);
  const emailVerifiedRef = useRef(email_verified);
  const enabledRef = useRef(enabled);
  const pageRef = useRef(page);

  const fetchUsers = useCallback(
    async (is_filter_request, is_export_request) => {
      try {
        !is_export_request && setIsLoadingUsers(true);
        let { data } = await axiosInstance.get(`/users/cognito_users`, {
          params: {
            username: selectedUsernameRef.current,
            user_group_name: selectedUserGroupNameRef.current,
            name: nameRef.current,
            email_verified: emailVerifiedRef.current,
            enabled: enabledRef.current,
            page: is_filter_request ? 0 : pageRef.current,
            export: is_export_request,
          },
        });
        if (!is_export_request) {
          setState({
            ...state,
            data:
              pageRef.current && !is_filter_request
                ? [...data.data, ...state.data]
                : data.data,
            sum_count: data.count,
          });
        } else {
          setExportedData(data.data);
        }
        !is_export_request && setIsLoadingUsers(false);
      } catch (error) {
        toast.error(error?.message);
      }
    },
    [setIsLoadingUsers, axiosInstance, setExportedData, setState, state]
  );

  const fetchUserGroups = useCallback(async () => {
    try {
      setIsLoadingUserGroups(true);
      let { data } = await axiosInstance.get(`/user_groups/groups`);
      setUserGroups({
        ...userGroups,
        data: data.data,
        searchResults: data.data,
      });
      setIsLoadingUserGroups(false);
    } catch (error) {
      toast.error(error?.message);
      setIsLoadingUserGroups(false);
    }
  }, [axiosInstance, setUserGroups, userGroups, setIsLoadingUserGroups]);

  const fetchProfile = useCallback(
    async (username) => {
      try {
        setIsFetchingUserProfile(true);
        const { data } = await axiosInstance.get(`/users/profile/${username}`);
        setUser(data.data);
        setIsFetchingUserProfile(false);
      } catch (error) {
        toast.error(error.response?.data.message);
        setIsFetchingUserProfile(false);
      }
    },
    [setIsFetchingUserProfile, axiosInstance]
  );

  const createUser = useCallback(
    async (payload) => {
      setIsCreateUserLoading(true);
      if (payload.segments.length > 0) {
        for (const element of payload.segments)
          try {
            await axiosInstance.post(`/users/invite_user`, {
              email: element.email,
              name: `${element.firstName} ${element.lastName}`,
              user_group: payload.user_group,
              subscription_battery_type: element.subscription_battery_type
                ? element.subscription_battery_type
                : null,
              amount: element.amount ? element.amount : null,
            });
            showSuccessNotification("User created successfully");
            setShowUserForm(false);
          } catch (error) {
            showErrorNotification(error?.response?.data.message);
          }

        setIsCreateUserLoading(false);
      }
    },
    [axiosInstance, showErrorNotification, showSuccessNotification]
  );

  const deleteUserGroupByUserGroupName = useCallback(
    async (userGroupName, setIsOpen) => {
      try {
        await axiosInstance.delete(`/user_groups/${userGroupName}`);

        showSuccessNotification(
          `The Group ${userGroupName} was deleted successfully`
        );
        setIsOpen(false);
      } catch (error) {
        showErrorNotification(
          `Failed to delete group: ${error?.response?.data?.message}`
        );
      }
    },
    [axiosInstance, showErrorNotification, showSuccessNotification]
  );

  useEffect(() => {
    if (!axiosInstance) return;
    fetchUsers();
    fetchUserGroups();

    return () => {};
  }, [axiosInstance]);

  return (
    <UserManagementContext.Provider
      value={{
        fetchUsers,
        isLoadingUsers,
        users,
        setName,
        setUsers,
        setIsLoadingUsers,
        name,
        selectedUsername,
        setSelectedUsername,
        selectedUserGroupName,
        setSelectedUserGroupName,
        email_verified,
        setEmailVerified,
        state,
        setState,
        enabled,
        setEnabled,
        page,
        setPage,
        exportedData,
        setExportedData,
        selectedUsernameRef,
        selectedUserGroupNameRef,
        nameRef,
        emailVerifiedRef,
        enabledRef,
        pageRef,
        userGroups,
        setUserGroups,
        isLoadingUserGroups,
        fetchUserGroups,
        user,
        isFetchingUserProfile,
        fetchProfile,
        createUser,
        isCreateUserLoading,
        showUserForm,
        setShowUserForm,
        deleteUserGroupByUserGroupName,
      }}
    >
      {children}
    </UserManagementContext.Provider>
  );
};
