import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import {
  TileLayer,
  MapContainer,
  useMap,
  Popup,
  Marker,
  ZoomControl,
} from "react-leaflet";
import * as L from "leaflet";
import "leaflet.markercluster";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

import MarkerClusterGroup from "react-leaflet-markercluster";
import { Button } from "semantic-ui-react";
import { upperFirst } from "lodash";
import { AssetsContext } from "../../context";
import { CustomMarkerPopup } from "../CustomComponents";

delete L.Icon.Default.prototype._getIconUrl;

const mapProps = {
  latLng: PropTypes.array,
  sharingPoints: PropTypes.array,
  diameter: PropTypes.number,
  ownLocation: PropTypes.bool,
};

const minZoom = 4;
const maxZoom = 18;
const tileUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const attribution =
  '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';

/** react-leaflet Map renders all public sharingpoints*/
const Map = ({
  latLng,
  sharingPoints,
  diameter,
  ownLocation,
  initialMapZoom,
  flyToGeoCode,
  hasUserLocationButton,
  hasZoomControl,
}) => {
  const [userPosition, setUserPosition] = useState(null);

  return (
    <MapContainer
      zoomControl={false}
      zoom={initialMapZoom}
      center={latLng}
      style={{ height: "100%", width: "100%" }}
    >
      {hasZoomControl && <ZoomControl position="bottomright" />}

      <TileLayer
        attribution={attribution}
        url={tileUrl}
        minZoom={minZoom}
        maxZoom={maxZoom}
      />
      <MapChild
        sharingPoints={sharingPoints}
        diameter={diameter}
        latLng={latLng}
        ownLocation={ownLocation}
        {...{
          flyToGeoCode,
          userPosition,
          setUserPosition,
          hasUserLocationButton,
        }}
      />
    </MapContainer>
  );
};

/** Helper Component needed to access map with useMap() renders sp markers
 * @param {array} sharingPoints - all public sharingpoints
 * @param {number} diameter - the area to zoom to
 * @param {array} latLng - geolocation [latitude, longitude] to center
 * @param {bool} ownLocation - determines whether a section of a circle or the entire map is shown
 * @returns react-leaflet map layer with center-control and markercluster groups
 */
const MapChild = ({
  sharingPoints,
  diameter,
  latLng,
  ownLocation,
  flyToGeoCode,
  userPosition,
  setUserPosition,
  hasUserLocationButton,
}) => {
  const clusterGroup = useRef();
  const radiusCircle = useRef();
  const map = useMap();

  const flyToBounds = useCallback(() => {
    if (flyToGeoCode) return;
    if (!ownLocation) {
      diameter = diameter || 7;
      latLng = latLng || [52.43179590114306, 13.523359681764884];
    }
    const clusterCurrent = clusterGroup.current;
    if (!ownLocation) {
      console.log("ownLocation");
      map.fitBounds(clusterCurrent.getBounds(), { padding: [20, 20] });
    } else if (latLng && diameter) {
      if (radiusCircle.current) {
        map.removeLayer(radiusCircle.current);
        radiusCircle.current = undefined;
      }
      console.log(latLng);
      const circle = L.circle(latLng, {
        radius: (diameter / 2) * 1000,
        fill: false,
        stroke: false,
      }).addTo(map);
      radiusCircle.current = circle;
      // console.log("-->",circle.getBounds())
      map.fitBounds(clusterCurrent.getBounds(), { padding: [20, 20] });
    } else {
      console.log(
        "map.fitBounds(clusterCurrent.getBounds(), { padding: [20, 20] });"
      );
      map.fitBounds(clusterCurrent.getBounds(), { padding: [20, 20] });
    }
  }, [map, latLng, diameter, ownLocation]);

  const flyTo = useCallback(() => {
    map.flyTo(flyToGeoCode, 12);
  }, [map, flyToGeoCode, diameter, ownLocation]);

  useEffect(() => {
    if (sharingPoints) flyToBounds();
  }, [sharingPoints, flyToBounds, latLng]);

  useEffect(() => {
    if (!(sharingPoints && flyToGeoCode)) return;
    flyTo();
  }, [sharingPoints, flyToGeoCode]);

  const { dashboardAssets, CustomMarker, createClusterIcon } =
    useContext(AssetsContext);
  // const openIcon = (state = "active") => {
  //   return L.icon({
  //     iconUrl: dashboardAssets.map_pin,
  //     iconSize: [35, "auto"], // size of the icon
  //     shadowSize: [50, 64], // size of the shadow
  //     iconAnchor: [14, 50], // point of the icon which will correspond to marker's location
  //     shadowAnchor: [4, 62], // the same for the shadow
  //     popupAnchor: [0, -50],
  //   });
  // };

  return (
    <>
      {hasUserLocationButton && (
        <Button
          icon={
            <img
              style={{ height: 30, width: "auto", position: "absolute" }}
              src={require("./searchlocation.png")}
              alt=""
            />
          }
          style={{
            color: "black",
            position: "absolute",
            zIndex: 9999,
            bottom: 100,
            right: 9,
            backgroundColor: "#fff",
            boxShadow: "0 1px 5px rgba(0,0,0,0.65)",
            height: 40,
            width: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
          }}
          disabled={!userPosition}
          onClick={() => {
            map.flyTo(userPosition, 18);
          }}
        />
      )}
      <MarkerClusterGroup
        ref={clusterGroup}
        iconCreateFunction={createClusterIcon}
      >
        <>
          {/* {map.getZoom() && hasUserLocationButton && (
            <LocationMarker {...{ map, userPosition, setUserPosition }} />
          )} */}
          {sharingPoints &&
            sharingPoints.map((sp) => {
              return (
                sp &&
                sp.gps_latitude && (
                  <Marker
                    key={sp.sp_serial_number}
                    spName={sp.sp_serial_number}
                    icon={CustomMarker()}
                    position={[sp.gps_latitude, sp.gps_longitude]}
                  >
                    <Popup>
                      <CustomMarkerPopup
                        box_status_check={sp.box_status_check}
                        city={sp.city}
                        sp_serial_number={sp.sp_serial_number}
                        street={sp.street}
                        street_number={sp.street_number}
                        zipcode={sp.zipcode}
                        nick_name={sp.nick_name}
                        w3w={sp.w3w}
                        showDetailsButton={true}
                      />
                    </Popup>
                  </Marker>
                )
              );
            })}
        </>
      </MarkerClusterGroup>
    </>
  );
};

Map.propTypes = mapProps;
export { Map };

const LocationMarker = ({ map, userPosition, setUserPosition }) => {
  useEffect(() => {
    if (!map) return;
    map.locate().on("locationfound", function (e) {
      setUserPosition(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
    });
  }, [map]);

  return userPosition === null ? null : (
    <Marker
      position={userPosition}
      icon={L.icon({
        iconSize: [25, 41],
        iconAnchor: [10, 41],
        popupAnchor: [2, -40],
        iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
        shadowUrl:
          "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png",
      })}
    />
  );
};
