/** @format */

import React, { useContext } from "react";
import { Confirm } from "semantic-ui-react";
import { AxiosContext } from "../../context/AxiosContext";
import { NotifyContext, UserManagementContext } from "../../context";

const DeleteGroup = ({ setIsOpen, isOpen, group }) => {
  const { axiosInstance } = useContext(AxiosContext);
  const { showErrorNotification, showSuccessNotification } =
    useContext(NotifyContext);
  const { deleteUserGroupByUserGroupName } = useContext(UserManagementContext);
  return (
    <Confirm
      size="mini"
      open={isOpen}
      content={`Are you sure you want to delete the Group ${group}?`}
      header="Confirmation"
      onConfirm={() => deleteUserGroupByUserGroupName(group, setIsOpen)}
      onCancel={() => {
        setIsOpen(false);
      }}
    />
  );
};

export default DeleteGroup;
