/** @format */

import React, { useCallback, useContext, useEffect, useRef } from "react";
import { Button } from "semantic-ui-react";
import { updateSideBarState } from "../../store/actions";

import "./index.scss";
import { useDispatch } from "react-redux";

import { animated, useSpring } from "react-spring";
import ProfileDropdown from "./ProfileDropdown";
import SideBarList from "./SideBarList";
import { MainLogo } from "./MainLogo";
import { AssetsContext } from "../../context/AssetsContext";

interface MainHeaderProps {
  lang: string;
  setLang: (lang: string) => void;
  session: any;
  collapsed: boolean;
}

const MainHeader = (props: MainHeaderProps) => {
  const { session, lang, setLang, collapsed } = props;
  const dispatch = useDispatch();
  const node = useRef<HTMLDivElement>(null);
  const [styles, api] = useSpring(() => ({ translateX: -800, delay: 0 }));
  const { mainColor } = useContext(AssetsContext);
  const changeSidebar = useCallback(() => {
    dispatch(updateSideBarState(!collapsed));
  }, [dispatch, collapsed]);

  /** handle click handler detects if click occurs outside component */
  const handleClick = useCallback(
    (e: any) => {
      if (!collapsed) {
        if (node.current && node.current) {
          return;
        } else {
          dispatch(updateSideBarState(true));
        }
      }
    },
    [dispatch, collapsed]
  );
  /** set mousedown listener for clickoutside component */
  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [collapsed, handleClick]);

  /** Spring animation style with animation prop setter in useEffect*/

  useEffect(() => {
    api.start({ translateX: collapsed ? -800 : 0, config: { duration: 100 } });
  }, [collapsed, api]);

  return (
    <div
      style={{
        backgroundColor: "#eee",
        boxShadow: "0.5px 0.5px 0.5px 0.5px #bbb",
      }}
      className={`main-header main-header--${
        collapsed ? "collapsed" : "opened"
      }`}
      ref={node}
    >
      <div style={{ backgroundColor: "#eee" }} className="left-side">
        <animated.div style={styles}>
          <div className="nav-menu" style={{ backgroundColor: mainColor }}>
            <SideBarList collapsed={collapsed} />
          </div>
        </animated.div>
        <div
          style={{ backgroundColor: "#eee" }}
          className="flex space-between burger-wrapper"
        >
          <Button
            style={{ color: mainColor }}
            className="burger-menu"
            icon={collapsed ? "sidebar" : "close"}
            onClick={changeSidebar}
          />

          <MainLogo />
        </div>
      </div>
      <div className="portal" id="header-portal"></div>
      <div className="nav-wrapper">
        <ProfileDropdown session={session} lang={lang} setLang={setLang} />
      </div>
    </div>
  );
};

export default MainHeader;
