import React, { CSSProperties, useContext, useMemo } from "react";
import { Icon, IconProps, Loader, SemanticICONS } from "semantic-ui-react";
import { AssetsContext } from "../../context";
import "./index.scss";
import { useTranslation } from "react-i18next";

interface SwobbeeButtonProps {
  title: string;
  icon?: SemanticICONS | "lock";
  onClick: () => void;
  style?: CSSProperties | {};
  iconColor?: IconProps["color"];
  isLoading?: boolean;
  disabled?: boolean;
  primary?: boolean | true;
  negative?: boolean;
  translate?: boolean;
}

const CustomButton = ({
  title,
  icon,
  onClick,
  style,
  iconColor,
  isLoading,
  disabled,
  primary,
  negative,
  translate,
}: SwobbeeButtonProps) => {
  const { dashboardAssets } = useContext(AssetsContext);

  const { t } = useTranslation();

  const backgroundColor = useMemo(() => {
    if (primary) {
      return dashboardAssets.main_color;
    } else if (negative) {
      return dashboardAssets.secondary_color;
    } else {
      return dashboardAssets.main_color;
    }
  }, [dashboardAssets, negative, primary]);

  const titleMemo = useMemo(() => {
    return translate ? t(title) : title;
  }, [translate, title, t]);

  return (
    <>
      {dashboardAssets ? (
        <button
          className="swobbee-button"
          onClick={onClick}
          style={{ opacity: disabled ? 0.5 : 1, backgroundColor, ...style }}
          disabled={disabled}
        >
          {isLoading ? <Loader inline active size="tiny" /> : null}
          {!isLoading ? titleMemo : null}{" "}
          {!isLoading && icon ? (
            <Icon name={icon} inverted color={iconColor} />
          ) : null}
        </button>
      ) : null}
    </>
  );
};

export { CustomButton };
