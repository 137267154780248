// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/line
import { ResponsiveLine, ResponsiveLineCanvas } from "@nivo/line";
import React, { useEffect } from "react";
import { BatteryImage } from "../UtilComponents";
import { upperFirst } from "lodash";
import ToolTipSwapPlot from "./ToolTipSwapPlot";
import moment from "moment";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

export default ({ data, xLabel, yLabel }) => {
  useEffect(() => {
    HTMLCanvasElement.prototype.getBBox = function () {
      return { width: "100%", height: "100%" };
    };
  }, []);

  return (
    <div style={{ height: 600 }}>
      <ResponsiveLineCanvas
        data={data}
        margin={{ top: 40, bottom: 150, left: 80 }}
        xScale={{ type: "point" }}
        tooltip={(e) => (
          <ToolTipSwapPlot
            {...{
              battery_type: e.point.serieId,
              x: e.point.data.x,
              y: e.point.data.y,
            }}
          />
        )}
        colors={{ scheme: "accent" }}
        animate={false}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 10,
          tickPadding: 20,
          tickRotation: 45,
          legend: xLabel,
          legendOffset: 100,
          legendPosition: "middle",
          format: function (value) {
            return data && data[0].data.length < 100
              ? value
              : moment(value, "DD-MM-YYYY").day() % 2
              ? value
              : "";
          },
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: yLabel,
          legendOffset: -40,
          legendPosition: "middle",
        }}
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateX: 0,
            translateY: 140,
            itemsSpacing: 10,
            itemDirection: "left-to-right",
            itemWidth: 140,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};
