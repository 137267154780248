/** @format */

// /** @format */

import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import {
  Table,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Grid,
  GridColumn,
  Input,
  Button,
  Loader,
  Label,
} from "semantic-ui-react";
import { useFormatTimestamp } from "../../hooks/use-format-timestamp";
import AuthenticationPlot from "./AuthenticationPlot";
import { AxiosContext } from "../../context/AxiosContext";

const applications = {
  "6hof405f01htgopi1kqdrlc30": { name: "ipc-app", color: "teal" },
  "3pand5jtq89e06a3ic0njsdvs6": { name: "swobbee-portal", color: "green" },
  "pabt635842b8o83vffujcndfb": { name: "battery-reading-tool", color: "blue" },
  "1iujo2300pr2tukptq3lvfvmik": { name: "swobbee-pwa", color: "black" },
  "5tb1ifpncqqmvv1fa9sk1jmhim": { name: "swobbee-native-app", color: "orange" },
};

export default () => {
  const [state, setState] = useState({
    column: null,
    data: null,
    direction: null,
    last_evaluated_key: null,
    loading: false,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const { timeSpans } = useFormatTimestamp();

  const [showStatisticModal, setShowStatisticModal] = useState();
  const { axiosInstance } = useContext(AxiosContext);
  const fetchMyAPI = async () => {
    setState({
      ...state,
      loading: true,
    });
    let { data } = await axiosInstance.get(`/authentications`, {
      params: {
        search_term: searchTerm,
      },
    });
    setState({
      ...state,
      data: data.data,
      last_evaluated_key: data.last_evaluated_key,
      loading: false,
    });
  };

  const loadMore = async () => {
    setState({
      ...state,
      loading: true,
    });
    let { data } = await axiosInstance.get(`/authentications`, {
      params: {
        search_term: searchTerm,
        ...state.last_evaluated_key,
      },
    });
    setState({
      ...state,
      data: [...state.data, ...data.data],
      last_evaluated_key: data.last_evaluated_key,
      loading: false,
    });
  };

  useEffect(() => {
    fetchMyAPI();
  }, []);

  const statusMapping = {
    SUCCESSFUL: "green",
    FAILED: "red",
    PENDING: "yellow",
  };

  const { column, data, direction } = state;

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const sortColumn = (column) => {
    if (state.column === column) {
      return setState({
        ...state,
        data: state.data.slice().reverse(),
        direction: state.direction === "ascending" ? "descending" : "ascending",
      });
    }
    return setState({
      ...state,
      column,
      data: _.sortBy(state.data, [column]),
      direction: "ascending",
    });
  };

  return (
    <Grid>
      {!data && (
        <GridColumn width="16">
          <Loader active inline size="large" />
        </GridColumn>
      )}

      {data && (
        <>
          {data && (
            <>
              <GridColumn width="16" style={{ padding: "30px 30px 0px" }}>
                <Button
                  basic
                  icon="chart bar"
                  content="statistic"
                  size="tiny"
                  onClick={() => setShowStatisticModal(true)}
                />
              </GridColumn>
              <GridColumn
                verticalAlign="middle"
                width="16"
                style={{ padding: "30px 30px 0px" }}
              >
                <span
                  style={{
                    float: "left",
                    color: "#444",
                    marginTop: 2,
                    fontSize: 18,
                  }}
                >
                  {data.length} Authentications{" "}
                </span>
                <Button
                  basic
                  circular
                  onClick={() => {
                    fetchMyAPI();
                  }}
                  icon="search"
                  size="mini"
                  style={{ float: "right", marginLeft: 14 }}
                />
                <Input
                  style={{ float: "right", width: 300 }}
                  icon="search"
                  placeholder="Search by username, name, company or email"
                  value={searchTerm}
                  onChange={handleChange}
                  size="mini"
                />
              </GridColumn>
            </>
          )}

          <GridColumn width="16" style={{ padding: "30px 30px 0px 30px" }}>
            {data && (
              <Table fixed sortable>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell
                      className="left-header"
                      sorted={column === "rfid_tag" ? direction : null}
                      onClick={() => sortColumn("rfid_tag")}
                    >
                      Username
                    </TableHeaderCell>

                    <TableHeaderCell
                      className="inner-header"
                      sorted={column === "name" ? direction : null}
                      onClick={() => sortColumn("name")}
                    >
                      Name
                    </TableHeaderCell>
                    <TableHeaderCell className="inner-header">
                      Company
                    </TableHeaderCell>
                    <TableHeaderCell
                      className="inner-header"
                      sorted={column === "created_by" ? direction : null}
                      onClick={() => sortColumn("created_by")}
                    >
                      Email
                    </TableHeaderCell>
                    <TableHeaderCell
                      className="inner-header"
                      sorted={column === "client_id" ? direction : null}
                      onClick={() => sortColumn("client_id")}
                    >
                      Application
                    </TableHeaderCell>

                    <TableHeaderCell
                      className="inner-header"
                      sorted={column === "updated_at" ? direction : null}
                      onClick={() => sortColumn("updated_at")}
                    >
                      Status
                    </TableHeaderCell>

                    <TableHeaderCell
                      className="right-header"
                      sorted={column === "updated_at" ? direction : null}
                      onClick={() => sortColumn("updated_at")}
                    >
                      Created at
                    </TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <Table.Body>
                  {data &&
                    data.map(
                      (
                        {
                          created_at,
                          email,
                          name,
                          user_group_name,
                          username,
                          status,
                          client_id,
                        },
                        index
                      ) => (
                        <TableRow key={index}>
                          <Table.Cell>{username}</Table.Cell>
                          <Table.Cell>{name ? name : "-"}</Table.Cell>
                          <Table.Cell>
                            {user_group_name ? user_group_name : "-"}
                          </Table.Cell>

                          <Table.Cell>{email ? email : "-"}</Table.Cell>
                          <Table.Cell>
                            <Label
                              content={
                                applications[client_id]
                                  ? applications[client_id].name
                                  : client_id
                              }
                              color={
                                applications[client_id]
                                  ? applications[client_id].color
                                  : "teal"
                              }
                              circular
                            />
                          </Table.Cell>

                          <Table.Cell>
                            <Label
                              content={status}
                              color={statusMapping[status]}
                              circular
                            />
                          </Table.Cell>

                          <Table.Cell>{timeSpans(created_at)}</Table.Cell>
                        </TableRow>
                      )
                    )}
                </Table.Body>
              </Table>
            )}
          </GridColumn>
          <GridColumn textAlign="center" width="16" style={{ color: "#444" }}>
            {state.data && state.data.length === 0 && "no data available"}
            {state.data && state.data.length > 0 && (
              <Button
                disabled={!state.last_evaluated_key}
                content="Load More"
                basic
                circular
                loading={state.loading}
                onClick={() => {
                  loadMore();
                }}
              />
            )}
          </GridColumn>
        </>
      )}
      <AuthenticationPlot {...{ showStatisticModal, setShowStatisticModal }} />
    </Grid>
  );
};
