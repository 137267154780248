import React, { useState, useMemo, useEffect, useContext } from "react";
import { Link, navigate } from "@reach/router";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  Loader,
  Header,
  TableHeader,
  TableRow,
  TableHeaderCell,
  GridColumn,
  TableCell,
  Table,
  Segment,
  Icon,
  Input,
} from "semantic-ui-react";
import MarkerMap from "../helpers/maps/MarkerMap";
import _ from "lodash";
import "./index.scss";
import { AxiosContext } from "../../context/AxiosContext";
import { CustomButton, CustomTableHeader } from "../CustomComponents";

const LocationsComponent = () => {
  const [selectedLocationHash, setSelectedLocationHash] = useState("");

  const [locationsTableData, setLocationsTableData] = useState();

  const { axiosInstance } = useContext(AxiosContext);
  const fetchLocations = async () => {
    const { data } = await axiosInstance.get("/locations");
    setLocationsTableData(data.data);
  };

  useEffect(() => {
    if (!axiosInstance) return;
    window.scroll(0, 0);
    fetchLocations();
  }, [axiosInstance]);

  const tableData = useMemo(() => {
    if (!locationsTableData) return;
    return _.sortBy(locationsTableData, ["street"]);
  }, [locationsTableData]);

  const markersData = useMemo(() => {
    if (!locationsTableData) return;
    return locationsTableData.map((location) => ({
      id: location.hash_key,
      label: (
        <Grid>
          <Grid.Row>
            <Grid.Column>
              {location.street} {location.street_number}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {location.city} {location.zipcode}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ),
      latLng: [+location.gps_latitude, +location.gps_longitude],
      color: location.state === "aktiv" ? "green" : "red",
      state: location.state,
    }));
  }, [locationsTableData]);

  //selectLocation cb for
  const selectLocation = (locationHash) => {
    setSelectedLocationHash((current) => {
      return current === locationHash ? undefined : locationHash;
    });
  };

  const [state, setState] = useState({
    column: null,
    data: null,
    direction: null,
    searchResults: null,
    searchTerm: null,
    last_evaluated_key: null,
  });

  useEffect(() => {
    setState({
      ...state,
      data: tableData,
      searchResults: tableData,
      column: state.column,
    });
    return () => {};
  }, [tableData]);

  const { column, data, searchTerm, searchResults, direction } = state;

  const sortColumn = (column) => {
    if (state.column === column) {
      return setState({
        ...state,
        data: searchResults.slice().reverse(),
        searchResults: searchResults.slice().reverse(),
        direction: state.direction === "ascending" ? "descending" : "ascending",
      });
    }
    return setState({
      ...state,
      column,
      data: _.sortBy(searchResults, [column]),
      searchResults: _.sortBy(
        searchResults,
        [
          (searchResult) =>
            searchResult[column] ? searchResult[column].toLowerCase() : "",
        ],
        [column]
      ),
      direction: "ascending",
    });
  };
  const handleChange = (event) => {
    setState({ ...state, searchTerm: event.target.value });
  };

  useEffect(() => {
    if (!data) return;
    const results = tableData.filter((item) => {
      return item.street
        .toLowerCase()
        .includes(searchTerm ? searchTerm.toLowerCase() : "");
    });
    setState({ ...state, searchResults: results });
  }, [searchTerm]);

  return (
    <>
      {tableData && markersData && (
        <Grid columns="1">
          <GridColumn style={{ height: "350px", paddingBottom: "14px" }}>
            <Segment style={{ padding: 0 }}>
              <MarkerMap
                latLng={{ latitude: 52.520008, longitude: 13.404954 }}
                data={markersData}
                selectedHash={selectedLocationHash}
                selectHandler={selectLocation}
                layout={"horizontal"}
                pathName={"locations"}
              />
            </Segment>
          </GridColumn>
          <GridColumn style={{ marginTop: "60px" }}>
            <Segment style={{ borderRadius: "10px", marginBottom: "50px" }}>
              <Grid columns="1">
                <GridColumn style={{ display: "flex",justifyContent: "space-between" }}>
                  <CustomButton
                    icon="plus"
                    size="mini"
                    circular
                    basic
                    floated="right"
                    onClick={() => navigate("/locations/create/step1")}
                    title="Create"
                  />
                  <Input
                    icon="search"
                    placeholder="Search by Address..."
                    onChange={handleChange}

                  />
                </GridColumn>
                <GridColumn>
                  <CustomTableHeader
                    itemCount={`${searchResults ? searchResults.length : 0} / ${
                      tableData.length
                    }`}
                    header="Locations"
                    subHeader={`Easily browse, search, and manage all your saved locations. Use the search bar to quickly find a location, or click "Add" to input new entries. Tap "Edit" to update information, or select "Details" to view full information for each location.`}
                  />
                </GridColumn>
                <GridColumn>
                  <TableComponent
                    {...{
                      searchResults,
                      column,
                      data,
                      direction,
                      searchTerm,
                      sortColumn,
                    }}
                  />
                </GridColumn>
              </Grid>
            </Segment>
          </GridColumn>
        </Grid>
      )}

      {!tableData && (
        <Grid>
          <GridColumn textAlign="left">
            <Loader size="large" active inline />
          </GridColumn>
        </Grid>
      )}
    </>
  );
};

LocationsComponent.propTypes = {
  locationsMapData: PropTypes.array,
  locationsMapDataFetching: PropTypes.bool,
  locationsTableData: PropTypes.array,
  locationsTableDataFetching: PropTypes.bool,
  locationStates: PropTypes.array,
  locationStatesFetching: PropTypes.bool,
  routeLocation: PropTypes.object,
  locationsTotal: PropTypes.number,
};

export default LocationsComponent;

const TableComponent = ({ searchResults, column, direction, sortColumn }) => {
  return (
    <>
      {searchResults && (
        <>
          {searchResults.length === 0 && (
            <GridColumn
              style={{ height: "200px" }}
              textAlign="center"
              verticalAlign="middle"
              width="16"
            >
              <Header as="h4" style={{ marginTop: "190px" }}>
                No data available
              </Header>
            </GridColumn>
          )}
        </>
      )}

      {searchResults && (
        <>
          {searchResults.length > 0 && (
            <>
              <Table fixed sortable>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell
                      className="left-header"
                      sorted={column === "w3w" ? direction : null}
                      onClick={() => sortColumn("w3w")}
                    >
                      w3w
                    </TableHeaderCell>
                    <TableHeaderCell
                      className="inner-header"
                      sorted={column === "street" ? direction : null}
                      onClick={() => sortColumn("street")}
                    >
                      Address
                    </TableHeaderCell>

                    <TableHeaderCell
                      sorted={column === "first_name" ? direction : null}
                      onClick={() => sortColumn("first_name")}
                      className="inner-header"
                    >
                      Name
                    </TableHeaderCell>

                    <TableHeaderCell
                      sorted={column === "email" ? direction : null}
                      onClick={() => sortColumn("email")}
                      className="inner-header"
                    >
                      E-mail
                    </TableHeaderCell>
                    <TableHeaderCell
                      className="inner-header"
                      sorted={column === "state" ? direction : null}
                      onClick={() => sortColumn("state")}
                    >
                      State
                    </TableHeaderCell>

                    <TableHeaderCell className="right-header">
                      Options
                    </TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <Table.Body>
                  {searchResults.map(
                    (
                      {
                        w3w,
                        street,
                        street_number,
                        city,
                        email,
                        state,
                        hash_key,
                        first_name,
                        family_name,
                      },
                      index
                    ) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Link
                            style={{ color: "black", fontWeight: "bold" }}
                            to={`/locations/${hash_key}`}
                          >
                            {w3w}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {street} {street_number}, {city}
                        </TableCell>
                        <TableCell>
                          {first_name} {family_name}
                        </TableCell>
                        <TableCell>{email}</TableCell>
                        <TableCell>{state}</TableCell>

                        <TableCell>
                          <Link to={`/locations/${hash_key}`}>
                            <Button className="details-button" basic circular>
                              details{" "}
                              <Icon name="arrow alternate circle right" />
                            </Button>
                          </Link>
                          <Link to={`/locations/edit/${hash_key}/step1`}>
                            <Button
                              style={{ paddingRight: "10px" }}
                              basic
                              circular
                            >
                              <Icon name="edit" />
                            </Button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </Table.Body>
              </Table>
            </>
          )}
        </>
      )}
    </>
  );
};
