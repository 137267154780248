import _ from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalContent,
  Select,
  Loader,
  Form,
  FormField,
  Divider,
  FormGroup,
  Header,
} from "semantic-ui-react";
import { restApiResponse } from "../../store/actions";
import { AxiosContext } from "../../context/AxiosContext";
import { NotifyContext } from "../../context";

const EditUser = ({
  userInfo,
  setUserInfo,
  showEditUsersGroup,
  setShowEditUsersGroup,
  userGroups,
  fetchUsers,
}) => {
  const dispatch = useDispatch();
  const [profile, setProfile] = useState();

  const [batteryTypeIds, setBatteryTypeIds] = useState([]);
  const [submissions, setSubmissions] = useState({});
  const { axiosInstance } = useContext(AxiosContext);

  const { showErrorNotification, showSuccessNotification } = useContext(NotifyContext);

  const fetchBatteryTypeIds = async () => {
    const { data } = await axiosInstance.get("/battery_type_ids");
    setBatteryTypeIds(data.data);
  };

  const submit = async () => {
    try {
      await axiosInstance.post(`/permissions`, submissions);
      setShowEditUsersGroup(false);
      fetchUsers();
      showSuccessNotification("User successfully edited")
    } catch (error) {
      showErrorNotification(`User failed to edited: ${error?.response?.data?.message}`)
    }
  };

  const fetchUserInfo = async () => {
    const { data } = await axiosInstance.get(
      `/users/profile/${userInfo.username}`
    );
    setProfile(data.data);
    setSubmissions({
      user_group: data.data.user_group_name,
      usernames: data.data.user,
      reservation_limit: data.data.reservation_limit,
      battery_type: data.data.subscription_info
        ? data.data.subscription_info.battery_type
        : null,
      amount: data.data.subscription_info
        ? data.data.subscription_info.amount
        : null,
      user_group_category: data.data.user_group_category,
    });
  };
  useEffect(() => {
    fetchBatteryTypeIds();
    fetchUserInfo();
  }, []);

  const showSubscriptionInfo = useMemo(() => {
    return ["vaas", "baas"].includes(submissions.user_group_category);
  }, [submissions]);

  const mapUserGroupCategory = useMemo(() => {
    if (!userGroups) return [];

    const result = userGroups.reduce((acc, item) => {
      acc[item.value] = item;
      return acc;
    }, {});
    return result;
  }, [userGroups]);

  return (
    <Modal
      open={showEditUsersGroup}
      onClose={() => {
        setUserInfo(null);
        setShowEditUsersGroup(false);
        setSubmissions({});
      }}
      onOpen={() => {
        setShowEditUsersGroup(true);
      }}
      size="tiny"
      style={{ paddingBottom: "20px" }}
    >
      <ModalHeader>Edit Permissions</ModalHeader>
      {!profile && (
        <ModalContent style={{ color: "#444" }}>
          <Loader active inverted inline size="large" />
        </ModalContent>
      )}
      {profile && (
        <ModalContent>
          <Form>
            <FormField>
              <label>User Group</label>
              <Select
                fluid
                search
                clearable
                value={submissions && submissions.user_group}
                placeholder="Select a user group..."
                options={userGroups}
                onChange={(_, data) => {
                  setSubmissions({
                    ...submissions,
                    user_group: data.value,
                    usernames: userInfo.username,
                    user_group_category:
                      mapUserGroupCategory[data.value]?.data
                        ?.user_group_category,
                  });
                }}
              />
            </FormField>

            <FormGroup widths="equal">
              <FormField>
                <label>Okai A reservation limit </label>
                <Input
                  placeholder="Reservation limit"
                  fluid
                  type="number"
                  icon="battery full"
                  defaultValue={
                    profile.reservation_limit &&
                    +profile.reservation_limit["Okai A"]
                  }
                  onChange={(_, { value }) => {
                    setSubmissions({
                      ...submissions,
                      reservation_limit_okai_a: value,
                    });
                  }}
                />
              </FormField>

              <FormField>
                <label>Okai B reservation limit </label>
                <Input
                  placeholder="Reservation limit"
                  fluid
                  type="number"
                  icon="battery full"
                  defaultValue={
                    profile.reservation_limit &&
                    +profile.reservation_limit["Okai B"]
                  }
                  onChange={(_, { value }) => {
                    setSubmissions({
                      ...submissions,
                      reservation_limit_okai_b: value,
                    });
                  }}
                />
              </FormField>
            </FormGroup>

            <FormGroup widths="equal">
              <FormField>
                <label>Segway reservation limit </label>
                <Input
                  placeholder="Reservation limit"
                  fluid
                  type="number"
                  icon="battery full"
                  defaultValue={
                    profile.reservation_limit &&
                    +profile.reservation_limit.Segway
                  }
                  onChange={(_, { value }) => {
                    setSubmissions({
                      ...submissions,
                      reservation_limit_segway: value,
                    });
                  }}
                />
              </FormField>

              <FormField>
                <label>Greenpack reservation limit </label>
                <Input
                  placeholder="Reservation limit"
                  fluid
                  type="number"
                  icon="battery full"
                  defaultValue={
                    profile.reservation_limit &&
                    +profile.reservation_limit.greenpack
                  }
                  onChange={(_, { value }) => {
                    setSubmissions({
                      ...submissions,
                      reservation_limit_greenpack: value,
                    });
                  }}
                />
              </FormField>
            </FormGroup>

            <FormGroup widths="equal">
              <FormField>
                <label>Kumpan reservation limit </label>
                <Input
                  placeholder="Reservation limit"
                  fluid
                  type="number"
                  icon="battery full"
                  defaultValue={
                    profile.reservation_limit &&
                    +profile.reservation_limit.kumpan
                  }
                  onChange={(_, { value }) => {
                    setSubmissions({
                      ...submissions,
                      reservation_limit_kumpan: value,
                    });
                  }}
                />
              </FormField>
              <FormField>
                <label>Torrot reservation limit </label>
                <Input
                  placeholder="Reservation limit"
                  fluid
                  type="number"
                  icon="battery full"
                  defaultValue={
                    profile.reservation_limit &&
                    +profile.reservation_limit.torrot
                  }
                  onChange={(_, { value }) => {
                    setSubmissions({
                      ...submissions,
                      reservation_limit_torrot: value,
                    });
                  }}
                />
              </FormField>
            </FormGroup>
            <Divider />

            {showSubscriptionInfo && (
              <>
                <Header>Subscription</Header>
                <FormGroup widths="equal">
                  <FormField>
                    <label>Battery Type</label>

                    <Select
                      fluid
                      clearable
                      placeholder="Battery Type"
                      type="text"
                      defaultValue={
                        profile.subscription_info &&
                        profile.subscription_info.battery_type
                      }
                      options={batteryTypeIds}
                      onChange={(_, { value }) =>
                        setSubmissions({
                          ...submissions,
                          battery_type: value,
                        })
                      }
                      search
                      selection
                    />
                  </FormField>

                  <FormField>
                    <label>Amount</label>
                    <Input
                      placeholder="Amount"
                      fluid
                      type="number"
                      defaultValue={
                        profile.subscription_info &&
                        profile.subscription_info.amount
                      }
                      onChange={(_, { value }) => {
                        setSubmissions({
                          ...submissions,
                          amount: value,
                        });
                      }}
                    />
                  </FormField>
                </FormGroup>
              </>
            )}
          </Form>
          <Divider />
          <Button
            floated="right"
            primary
            onClick={submit}
            disabled={
              showSubscriptionInfo &&
              !(submissions.battery_type && submissions.amount)
            }
          >
            Save
          </Button>
          <Button
            basic
            onClick={() => {
              setShowEditUsersGroup(false);
              setUserInfo(null);
            }}
            floated="right"
          >
            Cancel
          </Button>
        </ModalContent>
      )}
    </Modal>
  );
};

export default EditUser;
