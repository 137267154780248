/** @format */

// /** @format */

import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import {
  Table,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Grid,
  GridColumn,
  Input,
  Button,
  Loader,
  Label,
  Confirm,
  Container,
} from "semantic-ui-react";
import { useFormatTimestamp } from "../../hooks/use-format-timestamp";
import IsNewItem from "../IsNewItem";
import CreateRfid from "./CreateRfid";
import { restApiResponse } from "../../store/actions";
import { useDispatch } from "react-redux";
import { AxiosContext } from "../../context/AxiosContext";

export default () => {
  const [companyDropdown, setCompanyDropdown] = useState();
  const [rfid, setRfid] = useState();

  const [state, setState] = useState({
    column: null,
    data: null,
    direction: null,
    last_evaluated_key: null,
  });
  const [searchTerm, setSearchTerm] = useState("");

  const [showRfidForm, setShowRfidForm] = useState();
  const [showRfidDeleteConfirmaion, setShowRfidDeleteConfirmaion] = useState();

  const [edit, setEdit] = useState();
  const [rfidTag, setRfidTag] = useState();

  const { axiosInstance } = useContext(AxiosContext);
  const fetchMyAPI = async () => {
    let { data } = await axiosInstance.get(`/rfid`, {
      params: {
        search_term: searchTerm,
      },
    });
    setState({
      ...state,
      data: data.data,
      last_evaluated_key: data.last_evaluated_key,
    });
  };

  const loadMore = async () => {
    let { data } = await axiosInstance.get(`/rfid`, {
      params: {
        search_term: searchTerm,
        ...state.last_evaluated_key,
      },
    });
    setState({
      ...state,
      data: [...state.data, ...data.data],
      last_evaluated_key: data.last_evaluated_key,
    });
  };

  const { timeSpans } = useFormatTimestamp();

  const dispatch = useDispatch();

  const fetchCompanyDropdown = async () => {
    const { data } = await axiosInstance.get(
      "/user_group_dropdown"
    );
    setCompanyDropdown(data.data);
  };

  const deleteRfidCardByRfidTag = async () => {
    try {
      let { data } = await axiosInstance.delete(`/rfid/${rfidTag}`);
      fetchMyAPI();
      let response = {
        message: "Rfid successfuly deleted",
        request_parameter: "",
        status_code: 200,
      };
      dispatch(restApiResponse(response));
    } catch (error) {
      let response = {
        message: "Failed to delete rfid",
        request_parameter: "",
        status_code: 200,
      };
      dispatch(restApiResponse(response));
    }
    setShowRfidDeleteConfirmaion(false);
  };

  useEffect(() => {
    fetchMyAPI();
    fetchCompanyDropdown();
    return;
  }, []);

  const { column, data, direction } = state;

  const handleChange = event => {
    setSearchTerm(event.target.value);
  };

  const sortColumn = column => {
    if (state.column === column) {
      return setState({
        ...state,
        data: state.data.slice().reverse(),
        direction: state.direction === "ascending" ? "descending" : "ascending",
      });
    }
    return setState({
      ...state,
      column,
      data: _.sortBy(state.data, [column]),
      direction: "ascending",
    });
  };

  return (
    <Grid>
      {!data && (
        <GridColumn width="16">
          <Loader active inline size="large" />
        </GridColumn>
      )}

      {data && (
        <>
          {data && (
            <>
              <GridColumn width="16" style={{ padding: "30px 30px 0px" }}>
                <Button
                  basic
                  onClick={() => {
                    setShowRfidForm(true);
                  }}
                  icon="plus"
                  size="tiny"
                  content="Create Rfid"
                  style={{ float: "left" }}
                />
              </GridColumn>
              <GridColumn
                verticalAlign="middle"
                width="16"
                style={{ padding: "30px 30px 0px" }}
              >
                <span
                  style={{
                    float: "left",
                    color: "#444",
                    marginTop: 2,
                    fontSize: 18,
                  }}
                >
                  {data.length} Rfids{" "}
                </span>

                <Button
                  basic
                  circular
                  onClick={() => {
                    fetchMyAPI();
                  }}
                  icon="search"
                  size="mini"
                  style={{ float: "right", marginLeft: 14 }}
                />
                <Input
                  style={{ float: "right", width: 300 }}
                  placeholder="Search by username, name, company or email"
                  onChange={handleChange}
                  size="mini"
                />
              </GridColumn>
            </>
          )}

          <GridColumn width="16" style={{ padding: "30px 30px 0px 30px" }}>
            {data && (
                <Table fixed sortable>
                  <TableHeader>
                    <TableRow>
                      <TableHeaderCell
                        className="left-header"
                        sorted={column === "rfid_tag" ? direction : null}
                        onClick={() => sortColumn("rfid_tag")}
                      >
                        Rfid tag
                      </TableHeaderCell>
                      <TableHeaderCell
                        className="inner-header"
                        sorted={column === "name" ? direction : null}
                        onClick={() => sortColumn("name")}
                      >
                        Name
                      </TableHeaderCell>
                      <TableHeaderCell
                        className="inner-header"
                        sorted={column === "company" ? direction : null}
                        onClick={() => sortColumn("company")}
                      >
                        User group
                      </TableHeaderCell>

                      <TableHeaderCell
                        className="inner-header"
                        sorted={column === "created_at" ? direction : null}
                        onClick={() => sortColumn("created_at")}
                      >
                        Card number
                      </TableHeaderCell>
                      <TableHeaderCell
                        className="inner-header"
                        sorted={column === "activated" ? direction : null}
                        onClick={() => sortColumn("activated")}
                      >
                        Activated
                      </TableHeaderCell>
                      <TableHeaderCell className="inner-header">
                        Created by
                      </TableHeaderCell>
                      <TableHeaderCell
                        className="inner-header"
                        sorted={column === "created_at" ? direction : null}
                        onClick={() => sortColumn("created_at")}
                      >
                        Created at
                      </TableHeaderCell>

                      <TableHeaderCell
                        className="inner-header"
                        sorted={column === "updated_at" ? direction : null}
                        onClick={() => sortColumn("updated_at")}
                      >
                        Updated at
                      </TableHeaderCell>
                      <TableHeaderCell className="right-header">
                        Actions
                      </TableHeaderCell>
                    </TableRow>
                  </TableHeader>
                  <Table.Body>
                    {data &&
                      data.map(
                        (
                          {
                            rfid_tag,
                            activated,
                            updated_at,
                            created_at,
                            company,
                            name,
                            card_number,
                            created_by,
                          },
                          index
                        ) => (
                          <TableRow key={index}>
                            <Table.Cell>
                              <span style={{ marginRight: 10 }}>
                                {rfid_tag.replace(/^0+/, '')}
                              </span>
                              <IsNewItem created_at={+created_at} />
                            </Table.Cell>
                            <Table.Cell>{name}</Table.Cell>
                            <Table.Cell>{company}</Table.Cell>

                            <Table.Cell>{card_number}</Table.Cell>

                            <Table.Cell>
                              <Label
                                content={
                                  activated ? "Activated" : "Deactivated"
                                }
                                color={activated ? "green" : "grey"}
                                circular
                              />
                            </Table.Cell>
                            <Table.Cell>{created_by}</Table.Cell>
                            <Table.Cell>{timeSpans(created_at)}</Table.Cell>

                            <Table.Cell>{timeSpans(updated_at)}</Table.Cell>

                            <Table.Cell textAlign="center">
                              <Button
                                size="mini"
                                circular
                                icon="edit"
                                basic
                                onClick={() => {
                                  setEdit(true);
                                  setShowRfidForm(true);
                                  setRfidTag(rfid_tag);
                                }}
                              />

                              <Button
                                size="mini"
                                circular
                                icon="trash"
                                basic
                                onClick={() => {
                                  setRfidTag(rfid_tag);
                                  setShowRfidDeleteConfirmaion(true);
                                  setRfidTag(rfid_tag);
                                }}
                              />
                            </Table.Cell>
                          </TableRow>
                        )
                      )}
                  </Table.Body>
                </Table>
            )}
          </GridColumn>
          <GridColumn textAlign="center" width="16" style={{ color: "#444" }}>
            {state.data && state.data.length === 0 && "no data available"}
            {state.data && state.data.length > 0 && (
              <Button
                disabled={!state.last_evaluated_key}
                content="Load More"
                basic
                circular
                onClick={() => {
                  loadMore();
                }}
              />
            )}
          </GridColumn>
        </>
      )}
      <CreateRfid
        {...{
          companyDropdown,
          fetchMyAPI,
          setShowRfidForm,
          showRfidForm,
          edit,
          setEdit,
          rfidTag,
          rfid,
          setRfid,
        }}
      />

      <Confirm
        size="mini"
        onClose={true}
        closeOnEscape={true}
        open={showRfidDeleteConfirmaion}
        header="Confirmation"
        onCancel={() => setShowRfidDeleteConfirmaion(false)}
        onConfirm={deleteRfidCardByRfidTag}
      />
    </Grid>
  );
};
