import React, { useContext, useEffect, useMemo, useState } from "react";
import { useAxiosService } from "../../helper/axiosService";
import { createBrowserHistory } from "history";
import { Pie } from "@reactchartjs/react-chart.js";

import {
  ExportDataAsCsv,
  TableScreenTemplate,
  GroupsDropdownSearch,
  SWBSqlTable,
  UsersDropdownSearch,
} from "../UtilComponents";
import qs from "qs";
import { Grid, GridColumn, Button, Dropdown } from "semantic-ui-react";
import { AxiosContext } from "../../context/AxiosContext";

export default () => {
  const [sp_serial_number, setSelectedStation] = useState();
  const [selectedUserGroupName, setSelectedUserGroupName] = useState();
  const [selectedUsername, setSelectedUsername] = useState();

  const [location, setSelectedLocation] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [rating, setRatings] = useState();

  const [reason, setReason] = useState();
  const [page, setPage] = useState(0);
  const [reasonOptions, setReasonOptions] = useState();
  const [isInitialized, setIsInitialized] = useState();
  const { axiosInstance } = useContext(AxiosContext);
  const fetchReasons = async () => {
    try {
      const { data } = await axiosInstance.get(
        "/service-rating-options"
      );
      setReasonOptions(
        data.data.map((item) => {
          return { key: item, text: item, value: item };
        })
      );
    } catch (error) {}
  };

  useEffect(() => {
    fetchReasons();
    return;
  }, []);

  const {
    fetchedData,
    isLoading,
    fetchData,
    sum,
    sortParams,
    setSortParams,
    sortColumn,
    setPagination,
    exportedData,
    pagination,
  } = useAxiosService({ backendRoute: "/service-ratings" });

  const headers = useMemo(() => {
    return [
      {
        key: "username",
        header: "Username",
        isLink: true,
        navigateTo: "/profile/",
      },
      { key: "user_group_name", header: "User group" },
      { key: "rating", header: "Rating" },
      { key: "reason", header: "Reason" },
      { key: "category", header: "Category" },
      {
        key: "dynamo_swap_id",
        header: "Swap ID",
        isLink: true,
        navigateTo: "/swaps/",
      },
      {
        key: "created_at",
        header: "Timestamp",
        isDate: true,
        dateFormat: "DD-MM-yyyy HH:MM:ss",
      },
    ];
  }, []);

  const history = createBrowserHistory();

  useEffect(() => {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);

    setSortParams({
      order: filtersFromParams.order,
      sorted_by: filtersFromParams.sorted_by,
    });

    setSelectedUserGroupName(filtersFromParams.user_group_name);
    setSelectedUsername(filtersFromParams.username);
    setReason(filtersFromParams.reason);
    setPagination(pagination);
    const { rating } = filtersFromParams;
    setRatings(rating ? +rating : undefined);
    fetchData(false, {
      user_group_name: filtersFromParams.user_group_name,
      username: filtersFromParams.username,
      rating,
      reason: filtersFromParams.reason,
      sorted_by: filtersFromParams.sorted_by,
      order: filtersFromParams.order,
      page: pagination,
    });
    setIsInitialized(true);
  }, []);

  useEffect(() => {
    history.push(
      `?tab=service-ratings&reason=${reason ? reason : ""}&user_group_name=${
        selectedUserGroupName ? selectedUserGroupName : ""
      }&rating=${rating ? rating : ""}&sorted_by=${
        sortParams.sorted_by ? sortParams.sorted_by : ""
      }&order=${sortParams.order ? sortParams.order : ""}&username=${
        selectedUsername ? selectedUsername : ""
      }`
    );
    if (isInitialized) {
      fetchData(false, {
        sp_serial_number,
        user_group_name: selectedUserGroupName,
        sorted_by: sortParams.sorted_by,
        order: sortParams.order,
        rating,
        reason,
        username: selectedUsername,
        page: pagination,
      });
    }
  }, [
    selectedUsername,
    reason,
    rating,
    selectedUserGroupName,
    sortParams.order,
    sortParams.sorted_by,
  ]);

  return (
    <Grid style={{ padding: "10px" }}>
      <GridColumn width={16}>
        <RatingReport />
      </GridColumn>
      <TableScreenTemplate
        hasBreadCrumb={false}
        hasSegment={true}
        sum={sum}
        data={fetchedData}
        actions={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
              }}>
              <ExportDataAsCsv
                filename="swobbee-service-rating"
                data={exportedData}
                fetchData={() =>
                  fetchData(
                    null,
                    {
                      sp_serial_number,
                      user_group_name: selectedUserGroupName,
                      sorted_by: sortParams.sorted_by,
                      order: sortParams.order,
                      rating,
                      reason,
                      username: selectedUsername,
                    },
                    true
                  )
                }
              />

              <Button
                style={{ width: 130 }}
                icon="close"
                basic
                content="Clear filter"
                size="tiny"
                disabled={
                  !(
                    rating ||
                    reason ||
                    selectedUserGroupName ||
                    selectedUsername
                  )
                }
                onClick={() => {
                  // setName("");
                  setSelectedUserGroupName(null);
                  setRatings(null);
                  setSelectedUsername(null);
                  setReason(null);
                }}
              />
            </div>
          </div>
        }
        header_name={"Ratings"}
        description={
          "Explore the different ratings left by swobbee users for the provided services"
        }
        filter_components={
          <div
            className="swap-table-filter-container"
            style={{ width: "100%" }}>
            <div className="filter-components">
              {" "}
              <UsersDropdownSearch
                {...{ selectedUsername, setSelectedUsername }}
              />{" "}
            </div>
            <div className="filter-components">
              {" "}
              <GroupsDropdownSearch
                {...{ selectedUserGroupName, setSelectedUserGroupName }}
              />
            </div>
            <div className="filter-components">
              {" "}
              <Dropdown
                style={{ width: "100%" }}
                selection
                placeholder="Ratings"
                defaultValue={rating}
                value={rating}
                options={[
                  { key: "1", text: "1", value: 1 },
                  { key: "2", text: "2", value: 2 },
                  { key: "3", text: "3", value: 3 },
                  { key: "4", text: "4", value: 4 },
                  { key: "5", text: "5", value: 5 },
                ]}
                onChange={(_, { value }) => setRatings(value ? value : null)}
                clearable
              />
            </div>
            <div className="filter-components">
              {" "}
              <Dropdown
                style={{ width: "100%" }}
                selection
                placeholder="Reason"
                defaultValue={reason}
                value={reason}
                options={reasonOptions}
                onChange={(_, { value }) => setReason(value)}
                clearable
              />
            </div>
          </div>
        }
        // data={data}
        // sum_count={sum_count}
        table={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <SWBSqlTable
              {...{
                sortColumn,
                data: fetchedData,
                headers,
                isLoading,
                fetchData: fetchData,
                sum,
                fetchData,
                sortParams,
                setSortParams,

                searchParams: {
                  sp_serial_number,
                  startDate,
                  endDate,
                  user_group_name: selectedUserGroupName,
                  location,
                  sorted_by: sortParams.sorted_by,
                  order: sortParams.order,
                },
              }}
            />
          </div>
        }
      />
    </Grid>
  );
};

const Plot = ({ data }) => {
  return (
    <>
      {data && (
        <Pie
          height={150}
          options={{
            legend: { display: true, position: "right" },

            datalabels: {
              display: true,
              color: "white",
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  var dataset = data.datasets[tooltipItem.datasetIndex];
                  var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                  var total = meta.total;
                  var currentValue = dataset.data[tooltipItem.index];
                  var percentage = parseFloat(
                    ((currentValue / total) * 100).toFixed(1)
                  );
                  return " (" + percentage + "%)";
                },
                title: function (tooltipItem, data) {
                  return data.labels[tooltipItem[0].index];
                },
              },
            },
            plugins: {
              labels: {
                render: "percentage",
                fontColor: ["green", "white", "red"],
                precision: 2,
              },
            },
          }}
          data={{
            labels: data.res.map(({ rating }) => `${rating} ★`),
            datasets: [
              {
                label: "# of Ratings",
                data: data.res.map(({ count_rating }) => count_rating),
                backgroundColor: [
                  "rgba(255, 99, 132, 0.2)",
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                  "rgba(75, 192, 192, 0.2)",
                  "rgba(153, 102, 255, 0.2)",
                  "rgba(255, 159, 64, 0.2)",
                ],
                borderColor: [
                  "rgba(255, 99, 132, 1)",
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                  "rgba(75, 192, 192, 1)",
                  "rgba(153, 102, 255, 1)",
                  "rgba(255, 159, 64, 1)",
                ],
                borderWidth: 1,

                // hoverBackgroundColor: [
                // '#FF6384',
                // '#36A2EB',
                // 'red',
                // 'green',
                // "grey"
                // ]
              },
            ],
          }}
        />
      )}
    </>
  );
};

const RatingReport = () => {
  const [ratingReport, setRatingReport] = useState();
  const { axiosInstance } = useContext(AxiosContext);
  const fetchRatingReport = async () => {
    const { data } = await axiosInstance.get("/rating-report");
    setRatingReport(data.data);
  };

  useEffect(() => {
    fetchRatingReport();
  }, []);

  return (
    <>
      {ratingReport && (
        <Grid
          verticalAlign="middle"
          textAlign="center"
          columns={3}
          style={{ width: "100%", height: 300, color: "#444" }}>
          <GridColumn
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <span
              style={{
                fontWeight: 150,
                fontSize: 30,
              }}>
              Avg. rating
            </span>
            <span style={{ marginTop: 30, fontWeight: 150, fontSize: 50 }}>
              {ratingReport.avg}
            </span>
          </GridColumn>
          <GridColumn>
            <Plot {...{ data: ratingReport }} />
          </GridColumn>
          <GridColumn>
            <Grid columns={1}>
              <GridColumn>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      fontWeight: 200,
                      fontSize: 20,
                      color: "grey",
                    }}>
                    Total ratings
                  </div>
                  <div
                    style={{
                      fontWeight: 200,
                      fontSize: 30,
                      padding: 14,
                    }}>
                    {ratingReport.count}
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      fontWeight: 200,
                      fontSize: 20,
                      color: "grey",
                    }}>
                    Ratings with comment
                  </div>
                  <div
                    style={{
                      fontWeight: 200,
                      fontSize: 30,
                      padding: 14,
                    }}>
                    {ratingReport.count_with_review}
                  </div>
                </div>
              </GridColumn>
            </Grid>
          </GridColumn>
        </Grid>
      )}
    </>
  );
};
