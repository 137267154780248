import React, { useContext } from "react";
import {
  Button,
  Header,
  GridColumn,
  Grid,
  Message,
  Segment,
  List,
  ListItem,
  Label,
} from "semantic-ui-react";

import { upperFirst, isEmpty, isArray } from "lodash";
import { AssetsContext } from "../../../context/AssetsContext";

const SubmitionConfirmation = ({
  submissions,
  formStep,
  setFormStep,
  onSubmitStepThree,
  isPostLoading,
  mode,
}) => {
  const { mainColor } = useContext(AssetsContext);

  const stepSharingpoint = [
    "group_name",
    "role",
    "parent_company",
    "sharingpoints",
    "allowed_to_fill_in",
    "allowed_to_force_swap",
    "user_group_category",
  ];

  const stepReservation = [
    "allowed_to_swap_batteries",
    "reservation_interval",
    "reservation_request_limit",
    "reservation_limit_aes_ebike_akku_2+0_48v",
    "reservation_limit_greenpack",
    "reservation_limit_kumpan",
    "reservation_limit_okai_a",
    "reservation_limit_okai_b",
    "reservation_limit_segway",
    "reservation_limit_torrot",
  ];

  const stepBatteries = [
    "battery_read_permission",
    "battery_write_permission",
    "battery_pool",
    "battery_type_name",
    "manufacturer_name",
    "provider_name",
    "region_name",
  ];

  return (
    <Grid columns="1">
      <GridColumn textAlign="center">
        <Header as="h2">{submissions.group_name}</Header>
      </GridColumn>

      <GridColumn>
        <Message
          content={`Sure you want to ${mode} this user group?`}
          info
          size="big"
          icon="info"
        />
      </GridColumn>

      <GridColumn>
        <Segment>
          <Grid columns="1">
            <GridColumn>
              <Header
                as="h3"
                style={{ color: "#444" }}
              >
                Sharingpoint
              </Header>
            </GridColumn>
            <GridColumn>
              {stepSharingpoint.map((item) => (
                <>
                  <div
                    className="user-group-submition-key-value"
                    key={item}
                  >
                    <span
                      style={{ float: "left", fontSize: 18, color: "#444" }}
                    >
                      {upperFirst(item.replaceAll("_", " "))}
                    </span>{" "}
                    <span
                      className="user-group-submition-key-value"
                      style={{
                        fontSize: 18,
                        color: mainColor,
                        fontWeight: "bold",
                      }}
                    >
                      {typeof submissions[item] == "boolean" ? (
                        submissions[item] ? (
                          "allowed"
                        ) : (
                          "not allowed"
                        )
                      ) : !isArray(submissions[item]) ? (
                        submissions[item]
                      ) : (
                        <List>
                          {submissions[item].map((item) => (
                            <ListItem>
                              <Label
                                content={item}
                                key={item}
                                color="orange"
                                circular
                              />
                            </ListItem>
                          ))}
                        </List>
                      )}
                    </span>
                  </div>
                </>
              ))}
            </GridColumn>
          </Grid>
        </Segment>
      </GridColumn>

      {!isEmpty(stepReservation) && (
        <GridColumn>
          <Segment>
            <Grid columns="1">
              <GridColumn>
                <Header
                  as="h3"
                  style={{ color: "#444" }}
                >
                  Reservation
                </Header>
              </GridColumn>
              <GridColumn>
                {stepReservation.sort().map((item) => (
                  <>
                    <GridColumn
                      className="user-group-submition-key-value"
                      key={item}
                    >
                      <span
                        style={{
                          float: "left",
                          fontSize: 18,
                          color: "#444",
                        }}
                      >
                        {upperFirst(item.replaceAll("_", " "))}
                      </span>
                      <span
                        style={{
                          fontSize: 18,
                          color: mainColor,
                          fontWeight: "bold",
                        }}
                      >
                        {typeof submissions[item] == "boolean" ? (
                          submissions[item] ? (
                            "allowed"
                          ) : (
                            "not allowed"
                          )
                        ) : !isArray(submissions[item]) ? (
                          submissions[item] ? (
                            submissions[item]
                          ) : (
                            "-"
                          )
                        ) : (
                          <List>
                            {submissions[item].map((item) => (
                              <ListItem key={item}>
                                <Label
                                  content={item}
                                  orange="orange"
                                  circular
                                  style={{ float: "right" }}
                                />
                              </ListItem>
                            ))}
                          </List>
                        )}
                      </span>
                    </GridColumn>
                  </>
                ))}
              </GridColumn>
            </Grid>
          </Segment>
        </GridColumn>
      )}

      {!isEmpty(stepBatteries) && (
        <GridColumn>
          <Segment>
            <Grid columns="1">
              <GridColumn>
                <Header
                  as="h3"
                  style={{ color: "#444" }}
                >
                  Battery
                </Header>
              </GridColumn>
              <GridColumn>
                {stepBatteries.map((item) => (
                  <>
                    <GridColumn
                      className="user-group-submition-key-value"
                      key={item}
                    >
                      <span
                        style={{
                          float: "left",
                          fontSize: 18,
                          color: "#444",
                        }}
                      >
                        {upperFirst(item.replaceAll("_", " "))}
                      </span>
                      <span
                        style={{
                          fontSize: 18,
                          color: mainColor,
                          fontWeight: "bold",
                        }}
                      >
                        {typeof submissions[item] == "boolean" ? (
                          submissions[item] ? (
                            "allowed"
                          ) : (
                            "not allowed"
                          )
                        ) : !isArray(submissions[item]) ? (
                          submissions[item] ? (
                            submissions[item]
                          ) : (
                            "-"
                          )
                        ) : (
                          <List>
                            {submissions[item].map((item) => (
                              <ListItem key={item}>
                                <Label
                                  content={item}
                                  orange="orange"
                                  circular
                                  style={{ float: "right" }}
                                />
                              </ListItem>
                            ))}
                          </List>
                        )}
                      </span>
                    </GridColumn>
                  </>
                ))}
              </GridColumn>
            </Grid>
          </Segment>
        </GridColumn>
      )}

      <GridColumn width="16">
        <Button
          content="Yes"
          loading={isPostLoading}
          onClick={() => onSubmitStepThree()}
          fluid
          primary
          size="big"
        />
      </GridColumn>
      <GridColumn width="16">
        <Button
          size="big"
          content="Back"
          onClick={() => setFormStep(formStep - 1)}
          fluid
        />
      </GridColumn>
    </Grid>
  );
};

export default SubmitionConfirmation;
