import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from "react";
import {
  Grid,
  GridColumn,
  Segment,
  GridRow,
  Label,
  Table,
  Header,
  Breadcrumb,
  Loader,
  Message,
  Icon,
  TableBody,
  TableRow,
  TableCell,
} from "semantic-ui-react";
import BatteryImage from "../SharingPoints/BatteryImage";
import { SimpleMap } from "../helpers/maps/SimpleMarkerMap";
import { Link, navigate } from "@reach/router";
import { useFormatTimestamp } from "../../hooks/use-format-timestamp";
import { isArray, upperFirst } from "lodash";
import {
  BatteryIdDropdown,
  ExportDataAsCsv,
  SwapStateLabel,
  SWBLabel,
  SWBNavigateTo,
} from "../UtilComponents";
import "./style.scss";
import moment from "moment";
import { AxiosContext } from "../../context/AxiosContext";
import { AssetsContext } from "../../context/AssetsContext";
import {
  RESOURCE_METHODS,
  SWOBBEE_RESOURCES,
} from "../../constants/app-constants";
import { SwapContext } from "../../context";
import { CustomLoader } from "../SwobbeeUtilComponents";

export default ({ swapId }) => {
  const { mainColor } = useContext(AssetsContext);
  const [colorMapping, setColorMapping] = useState();
  const { timeSpans } = useFormatTimestamp();
  const { axiosInstance } = useContext(AxiosContext);
  const { fetchSwapById, swap, isLoadingSwap, setSwap } =
    useContext(SwapContext);

  const generateHexColor = useCallback(() => {
    let temp = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    if (!colorMapping) return temp;
    while (!Object.values(colorMapping).includes(temp)) {
      temp = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    }
    return temp;
  }, [colorMapping]);

  useEffect(() => {
    if (!swap) return;
    const temp = {};
    swap.events.forEach(({ battery_id }) => {
      temp[battery_id] = {
        color: generateHexColor(),
        count: temp[battery_id] ? temp[battery_id].count + 1 : 1,
      };
      setColorMapping(temp);
      console.log(temp);
    });
    console.log(colorMapping);
    return () => {
      setColorMapping(null);
      setSwap(null);
    };
  }, [swap, setSwap, setColorMapping, generateHexColor, colorMapping]);

  useEffect(() => {
    if (!axiosInstance) return;
    fetchSwapById(swapId);
  }, [axiosInstance, swapId, fetchSwapById]);

  const convertTimestamp = (timestamp) => {
    let d = new Date(+timestamp);
    return (
      `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}` +
      " - " +
      d.getHours() +
      ":" +
      d.getMinutes() +
      ":" +
      d.getSeconds()
    );
  };

  const filename = `swap_export_${swapId}`;

  const data = useMemo(() => {
    if (!swap) return;
    if (!swap.events) return;
    return swap.events.map(
      ({
        action,
        battery_id,
        timestamp,
        module_number,
        slot_number,
        soc_value,
      }) => {
        return {
          module_number,
          slot_number,
          timestamp: convertTimestamp(timestamp),
          battery_id,
          state_of_charge: soc_value ? soc_value + "%" : "-",
          action,
        };
      }
    );
  }, [swap]);

  const { isAllowedToAccess, userAllowedToAccess } = useContext(AssetsContext);

  const showSwapSection = useMemo(() => {
    if (!userAllowedToAccess) return;
    return isAllowedToAccess(SWOBBEE_RESOURCES.SWAPS, RESOURCE_METHODS.GET);
  }, [userAllowedToAccess, isAllowedToAccess]);

  return (
    <Grid>
      {isLoadingSwap ? (
        <GridColumn>
          <CustomLoader />
        </GridColumn>
      ) : null}

      {!isLoadingSwap && swap ? (
        <>
          <GridRow columns="1">
            <GridColumn style={{ color: "black", height: "400px" }}>
              <Segment style={{ padding: 0, height: "400px" }}>
                <SimpleMap latLng={[+swap.gps_latitude, +swap.gps_longitude]} />
              </Segment>
            </GridColumn>
          </GridRow>
          <GridRow>
            <Grid
              stackable
              className="grid-swap-segment"
              style={{ marginTop: "200px", margin: "auto" }}
              columns="1"
            >
              <GridColumn style={{ color: "black" }}>
                <Segment>
                  <Grid>
                    <GridColumn
                      width="16"
                      style={{ margin: "auto" }}
                      textAlign="center"
                    >
                      <h3 style={{ color: "black" }}>
                        {`${swap.street} ${swap.street_number}, ${swap.zipcode} ${swap.city}`}
                      </h3>
                    </GridColumn>
                    <GridColumn
                      width="16"
                      textAlign="center"
                      style={{ marginTop: "14px", marginBottom: "14px" }}
                    >
                      <BatteryImage
                        type={swap.battery_type}
                        view={"swapView"}
                        width="auto"
                        height="150px"
                        float="middle"
                        margin="auto"
                      />
                    </GridColumn>
                    <GridColumn
                      style={{ display: "flex", flexDirection: "column" }}
                      width="8"
                    >
                      <div className="swap-detail-key-value-grid-flex">
                        <span className="swap-detail-view-key">
                          Sharingpoint
                        </span>{" "}
                        <span className="swap-detail-view-value">
                          <Link to={`/sharingpoints/${swap.sp_serial_number}`}>
                            {swap.sp_serial_number}
                          </Link>
                        </span>
                      </div>

                      <div className="swap-detail-key-value-grid-flex">
                        <span className="swap-detail-view-key">
                          Battery type
                        </span>{" "}
                        <span className="swap-detail-view-value">
                          {swap.battery_type}
                        </span>
                      </div>

                      <div className="swap-detail-key-value-grid-flex">
                        <span className="swap-detail-view-key">Requested</span>{" "}
                        <span className="swap-detail-view-value">
                          {swap.requested !== -1 ? (
                            swap.requested
                          ) : (
                            <Label
                              content={"FILL-IN"}
                              color="blue"
                              circular
                              style={{ width: 100 }}
                            />
                          )}
                        </span>
                      </div>

                      <div className="swap-detail-key-value-grid-flex">
                        <span className="swap-detail-view-key">Out/In</span>{" "}
                        <span className="swap-detail-view-value">
                          {swap.count_out}/{swap.count_in}
                        </span>
                      </div>

                      <div className="swap-detail-key-value-grid-flex">
                        <span className="swap-detail-view-key">Modules</span>
                        <span className="swap-detail-view-value">
                          {swap.swap_status_map
                            ? Object.keys(swap.swap_status_map).join(", ")
                            : "-"}
                        </span>
                      </div>
                    </GridColumn>

                    <GridColumn
                      style={{ display: "flex", flexDirection: "column" }}
                      width="8"
                    >
                      <div className="swap-detail-key-value-grid-flex">
                        <span className="swap-detail-view-key">State</span>{" "}
                        <span className="swap-detail-view-value">
                          <SwapStateLabel state={swap.state} />
                        </span>
                      </div>
                      <div className="swap-detail-key-value-grid-flex">
                        <span className="swap-detail-view-key">
                          Requested by
                        </span>{" "}
                        <span className="swap-detail-view-value">
                          <SWBNavigateTo
                            style={{ fontWeight: 16 }}
                            view="UserProfileView"
                            propId={swap.created_by}
                          />
                        </span>
                      </div>
                      <div className="swap-detail-key-value-grid-flex">
                        <span className="swap-detail-view-key">User group</span>
                        <span className="swap-detail-view-value">
                          {swap.user_group_name
                            ? swap.user_group_name?.substring(0, 35)
                            : "-"}
                        </span>
                      </div>
                      <div className="swap-detail-key-value-grid-flex">
                        <span className="swap-detail-view-key">
                          Requested at
                        </span>{" "}
                        <span sclassName="swap-detail-view-value">
                          {timeSpans(swap.created_at, mainColor, 16)}
                        </span>
                      </div>
                      <div className="swap-detail-key-value-grid-flex">
                        <span className="swap-detail-view-key">Updated at</span>{" "}
                        <span className="swap-detail-view-value">
                          {timeSpans(swap.updated_at, mainColor, 16)}
                        </span>
                      </div>
                    </GridColumn>
                  </Grid>
                </Segment>
              </GridColumn>
              <GridColumn>
                <Segment>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Header style={{ color: "#444" }}>
                      {swap.door_events.length} Door events
                    </Header>
                  </div>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell className="left-header">
                          Event ID
                        </Table.HeaderCell>
                        <Table.HeaderCell className="inner-header">
                          Module
                        </Table.HeaderCell>
                        {swap.swap_type === "SINGLE_LOCK_SWAP" && (
                          <Table.HeaderCell className="inner-header">
                            Slot
                          </Table.HeaderCell>
                        )}

                        <Table.HeaderCell className="inner-header">
                          Timestamp
                        </Table.HeaderCell>

                        <Table.HeaderCell className="right-header">
                          State
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <TableBody>
                      {swap.door_events.map(
                        ({
                          module_number,
                          door_state,
                          created_at,
                          event_id,
                          slot_number,
                        }) => (
                          <TableRow>
                            <TableCell>{event_id}</TableCell>
                            <TableCell>{module_number}</TableCell>
                            {swap.swap_type === "SINGLE_LOCK_SWAP" && (
                              <TableCell>{slot_number}</TableCell>
                            )}
                            <TableCell>
                              {moment(created_at).format("DD-MM-YYYY HH:mm:ss")}
                            </TableCell>
                            <TableCell>
                              <SWBLabel
                                content={door_state}
                                activated={door_state === "OPEN"}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                  {!swap.door_events.length && (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        style={{
                          alignSelf: "center",
                          color: "#444",
                          textAlign: "center",
                        }}
                      >
                        no data available
                      </span>
                    </div>
                  )}
                </Segment>
              </GridColumn>
              <GridColumn style={{ color: "black" }}>
                <Segment>
                  <Grid>
                    {swap && !swap.events.length && (
                      <GridColumn width={16}>
                        <Message icon="info">
                          <Message.List>
                            <Message.Item>
                              No relevant events available
                            </Message.Item>
                          </Message.List>
                        </Message>
                      </GridColumn>
                    )}

                    {showSwapSection &&
                      swap.events &&
                      colorMapping &&
                      swap.events &&
                      isArray(swap.events) && (
                        <GridColumn width="16">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Header style={{ color: "#444" }}>
                              {swap.events.length} Battery events
                            </Header>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <ExportDataAsCsv {...{ data, filename }} />
                            </div>
                          </div>

                          <Table>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell className="left-header">
                                  Module
                                </Table.HeaderCell>
                                <Table.HeaderCell className="inner-header">
                                  Slot
                                </Table.HeaderCell>

                                <Table.HeaderCell className="inner-header">
                                  Timestamp
                                </Table.HeaderCell>

                                {swap.is_cmcu && (
                                  <Table.HeaderCell className="inner-header">
                                    Battery id
                                  </Table.HeaderCell>
                                )}

                                {swap.is_cmcu && (
                                  <Table.HeaderCell className="inner-header">
                                    Soc
                                  </Table.HeaderCell>
                                )}
                                <Table.HeaderCell className="right-header">
                                  Action
                                </Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {swap &&
                                swap.events &&
                                isArray(swap.events) &&
                                swap.events.map((item) => (
                                  <>
                                    {(!item.slot_value || item.slot_value) && (
                                      <Table.Row>
                                        <Table.Cell>
                                          {item.module_number}
                                        </Table.Cell>
                                        <Table.Cell>
                                          {item.slot_number}
                                        </Table.Cell>

                                        <Table.Cell>
                                          {moment(
                                            item.timestamp
                                              ? item.timestamp
                                              : item.created_at
                                          ).format("DD-MM-YYYY HH:mm:ss")}
                                        </Table.Cell>
                                        {swap.is_cmcu && (
                                          <Table.Cell textAlign="left">
                                            {swap.initial_serial_numbers.includes(
                                              item.battery_id
                                            ) ? (
                                              <>
                                                {colorMapping[item.battery_id]
                                                  .count > 1 && (
                                                  <Icon
                                                    name="circle"
                                                    style={{
                                                      color:
                                                        colorMapping[
                                                          item.battery_id
                                                        ].color,
                                                    }}
                                                  />
                                                )}
                                                <BatteryIdDropdown
                                                  isInitialBatteryId={swap.initial_serial_numbers.includes(
                                                    item.battery_id
                                                  )}
                                                  text={item.battery_id}
                                                  battery_id={item.battery_id}
                                                  battery_type={upperFirst(
                                                    swap.battery_type
                                                  )}
                                                />
                                              </>
                                            ) : (
                                              <>
                                                {colorMapping[item.battery_id]
                                                  .count > 1 && (
                                                  <Icon
                                                    name="circle"
                                                    style={{
                                                      color:
                                                        colorMapping[
                                                          item.battery_id
                                                        ].color,
                                                    }}
                                                  />
                                                )}
                                                <BatteryIdDropdown
                                                  isInitialBatteryId={swap.initial_serial_numbers.includes(
                                                    item.battery_id
                                                  )}
                                                  battery_id={item.battery_id}
                                                  battery_type={upperFirst(
                                                    swap.battery_type
                                                  )}
                                                />
                                              </>
                                            )}
                                          </Table.Cell>
                                        )}

                                        {swap.is_cmcu && (
                                          <Table.Cell>
                                            {item.swap_order_id === swapId
                                              ? ![null, undefined].includes(
                                                  item.battery_in_soc
                                                )
                                                ? item.battery_in_soc + "%"
                                                : "-"
                                              : ![null, undefined].includes(
                                                  item.soc_value
                                                )
                                              ? item.soc_value + "%"
                                              : "-"}
                                          </Table.Cell>
                                        )}

                                        <Table.Cell>
                                          <Label
                                            circular
                                            content={item.action}
                                            color={
                                              item.action === "IN"
                                                ? "green"
                                                : "red"
                                            }
                                            style={{ width: 40 }}
                                          />
                                        </Table.Cell>
                                      </Table.Row>
                                    )}
                                  </>
                                ))}
                            </Table.Body>
                          </Table>
                        </GridColumn>
                      )}
                  </Grid>
                </Segment>
              </GridColumn>
            </Grid>
          </GridRow>
        </>
      ) : null}
    </Grid>
  );
};
