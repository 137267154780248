import axios from "axios";
import { orderBy } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Container, Input, Loader } from "semantic-ui-react";
import LandingComponent from "../components/LandingComponent";
import LoginMobileScreen from "../screens/LoginMobileScreen";

import awsconfig from "../config/awsconfig";
import { useIsMountedRef } from "../hooks/use-is-mounted-ref";
import { Location, Redirect, Router } from "@reach/router";
import SignupScreen from "./SignupScreen";
import ResetPasswordForm from "../components/LandingComponent/forms/ResetPasswordForm";

import "./style.scss";
import SwobbeePublicLocationMap from "../components/SwobbeePublicLocationMap";

const sharingPointsUrl =
  awsconfig.REACT_APP_PUBLIC_API + "/sharingpoints/public_sharingpoints";

const berlinAlexanderPlatz = [52.519497922, 13.407165038];

const orderSharingPoints = (sps) => {
  let ordered = orderBy(sps, "location.distance");
  return ordered;
};
/**
 * LandingScreen is displayed always if not logged in
 * queries geolocation
 * queries public sharingpoints by geolocation or without
 * @param {object} location - location object from @reach/router
 * @returns div class screen with landingComponent
 */
const LandingScreen = () => {
  const isMountedRef = useIsMountedRef();
  const [ownLocation, setOwnLocation] = useState();
  const [latLng, setLatLng] = useState();
  const [sharingPoints, setSharingPoints] = useState();

  const setDefaultLocation = useCallback(() => {
    setOwnLocation(false);
    setLatLng(berlinAlexanderPlatz);
  }, []);
  /** query geolocation */
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          // if position is correct
          if (pos && pos.coords) {
            let latLng = [pos.coords.latitude, pos.coords.longitude];
            setOwnLocation(true);
            setLatLng(latLng);
          } else {
            // use default ios problem https://developer.apple.com/forums/thread/133067
            setDefaultLocation();
          }
        },
        (error) => {
          console.info("geolocation error", error);
          setDefaultLocation();
        },
        { timeout: 500 }
      );
    } else {
      setDefaultLocation();
    }
  }, []);

  const fetchSharingPoints = async () => {
    let params = {};
    if (ownLocation && latLng) {
      params = {
        latitude: latLng[0],
        longitude: latLng[1],
      };
    }
    const { data } = await axios.get(sharingPointsUrl, {
      params,
    });
    if (isMountedRef.current && !sharingPoints) {
      let sps = data.data;
      if (ownLocation) {
        sps = orderSharingPoints(sps);
        if (sps[0]?.location.distance > 260) {
          setOwnLocation(false);
        }
      }
      setSharingPoints(sps);
    }
  };

  /** fetch sharingpoints */
  useEffect(() => {
    if (!latLng) return;
    if (isMountedRef.current) fetchSharingPoints();
  }, [latLng, isMountedRef, ownLocation, sharingPoints]);

  return (
    <Container className="screen--landing screen" fluid>
      {window.innerWidth > 425 && (
        <Router>
          <ResetPasswordForm path="/reset-password" />
          <LandingComponent
            path="/"
            sharingPoints={sharingPoints}
            latLng={latLng}
            ownLocation={ownLocation}
          />
          <SwobbeePublicLocationMap
            {...{
              latLng,
              ownLocation,
            }}
            path="/swobbee-locations"
          />

          <SignupScreen path="/oauth2-signup" />
          <Redirect noThrow from="*" to="/" />
        </Router>
      )}

      {window.innerWidth < 425 && (
        <Location>
          <Router>
            <SwobbeePublicLocationMap
              {...{
                latLng,
                ownLocation,
              }}
              path="/swobbee-locations"
            />
            <LoginMobileScreen path="/" />
            <SignupScreen path="/oauth2-signup" />
            <Redirect noThrow from="*" to="/" />
          </Router>
        </Location>
      )}
    </Container>
  );
};

export default LandingScreen;
