import React, { useState, useMemo, useContext } from "react";
import { Tab, Container, Grid, GridColumn } from "semantic-ui-react";
import Users from "./Users";
import Groups from "./Groups";
import { navigate } from "@reach/router";
import qs from "qs";
import Rfids from "./Rfids";
import Authentications from "./Authentications";
import RfidValidationRequests from "./RfidValidationRequests";

import "./style.scss";
import {
  RESOURCE_METHODS,
  SWOBBEE_RESOURCES,
} from "../../constants/app-constants";
import { AssetsContext } from "../../context";

const tabs = {
  0: "users",
  1: "groups",
  2: "rfids",
  3: "authentications",
  4: "rfidValidationRequests",
};

const indexes = {
  users: 0,
  groups: 1,
  rfids: 2,
  authentications: 3,
  rfidValidationRequests: 4,
  "oauth2-signups": 5,
};
const panes = [
  {
    menuItem: {
      key: "users",
      icon: "user",
      content: "Users",
      resource: SWOBBEE_RESOURCES.USERS,
    },
    render: () => {
      return (
        <Tab.Pane>
          <Users />
        </Tab.Pane>
      );
    },
  },
  {
    menuItem: {
      key: "groups",
      icon: "group",
      content: "Groups",
      resource: SWOBBEE_RESOURCES.USER_GROUPS,
    },
    render: () => {
      return (
        <Tab.Pane>
          <Groups />
        </Tab.Pane>
      );
    },
  },
  {
    menuItem: {
      key: "rfids",
      icon: "dot circle outline",
      content: "Rfids",
      resource: SWOBBEE_RESOURCES.RFIDS,
    },
    render: () => {
      return (
        <Tab.Pane>
          <Rfids />
        </Tab.Pane>
      );
    },
  },

  {
    menuItem: {
      key: "rfidValidationRequests",
      icon: "dot circle",
      content: "Rfid-Validations",
      resource: SWOBBEE_RESOURCES.RFIDS_VALIDATION,
    },
    render: () => {
      return (
        <Tab.Pane>
          <RfidValidationRequests />
        </Tab.Pane>
      );
    },
  },
  {
    menuItem: {
      key: "authentications",
      icon: "sign-in",
      content: "Authentications",
      resource: SWOBBEE_RESOURCES.AUTHENTICATIONS,
    },
    render: () => {
      return (
        <Tab.Pane>
          <Authentications />
        </Tab.Pane>
      );
    },
  },
];

export default () => {
  const [activeIndex, setActiveIndex] = useState(
    indexes[qs.parse(window.location.search, { ignoreQueryPrefix: true }).tab]
  );
  const { isAllowedToAccess, userAllowedToAccess } = useContext(AssetsContext);

  const filteredPanes = useMemo(() => {
    if (!userAllowedToAccess) return;
    const res = panes.filter((item) => {
      return isAllowedToAccess(item.menuItem.resource, RESOURCE_METHODS.GET);
    });
    console.log("res", res);
    return res;
  }, [userAllowedToAccess, isAllowedToAccess]);

  const handleOnTabChange = (activeIndex) => {
    setActiveIndex(activeIndex);
    navigate(`/user-management?tab=${tabs[activeIndex]}`);
  };

  return (
    <Container
      className="user-management-container"
      fluid
      style={{ marginBottom: "50px" }}
    >
      <Grid columns="1">
        <GridColumn>
          <Tab
            renderActiveOnly
            activeIndex={activeIndex}
            panes={filteredPanes}
            onTabChange={(_, { activeIndex }) => handleOnTabChange(activeIndex)}
          />
        </GridColumn>
      </Grid>
    </Container>
  );
};
