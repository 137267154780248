/** @format */

let awsconfig: any= {};
if (process.env.REACT_APP_NODE_ENV === "production") {
  awsconfig = {
    ADMIN_URL_PREFIX: "admin.",
    REACT_APP_REST_API: process.env.REACT_APP_REST_API_REL,
    REACT_APP_REST_CLIENT_ID: process.env.REACT_APP_REST_CLIENT_ID_REL,
    REACT_APP_WS_API: process.env.REACT_APP_WS_API_REL,
    REACT_APP_PUBLIC_API: process.env.REACT_APP_PUBLIC_API_REL,
  };
} else if (process.env.REACT_APP_NODE_ENV === "dev") {
  awsconfig = {
    ADMIN_URL_PREFIX: "admin-dev.",
    REACT_APP_REST_API: process.env.REACT_APP_REST_API_DEV,
    REACT_APP_REST_CLIENT_ID: process.env.REACT_APP_REST_CLIENT_ID_DEV,
    REACT_APP_WS_API: process.env.REACT_APP_WS_API_DEV,
    REACT_APP_PUBLIC_API: process.env.REACT_APP_PUBLIC_API_DEV,
  };
} else if (process.env.REACT_APP_NODE_ENV === "local") {
  awsconfig = {
    ADMIN_URL_PREFIX: "admin-dev.",
    REACT_APP_REST_API: process.env.REACT_APP_REST_API_DEV,
    REACT_APP_REST_CLIENT_ID: process.env.REACT_APP_REST_CLIENT_ID_DEV,
    REACT_APP_WS_API: process.env.REACT_APP_WS_API_DEV,
    REACT_APP_PUBLIC_API: process.env.REACT_APP_PUBLIC_API_LOCAL_DEV,
  };
} else if (process.env.REACT_APP_NODE_ENV === "local_beta") {
  awsconfig = {
    ADMIN_URL_PREFIX: "admin-beta.",
    REACT_APP_REST_API: process.env.REACT_APP_REST_API_BETA,
    REACT_APP_REST_CLIENT_ID: process.env.REACT_APP_REST_CLIENT_ID_BETA,
    REACT_APP_WS_API: process.env.REACT_APP_WS_API_BETA,
    REACT_APP_PUBLIC_API: process.env.REACT_APP_PUBLIC_API_LOCAL_BETA,
  };
} else if (process.env.REACT_APP_NODE_ENV === "beta") {
  awsconfig = {
    ADMIN_URL_PREFIX: "admin-beta.",
    REACT_APP_REST_API: process.env.REACT_APP_REST_API_BETA,
    REACT_APP_REST_CLIENT_ID: process.env.REACT_APP_REST_CLIENT_ID_BETA,
    REACT_APP_WS_API: process.env.REACT_APP_WS_API_BETA,
    REACT_APP_PUBLIC_API: process.env.REACT_APP_PUBLIC_API_BETA,
  };
} else if (process.env.REACT_APP_NODE_ENV === "alexis") {
  awsconfig = {
    ADMIN_URL_PREFIX: "admin.",
    REACT_APP_REST_API: process.env.REACT_APP_REST_API_ALEXIS,
    REACT_APP_REST_CLIENT_ID: process.env.REACT_APP_REST_CLIENT_ID_ALEXIS,
    REACT_APP_WS_API: process.env.REACT_APP_WS_API_ALEXIS,
    REACT_APP_PUBLIC_API: process.env.REACT_APP_PUBLIC_API_ALEXIS,
  };
} else if (process.env.REACT_APP_NODE_ENV === "local_rel") {
  awsconfig = {
    ADMIN_URL_PREFIX: "admin.",
    REACT_APP_REST_API: process.env.REACT_APP_REST_API_REL,
    REACT_APP_REST_CLIENT_ID: process.env.REACT_APP_REST_CLIENT_ID_REL,
    REACT_APP_WS_API: process.env.REACT_APP_WS_API_REL,
    REACT_APP_PUBLIC_API: process.env.REACT_APP_PUBLIC_API_LOCAL_REL,
  };
} else if (process.env.REACT_APP_NODE_ENV === "phan") {
  awsconfig = {
    ADMIN_URL_PREFIX: "admin.",
    REACT_APP_REST_API: process.env.REACT_APP_REST_API_PHAN,
    REACT_APP_REST_CLIENT_ID: process.env.REACT_APP_REST_CLIENT_ID_PHAN,
    REACT_APP_WS_API: process.env.REACT_APP_WS_API_PHAN,
    REACT_APP_PUBLIC_API: process.env.REACT_APP_PUBLIC_API_PHAN,
  };
} else if (process.env.REACT_APP_NODE_ENV === "local_dev_us") {
  awsconfig = {
    ADMIN_URL_PREFIX: "admin-dev.us.",
    REACT_APP_REST_CLIENT_ID: process.env.REACT_APP_REST_CLIENT_ID_DEV_US,
    REACT_APP_PUBLIC_API: process.env.REACT_APP_PUBLIC_API_LOCAL,
  };
} else if (process.env.REACT_APP_NODE_ENV === "dev_us") {
  awsconfig = {
    ADMIN_URL_PREFIX: "admin-dev.us",
    REACT_APP_REST_CLIENT_ID: process.env.REACT_APP_REST_CLIENT_ID_DEV_US,
    REACT_APP_PUBLIC_API: process.env.REACT_APP_PUBLIC_API_DEV_US,
  };
} else if (process.env.REACT_APP_NODE_ENV === "local_rel_us") {
  awsconfig = {
    ADMIN_URL_PREFIX: "admin.us.",
    REACT_APP_REST_CLIENT_ID: process.env.REACT_APP_REST_CLIENT_ID_REL_US,
    REACT_APP_PUBLIC_API: process.env.REACT_APP_PUBLIC_API_LOCAL,
  };
} else if (process.env.REACT_APP_NODE_ENV === "rel_us") {
  awsconfig = {
    ADMIN_URL_PREFIX: "admin.us.",
    REACT_APP_REST_CLIENT_ID: process.env.REACT_APP_REST_CLIENT_ID_REL_US,
    REACT_APP_PUBLIC_API: process.env.REACT_APP_PUBLIC_API_REL_US,
  } 

} else if(process.env.REACT_APP_NODE_ENV === "enyring-beta") {
  awsconfig = {
    ADMIN_URL_PREFIX: "admin.us.",
    REACT_APP_REST_CLIENT_ID: process.env.REACT_APP_REST_CLIENT_ID_ENYRING_BETA,
    REACT_APP_PUBLIC_API: process.env.REACT_APP_PUBLIC_API_ENYRING_BETA,
  } }
  else if(process.env.REACT_APP_NODE_ENV === "enyring-beta-local") {
    awsconfig = {
      ADMIN_URL_PREFIX: "admin.us.",
      REACT_APP_REST_CLIENT_ID: process.env.REACT_APP_REST_CLIENT_ID_ENYRING_BETA,
      REACT_APP_PUBLIC_API: process.env.REACT_APP_PUBLIC_API_ENYRING_BETA_LOCAL,
    } };
export default awsconfig;
