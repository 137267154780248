/** @format */

import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";

import {
  Segment,
  Button,
  Modal,
  Loader,
  Comment,
  Message,
  GridColumn,
  Icon,
  Header,
  Grid,
} from "semantic-ui-react";
import Noteform from "./NoteForm";
import { formatTimestamp } from "../../helper/sp-utils";
import { useFormatTimestamp } from "../../hooks/use-format-timestamp";
import "./style.scss";
import { useTranslation } from "react-i18next";

const timeFormat = "DDxMMxYY";

const filterTypes = ["timestamp", "creator", "title"];

const NotesCard = ({
  postNote,
  putNote,
  notesError,
  notesByContextHashKey,
  notesIsFetching,
  isSavingNote,
}) => {
  const { t } = useTranslation();
  const { timeSpans } = useFormatTimestamp();
  const [filterType, setFilterType] = useState(filterTypes[1]);
  const [filterValue, setFilterValue] = useState();
  const [sortOrder, setSortOrder] = useState("ascending");
  const { userName } = useSelector((state) => state.session);

  const filteredNotes = useMemo(() => {
    if (!notesByContextHashKey) return [];
    let filtered = [...notesByContextHashKey];
    if (sortOrder === "ascending") {
      filtered.sort((a, b) => {
        return b.timestamp - a.timestamp;
      });
    } else {
      filtered.sort((a, b) => {
        return a.timestamp - b.timestamp;
      });
    }
    if (filterType === "timestamp" && filterValue)
      filtered = filtered.filter(
        (note) =>
          formatTimestamp(note.timestamp, timeFormat) ===
          formatTimestamp(new Date(filterValue).getTime(), timeFormat)
      );
    if (filterValue && (filterType === "creator" || filterType === "title"))
      filtered = filtered.filter((note) => {
        return (
          note[filterType] &&
          note[filterType].toUpperCase().includes(filterValue.toUpperCase())
        );
      });
    return filtered;
  }, [filterValue, filterType, notesByContextHashKey, sortOrder]);

  const [selectedNote, setSelectedNote] = useState();

  return (
    <Grid>
      {notesIsFetching && (
        <GridColumn width={16}>
          <Loader active inline size="large" />
        </GridColumn>
      )}

      {!notesIsFetching && (
        <>
          <GridColumn width="16">
            <Header style={{ float: "left" }}>
              {" "}
              {filteredNotes.length} Notes
            </Header>
            <Button
              floated="right"
              basic
              size="mini"
              icon="plus"
              onClick={() => setSelectedNote(true)}
              circular
            />
          </GridColumn>

          <GridColumn width={16} style={{ padding: "20px" }}>
            <Comment.Group
              style={{ overflow: "auto", maxHeight: 1100, maxWidth: "100%" }}
            >
              {notesError && <Message>{notesError["displayMessage"]}</Message>}

              {filteredNotes.length > 0 && !notesIsFetching ? (
                filteredNotes.map((note) => (
                  <Segment style={{ borderRadius: "10px" }} key={note.hash_key}>
                    <Comment>
                      <Comment.Avatar
                        src={`https://eu.ui-avatars.com/api/?name=${
                          note.creator.split(".").length > 0
                            ? `${note.creator.split(".")[0]} ${
                                note.creator.split(".")[1]
                              }`
                            : note.creator
                        }&background=ff5a00&color=fff&bold=true`}
                      />
                      <Comment.Content>
                        <Comment.Author as="a">{note.creator}</Comment.Author>
                        <Comment.Metadata>
                          <div>{timeSpans(note.timestamp)}</div>
                        </Comment.Metadata>
                        <br />
                        <br />
                        <Comment.Text>
                          <strong>{note.title}</strong>
                        </Comment.Text>
                        <br />
                        <Comment.Text>{note.note_text}</Comment.Text>

                        {note.creator === userName && (
                          <Comment.Actions>
                            <Comment.Action
                              onClick={() => setSelectedNote(note)}
                            >
                              Edit
                            </Comment.Action>
                          </Comment.Actions>
                        )}
                      </Comment.Content>
                    </Comment>
                  </Segment>
                ))
              ) : (
                <GridColumn width="16" style={{ textAlign: "center" }}>
                  <span
                    style={{
                      color: "#555",
                      fontColor: "#555",
                    }}
                  >
                    <Icon name="search" />
                    {t("notes.no_notes_available")}
                  </span>
                </GridColumn>
              )}
            </Comment.Group>

            <Modal
              style={{ width: "600px" }}
              onClose={() => setSelectedNote(false)}
              open={selectedNote}
              closeIcon
            >
              <Noteform
                onClose={() => setSelectedNote(false)}
                isSavingNote={isSavingNote}
                notesByContextHashKey={notesByContextHashKey}
                postNote={postNote}
                putNote={putNote}
                note={selectedNote}
              />
            </Modal>
          </GridColumn>
        </>
      )}
    </Grid>
  );
};

export default NotesCard;
