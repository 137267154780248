export const EMPTY_TEXT: string = "---";

export const STATION_MODULE_TYPES = {
  POWER_MODULE: "POWER_MODULE",
  CHARGE_MODULE: "CHARGE_MODULE",
  THERMAL_MODULE: "BATTERY_MODULE_WITH_POWER_MODULE",
};

export const OPEN_DOOR_REQUEST_TYPES = {
  SINGLE_LOCK_SLOT: "slotDoorOpen",
  CHARGE_MODULE_LOCK: "doorOpen",
};

export const ENYRING_MAIN_COLOR = "#0468bf";

export const SWOBBEE_RESOURCES = {
  USERS: "users",
  USER_GROUPS: "user_groups",
  AUTHENTICATIONS: "authentications",
  RFIDS: "rfids",
  RFIDS_VALIDATION: "rfids_validations",
  SHARINGPOINTS: "sharingpoints",
  LOCATIONS: "locations",
  BATTERIES: "batteries",
  BATTERY_TRACKING: "battery_tracking",
  SWAPS: "swaps",
  ERROR_HISTORY: "system-warnings",
  RELEASE_NOTES: "release_notes",
  COMMANDS: "commands",
  SERVICE_RATING : "service_rating",
  STATION_USAGE : "station_usage",
  STATION_SETTINGS: "station_settings",
  NOTES: "notes",
  FILES : "files",

};



export const RESOURCE_METHODS = {
  GET : "GET",
  POST : "POST",
  PUT : "PUT",
  DELETE : "DELETE",
};
