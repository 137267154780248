import React, { useContext } from "react";
import { Location, Link } from "@reach/router";
import { AssetsContext } from "../context/AssetsContext";

const Breadcrumbs = () => {
  const {mainColor}=useContext(AssetsContext)
  return (
    <Location>
      {({ location }) => {
        const paths = location.pathname.split("/").filter((x) => x);

        console.log("paths", location);

        const pathnames = paths.map((_, index) => {
          // Reconstruct the path up to the current part
          const path = `/${paths.slice(0, index + 1).join("/")}`;
          return { path, name: paths[index].replaceAll("%20", " ") };
        });
        return (
          <nav aria-label="breadcrumb" className="Breadcrumbs" style={{backgroundColor: mainColor, opacity: 0.8}}>
            <div style={{ display: "flex", justifyContent: "flex-start", listStyle: "none" }}>
              <li>
                <Link to="/" style={{color: "#fff"}}>home</Link>
              </li>
              {pathnames.map(({ path, name }, index) => (
                <div key={path}>
                  <span style={{ margin: "0 5px", color: "#fff" }}>
                    {" "}
                    {">"}{" "}
                  </span>

                  {pathnames.length - 1 === index && (
                    <span style={{ color: "#fff" }}>{name}</span>
                  )}

                  {pathnames.length - 1 !== index && (
                    <Link to={path} style={{color: "#fff"}}>{name}</Link>
                  )}
                </div>
              ))}
            </div>
          </nav>
        );
      }}
    </Location>
  );
};

export default Breadcrumbs;
