import React, { useState, useEffect, useContext } from "react";
import {
  Comment,
  Form,
  Button,
  Container,
  Grid,
  GridColumn,
  Image,
  FormTextArea,
} from "semantic-ui-react";
import { connect } from "react-redux";
import Timeago from "timeago-react";
import { postNote } from "../../store/actions";
import { useTranslation } from "react-i18next";
import awsconfig from "../../config/awsconfig";
import { AxiosContext } from "../../context/AxiosContext";
import { CustomButton } from "../../components/CustomComponents";

const CommentSection = ({ selectedError, dashboardAssets }) => {
  const { t } = useTranslation();

  const [notes, setNotes] = useState([]);
  const { axiosInstance } = useContext(AxiosContext);

  const fetchNotes = async () => {
    try {
      const { data } = await axiosInstance.get(
        awsconfig.REACT_APP_PUBLIC_API +
          `/notes/${selectedError.error_hash_key}?context=SharingPoints`
      );
      setNotes(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchNotes();
  }, [selectedError]);

  const postNote = async () => {
    try {
      await axiosInstance.post(`/notes`, {
        context: "SharingPoints",
        context_hash_key: selectedError.error_hash_key,
        note_text: commentText,
        title: "",
      });
      fetchNotes();
    } catch (error) {
      console.log(error);
    }
  };

  const [commentText, setCommentText] = useState(undefined);
  const handleSubmitComment = () => {
    postNote();
    setCommentText("");
  };
  const handleTextArea = (e, { value }) => {
    setCommentText(value);
  };

  return (
    <Container
      style={{ fontColor: "black", marginLeft: "40px" }}
      fluid>
      <Grid style={{ width: "100%" }}>
        <GridColumn
          width="16"
          style={{ width: "100%" }}>
          <Comment.Group
            style={{
              width: "100%",
              maxWidth: "inherit",
              marginTop: "10px",
              marginLeft: "15px",
            }}>
            {notes &&
              notes.map((note, i) => (
                <Comment
                  key={i}
                  style={{ width: "100%" }}>
                  <Comment.Avatar
                    as="a"
                    src={`https://eu.ui-avatars.com/api/?name=${note.creator}&background=f2711c&color=fff&bold=true`}
                  />

                  <Comment.Content>
                    <Comment.Author>{note.creator}</Comment.Author>
                    <Comment.Metadata>
                      <Timeago datetime={note.timestamp} />
                    </Comment.Metadata>
                    <Comment.Text>
                      <p style={{ color: "black" }}>{note.note_text}</p>
                    </Comment.Text>
                  </Comment.Content>
                </Comment>
              ))}
            <GridColumn width="16">
              <Grid style={{ marginTop: "20px" }}>
                <GridColumn width="2">
                  <Image
                    rounded
                    src={`https://eu.ui-avatars.com/api/?name=${localStorage.getItem(
                      `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.name`
                    )}ho&background=${dashboardAssets&&dashboardAssets.main_color.replace("#", "")}&color=fff&bold=true`}
                  />
                </GridColumn>
                <GridColumn width="14">
                  <Form
                    onSubmit={handleSubmitComment}
                    reply>
                    <FormTextArea
                      onChange={handleTextArea}
                      value={commentText}
                      style={{ height: "100px", width: "100%" }}
                    />
                    <CustomButton
                      style={{ marginTop: "20px", width: 150 }}
                      disabled={!commentText}
                      title={t("error_overview.submit_note")}
                      icon="edit"
                      primary
                    />
                  </Form>
                </GridColumn>
              </Grid>
            </GridColumn>
          </Comment.Group>
        </GridColumn>
      </Grid>
    </Container>
  );
};

const mapDispatch = { postNote };

export default connect(null, mapDispatch)(CommentSection);
