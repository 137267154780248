/** @format */

import React from "react";
import {
  Grid,
  Button,
  Modal,
  GridColumn,
  Image,
  ModalActions,
  ModalContent,
  FormField,
  Form,
} from "semantic-ui-react";
import { useForm } from "react-hook-form";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Noteform = ({ note = {}, postNote, putNote, isSavingNote, onClose }) => {
  const { t } = useTranslation();
  const { name } = useSelector(state => state.session);
  const { register, handleSubmit, errors } = useForm({
    defaultValues: note,
  });

  const onSubmit = (data, e) => {
    if (note && note.hash_key) {
      putNote({
        title: data.title,
        noteText: data.note_text,
        selectedNote: note.hash_key,
      });
      onClose();
    } else {
      postNote({ title: data.title, noteText: data.note_text });
      onClose();
    }
  };

  return (
    <>
      <Modal.Header>{t("notes.create_note")}</Modal.Header>
      <ModalContent>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <GridColumn width="4" textAlign="center" verticalAlign="middle">
              <Image
                style={{ margin: "auto" }}
                circular
                src={`https://eu.ui-avatars.com/api/?name=${name}&background=ff5a00&color=fff&bold=true`}
              />
            </GridColumn>
            <GridColumn width="12">
              <FormField error={errors.title}>
                <label>Title</label>
                <input
                  type="text"
                  placeholder={t("notes.title")}
                  name="title"
                  ref={register({
                    required: "Sp Serial Number name is required.",
                  })}
                />
              </FormField>
              <FormField error={errors.note_text}>
                <label>Note</label>
                <textarea
                  placeholder={t("notes.placeholder_text")}
                  name="note_text"
                  ref={register({
                    required: "Sp Serial Number name is required.",
                  })}
                />
              </FormField>
            </GridColumn>
          </Grid>
        </Form>
      </ModalContent>

      <ModalActions>
        <Button
          primary
          disabled={isSavingNote}
          type="submit"
          onClick={() => {
            handleSubmit(onSubmit)();
          }}
        >
          {t("notes.save")}
        </Button>
      </ModalActions>
    </>
  );
};

export default Noteform;
