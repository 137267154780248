import { Link } from "@reach/router";
import React, { useContext } from "react";
import { AssetsContext } from "../../context/AssetsContext";

const MainLogo = () => {
  const { dashboardAssets } = useContext(AssetsContext);
  return (
    <div className="h3 txt-logo">
      {dashboardAssets && (
        <Link to="/">
          <img
            alt=""
            width="110px"
            height="auto"
            style={{ borderRadius: 5, backgroundColor: "#fff" }}
            src={
              dashboardAssets.logo
                ? dashboardAssets.logo
                : "https://we-are-hiring.cdn.personio.de/logos/25749/default/e8a6675690ed898b3d859a1eb3f89cf2.png"
            }
          />
        </Link>
      )}
    </div>
  );
};

export { MainLogo };
