import React from "react";
import { Grid, Container } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";

import Locations from "../components/Locations";

import { updateSetting } from "../store/actions";

import "../assets/styles/locations.scss";

/**
 * LocationsScreen fetches data for location
 * @param {*} param0
 */
const LocationsScreen = ({ location, lang }) => {
  // from redux
  const dispatch = useDispatch();
  // get needed data from redux-store
  // set when the websocket connection isopen
  const appSettings = useSelector(state => state.appSettings);

  // not used yet
  const locationsError = useSelector(state => state.locations.error);
  // calculate by redux-store when batteriesTotal and batteries match
  const locationsMapDataFetching = useSelector(
    state => state.locations.fetchingMapData
  );
  const locationsTotal = useSelector(state => state.locations.locationsTotal);

  const locationsTableDataFetching = useSelector(
    state => state.locations.fetchingTableData
  );
  const locationsMapData = useSelector(state => {
    return state.locations.locationsMapData;
  });
  const locationsTableData = useSelector(
    state => state.locations.locationsTableData
  );
  const locationStates = useSelector(state => state.locations.locationStates);

  const setSettings = setting => {
    dispatch(updateSetting(setting));
  };

  return (
  
      <Grid >
          <Grid.Column width={16} textAlign="center">
            {locationsError && (
              <div className="error">{locationsError.message}</div>
            )}

            <Locations
              lang={lang}
              locationsMapData={locationsMapData}
              locationsTableData={locationsTableData}
              locationStates={locationStates}
              locationsTotal={locationsTotal}
              routeLocation={location}
              perPage={appSettings.perPage}
              setSettings={setSettings}
              loaded={!locationsMapDataFetching && !locationsTableDataFetching}
            />
          </Grid.Column>
      </Grid>
  );
};

export default LocationsScreen;
