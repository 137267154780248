import { Link, navigate, useLocation } from "@reach/router";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { parse } from "querystring";
import CsvDownload from "react-json-to-csv";
import "./UtilComponentsStyle.scss";
import {
  Breadcrumb,
  Button,
  Confirm,
  Container,
  Dropdown,
  Grid,
  GridColumn,
  Icon,
  Input,
  Label,
  Loader,
  Popup,
  Segment,
  Table,
  TableFooter,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Header,
  Image,
  Modal,
  Divider,
  List,
  ListItem,
} from "semantic-ui-react";
import { upperFirst, sortBy } from "lodash";
import PropTypes from "prop-types";
import ExportExcel from "./ExportExcel/ExportExcel";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import awsconfig from "../config/awsconfig";
import { useTranslation } from "react-i18next";
import { useFormatTimestamp } from "../hooks/use-format-timestamp";
import _ from "lodash";
import CommentSection from "../screens/ErrorScreen/CommentSection";
import { AxiosContext } from "../context/AxiosContext";
import { AssetsContext } from "../context";
import { CustomButton } from "./CustomComponents";

export const ExportDataAsCsv = ({ data, filename, fetchData, isLoading }) => {
  const [exportModalShown, setExportModalShown] = useState();
  return (
    <>
      <Button
        basic
        style={{ with: 300 }}
        content="Export as csv"
        size="small"
        icon="arrow down"
        onClick={() => {
          setExportModalShown(!exportModalShown);
          if (fetchData) fetchData();
        }}
      />
      <Confirm
        header="Confirmation"
        size="mini"
        open={exportModalShown}
        content={"Are you sure want to download export?"}
        onCancel={() => setExportModalShown(false)}
        confirmButton={() => (
          <>
            {!isLoading && data && data.length > 0 && (
              <CsvDownload
                style={{ opacity: data && data.length > 0 ? 1 : 0.5 }}
                id="csv-export-button"
                {...{
                  data,
                  filename: `${filename}-${new Date().toISOString()}.csv`,
                }}
                onClickCapture={() => setExportModalShown(false)}
              >
                {data ? (
                  <>
                    <Icon name="cloud download" />{" "}
                    {data && data.length > 0 ? "Ok" : "Failed"}
                  </>
                ) : (
                  <Loader inline active size="mini" />
                )}
              </CsvDownload>
            )}

            {isLoading && (
              <text style={{ marginLeft: 14 }}>Request loading...</text>
            )}

            {!isLoading && data && data.length === 0 && <text>Failed</text>}
          </>
        )}
      />
    </>
  );
};

export const RefreshButton = ({ fetchData }) => {
  return (
    <Button
      onClick={() => fetchData()}
      content="Refresh"
      basic
      size="small"
      icon="sync"
    />
  );
};

export const UsersDropdownSearch = ({
  setSelectedUsername,
  selectedUsername,
  placeholder,
}) => {
  const [state, setstate] = useState();

  const { axiosInstance } = useContext(AxiosContext);
  const fetchUsers = async () => {
    try {
      let { data } = await axiosInstance.get(`/users/users_dropdown`);
      setstate(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!axiosInstance) return;
    fetchUsers();
    return () => {};
  }, [axiosInstance]);
  return (
    <Dropdown
      selectOnBlur={false}
      placeholder={placeholder ? placeholder : `User`}
      search
      selection
      fluid
      options={state}
      defaultValue={selectedUsername}
      value={selectedUsername}
      clearable
      onChange={(_, { value }) => setSelectedUsername(value ? value : null)}
    />
  );
};

export const GroupsDropdownSearch = ({
  selectedUserGroupName,
  setSelectedUserGroupName,
}) => {
  const [state, setstate] = useState();
  const { axiosInstance } = useContext(AxiosContext);

  useEffect(() => {
    const fetchGroups = async () => {
      let { data } = await axiosInstance.get(
        `/user_groups/user_group_dropdown`
      );

      setstate(data.data);
    };
    if (!axiosInstance) return;
    fetchGroups();
    return () => {};
  }, [axiosInstance]);

  return (
    <Dropdown
      placeholder="User group"
      fluid
      search
      selectOnBlur={false}
      selection
      options={state}
      clearable
      defaultValue={selectedUserGroupName}
      value={selectedUserGroupName}
      onChange={(_, { value }) =>
        setSelectedUserGroupName(value ? value : null)
      }
    />
  );
};

export const BatteryTypeDropdownSearch = ({
  setSelectedBatteryType,
  battery_type,
  width,
}) => {
  const [state, setstate] = useState();
  const [isLoading, setIsLoading] = useState();
  const { axiosInstance } = useContext(AxiosContext);

  useEffect(() => {
    const fetchBatteryTypes = async () => {
      setIsLoading(true);
      let { data } = await axiosInstance.get(`/battery-types/`);

      setstate(
        data.data.sort().map((item) => {
          return {
            value: item.toLowerCase(),
            key: item,
            text: upperFirst(item),
          };
        })
      );
      setIsLoading(false);
    };
    if (!axiosInstance) return;
    fetchBatteryTypes();
    return () => {};
  }, [axiosInstance]);

  return (
    <Dropdown
      placeholder="Battery type"
      search
      selection
      fluid
      selectOnBlur={false}
      options={state}
      defaultValue={battery_type}
      value={battery_type}
      clearable
      onChange={(_, { value }) => setSelectedBatteryType(value ? value : null)}
      loading={isLoading}
    />
  );
};

export const ErrorsAckDropdownSearch = ({
  hasBeenAck,
  setSelectedHasBeenAck,
}) => {
  const [state, setstate] = useState();
  const [isLoading, setIsLoading] = useState();
  const { search } = useLocation();

  const options = [
    {
      key: "Not acknowleged",
      text: "Not acknowleged",
      value: "Not acknowleged",
    },
    {
      key: "Acknowleged",
      text: "Acknowleged",
      value: "Acknowleged",
    },
  ];

  return (
    <Dropdown
      closeOnEscape
      selectOnBlur={false}
      placeholder="Acknowleged"
      search
      selection
      fluid
      loading={isLoading}
      options={options}
      clearable
      defaultValue={hasBeenAck}
      value={hasBeenAck}
      onChange={(_, { value }) => {
        setSelectedHasBeenAck(value ? value : null);
      }}
    />
  );
};

export const ErrorsCategoryDropdownSearch = ({
  category,
  setSelectedCategory,
}) => {
  const [state, setstate] = useState();
  const [isLoading, setIsLoading] = useState();
  const { axiosInstance } = useContext(AxiosContext);
  const fetchErrorCategoryList = async () => {
    setIsLoading(true);

    let { data } = await axiosInstance.get(
      `/system-warnings/error-history/errors-categories`
    );

    const temp = data.data.map((item) => {
      return {
        key: item.category,
        value: item.category,
        text: item.category,
      };
    });
    setstate(temp);
    setIsLoading(false);
  };
  useEffect(() => {
    if (!axiosInstance) return;
    fetchErrorCategoryList();
  }, [axiosInstance]);

  return (
    <Dropdown
      closeOnEscape
      selectOnBlur={false}
      placeholder="Category"
      search
      selection
      fluid
      loading={isLoading}
      options={state}
      clearable
      defaultValue={category}
      value={category}
      onChange={(_, { value }) => {
        setSelectedCategory(value ? value : null);
      }}
    />
  );
};

export const ErrorsContextDropdownSearch = ({
  context,
  setSelectedContext,
}) => {
  const [state, setstate] = useState();
  const [isLoading, setIsLoading] = useState();
  const { search } = useLocation();
  const { axiosInstance } = useContext(AxiosContext);
  const fetchErrorContextList = async () => {
    setIsLoading(true);

    let { data } = await axiosInstance.get(
      `/system-warnings/error-history/errors-contexts`
    );

    const temp = data.data.map((item) => {
      return {
        key: item.context,
        value: item.context,
        text: item.context,
      };
    });
    setstate(temp);
    setIsLoading(false);
  };
  useEffect(() => {
    if (!axiosInstance) return;
    fetchErrorContextList();
  }, [axiosInstance]);

  return (
    <Dropdown
      closeOnEscape
      selectOnBlur={false}
      placeholder="Context"
      search
      selection
      fluid
      loading={isLoading}
      options={state}
      clearable
      defaultValue={context}
      value={context}
      onChange={(_, { value }) => {
        setSelectedContext(value ? value : null);
      }}
    />
  );
};

export const SharingPointDropdownSearch = ({
  sp_serial_number,
  setSelectedStation,
}) => {
  const [state, setstate] = useState();
  const [isLoading, setIsLoading] = useState();
  const { axiosInstance } = useContext(AxiosContext);
  const fetchStations = async () => {
    setIsLoading(true);
    let { data } = await axiosInstance.get(`/sharingpoints/sharing_point_ids`);
    setstate(sortBy(data.data, ["text"]));
    setIsLoading(false);
  };

  useEffect(() => {
    if (!axiosInstance) return;
    fetchStations();
    return;
  }, [axiosInstance]);

  return (
    <Dropdown
      closeOnEscape
      selectOnBlur={false}
      placeholder="Station"
      search
      selection
      fluid
      loading={isLoading}
      options={state}
      clearable
      defaultValue={sp_serial_number}
      value={sp_serial_number}
      onChange={(_, { value }) => {
        setSelectedStation(value ? value : null);
      }}
    />
  );
};

export const LocationDropdownSearch = ({ location, setSelectedLocation }) => {
  const [state, setstate] = useState();
  const [isLoading, setIsLoading] = useState();
  const { search } = useLocation();

  useEffect(() => {
    if (!state) return;
    let searchParams = parse(search);
    let location_id = searchParams.location;
    setSelectedLocation(location_id);
    return () => {};
  }, [state]);

  const { axiosInstance } = useContext(AxiosContext);

  const fetchLocations = async () => {
    setIsLoading(true);
    let { data } = await axiosInstance.get(`/location_ids`);
    setstate(data.data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchLocations();
    return () => {};
  }, []);

  // useEffect(() => {
  //   let searchParams = parse(search);
  //   let location_id = searchParams.location;
  //   setSelectedLocation(location_id);
  //   return () => {};
  // }, [state]);

  return (
    <Dropdown
      placeholder="Location"
      fluid
      selectOnBlur={false}
      search
      selection
      options={state}
      clearable
      defaultValue={location}
      value={location}
      onChange={(_, { value }) => setSelectedLocation(value ? value : null)}
      loading={isLoading}
    />
  );
};

export const BatteryIdDropdown = ({
  battery_id,
  battery_type,
  text,
  isInitialBatteryId,
  labeled,
}) => {
  const tagOptions = [
    {
      key: "battery-track-history",
      text: "Track battery id ",
      value: `/battery-tracking?battery_id=${battery_id}`,
      label: { color: "blue", empty: true, circular: true },
    },
    {
      key: "battery-detail",
      text: "Battery detail",
      value: `/batteries/${battery_id}/battery-detail?battery_type_name=${battery_type}/battery-detail`,
      label: { color: "green", empty: true, circular: true },
    },
    {
      key: "copy",
      text: "Copy to clipboard",
      value: "copy",
      label: { color: "teal", empty: true, circular: true },
    },
  ];

  const onSelectHandler = async (value) => {
    if (value === "copy") {
      await navigator.clipboard.writeText(battery_id);
    } else {
      navigate(value);
    }
  };

  return (
    <Dropdown
      text={
        <>
          {labeled ? (
            <Label
              circular
              color={isInitialBatteryId ? "orange" : "grey"}
              content={battery_id}
            />
          ) : (
            <>
              <a>{battery_id} </a>{" "}
              {isInitialBatteryId && (
                <span style={{ color: "grey" }}>(initial)</span>
              )}
            </>
          )}
        </>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Menu scrolling>
          {tagOptions.map((option) => (
            <Dropdown.Item
              onClick={() => onSelectHandler(option.value)}
              key={option.value}
              {...option}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export const BatteryTrackingSettingButton = ({ battery_id, battery_type }) => {
  const tagOptions = [
    {
      key: "battery-track-history",
      text: "Track battery id ",
      value: `/battery-tracking?battery_id=${battery_id}`,
      label: { color: "blue", empty: true, circular: true },
    },
    {
      key: "battery-detail",
      text: "Battery detail",
      value: `/batteries/${battery_id}/battery-detail?battery_type_name=${battery_type}/battery-detail`,
      label: { color: "green", empty: true, circular: true },
    },
    {
      key: "copy",
      text: "Copy battery id",
      value: "copy",
      label: { color: "teal", empty: true, circular: true },
    },
  ];

  const onSelectHandler = async (value) => {
    if (value === "copy") {
      await navigator.clipboard.writeText(battery_id);
    } else {
      navigate(value);
    }
  };

  return (
    <Dropdown icon={"setting"}>
      <Dropdown.Menu direction="left">
        <Dropdown.Menu scrolling>
          {tagOptions.map((option) => (
            <Dropdown.Item
              onClick={() => onSelectHandler(option.value)}
              key={option.value}
              {...option}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export const SwapStateLabel = ({ state }) => {
  const stateColors = {
    COMPLETED: "green",
    CANCELED: "orange",
    ABORTED: "grey",
    COMPLETED_WITH_PROBLEMS: "red",
    ABORTED_WITH_PROBLEMS: "red",
    CANCELED_WITH_PROBLEMS: "red",
    OPEN: "blue",
    HAS_PROBLEMS: "yellow",
    LESS_TAKEN_THAN_ORDERED: "yellow",
    MORE_TAKEN_THAN_ORDERED: "yellow",
  };

  const descriptions = {
    COMPLETED: "Completed without any issues",
    CANCELED: "orange",
    ABORTED: "Abortedy by the system due to inactivity",
    COMPLETED_WITH_PROBLEMS: "Completed with an issue",
    ABORTED_WITH_PROBLEMS:
      "Abortedy by the system due to inactivity with some issue",
    CANCELED_WITH_PROBLEMS: "Canceled with an issue",
    OPEN: "Swap is ongoing",
    HAS_PROBLEMS: "Swap is ongoing and has an issue",
    LESS_TAKEN_THAN_ORDERED: "Less batteries taken than requested",
    MORE_TAKEN_THAN_ORDERED: "More batteries taken than requested",
  };

  return (
    <Popup
      trigger={
        <Label
          color={stateColors[`${state}`]}
          content={state.replaceAll("_", " ")}
          circular
          style={{ width: "100%" }}
        />
      }
      content={descriptions[state]}
    />
  );
};

export const SWBNavigateTo = ({ view, propId, style }) => {
  const viewRouteMappping = {
    BatteryView: "",
    BatteryDetailView: "",
    SharingpointsView: "/sharingpoints/",
    SharingpointDetailView: `/sharingpoints/${propId}`,
    SwapsView: "",
    SwapDetailView: `/swaps/${propId}`,
    UserProfileView: `/profile/${propId}`,
  };

  return (
    <Link to={viewRouteMappping[view]}>
      {propId.length > 10 ? propId.slice(0, 10) : propId}{" "}
    </Link>
  );
};

SWBNavigateTo.propTypes = {
  propId: PropTypes.string,
  view: PropTypes.oneOf([
    "BatteryView",
    "BatteryDetailView",
    "SharingpointsView",
    "SharingpointDetailView",
    "UserProfileView",
    "SwapsView",
    "SwapDetailView",
  ]).isRequired,
};

export const SWBLabel = ({ activated, content }) => {
  return (
    <Label
      style={{ width: 100 }}
      content={content ? content : activated ? "Activated" : "Deactivated"}
      circular
      color={activated ? "green" : "grey"}
    />
  );
};

export const IpcNamesDropdownSearch = ({ ipcName, setSelectedIpcName }) => {
  const [state, setstate] = useState();
  const [isLoading, setIsLoading] = useState();
  const { axiosInstance } = useContext(AxiosContext);

  const fetchLocations = async () => {
    setIsLoading(true);
    let { data } = await axiosInstance.get(`/ipc-names-dropdown`);
    setstate(data.data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchLocations();
    return () => {};
  }, []);

  return (
    <Dropdown
      placeholder="Ipc name"
      selectOnBlur={false}
      search
      selection
      options={state}
      clearable
      value={ipcName}
      onChange={(_, { value }) => setSelectedIpcName(value ? value : null)}
      loading={isLoading}
      fluid
    />
  );
};

export const ExportAsXls = ({
  exportCSV,
  fetchBatteriesToExport,
  setExportCSV,
  dataExport,
  excelName,
  setBatteriesToExport,
}) => {
  return (
    <>
      <Button
        basic
        style={{ position: "right" }}
        type="button"
        onClick={() => {
          fetchBatteriesToExport();
          setExportCSV(true);
        }}
        content="Export as xls"
        icon="arrow down"
      />

      <Modal
        size="mini"
        onClose={() => {
          setExportCSV(false);
          setBatteriesToExport([]);
        }}
        open={exportCSV}
      >
        <Modal.Header textAlign="left">Confirmation</Modal.Header>
        <Modal.Content textAlign="left">
          <p>Would you like to download a battery export as xls?</p>
        </Modal.Content>
        <Modal.Actions>
          <ExportExcel
            excelData={dataExport}
            fileName={excelName}
            setBatteriesToExport={setBatteriesToExport}
            setExportCSV={setExportCSV}
          ></ExportExcel>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export const TableScreenTemplate = ({
  table,
  header_name,
  data,
  sum,
  actions,
  description,
  filter_components,
  hasBreadCrumb,
  hasSegment,
  hasFilterButton,
  hasFilter,
  resetFilterButtonHandler,
}) => {
  return (
    <Container style={{ width: "100%", padding: 14 }}>
      <Grid columns={1}>
        {hasBreadCrumb && (
          <GridColumn
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Breadcrumb>
              <Breadcrumb.Section link onClick={() => navigate("/")}>
                Home
              </Breadcrumb.Section>
              <Breadcrumb.Divider />
              <Breadcrumb.Section style={{ color: "#444" }}>
                {header_name}
              </Breadcrumb.Section>
            </Breadcrumb>
          </GridColumn>
        )}

        <GridColumn
          style={{ minHeight: 900 }}
          as={!hasSegment ? Segment : null}
        >
          <Grid columns={1}>
            <div style={{ display: "flex", paddingTop: 14, width: "100%" }}>
              {actions}{" "}
              {hasFilter && (
                <Button
                  icon="close"
                  content="Reset filter"
                  onClick={() => resetFilterButtonHandler()}
                  basic
                  size="small"
                />
              )}
            </div>
            <GridColumn
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <span style={{ color: "#444", fontSize: 18 }}>
                {data ? `${data.length} / ${sum}` : ""} {header_name}
              </span>
            </GridColumn>

            <GridColumn style={{ paddingTop: 0 }} width="12">
              <span style={{ color: "#687078" }}>{description}</span>
            </GridColumn>
            <GridColumn width="16">
              {hasFilterButton ? (
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%" }}> {filter_components} </div>
                </div>
              ) : (
                <>{filter_components}</>
              )}
            </GridColumn>
            <GridColumn>{table}</GridColumn>
          </Grid>
        </GridColumn>
      </Grid>
    </Container>
  );
};

export const SWBPublicLocationDropdownSearch = ({
  location,
  setSelectedLocation,
  className,
}) => {
  const [state, setstate] = useState();
  const [isLoading, setIsLoading] = useState();

  const fetchLocations = async () => {
    setIsLoading(true);
    let { data } = await axios.get(
      `${awsconfig.REACT_APP_PUBLIC_API}/public-locations-dropdown`
    );
    setstate(data.data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchLocations();
    return () => {};
  }, []);

  return (
    <Dropdown
      className="SWBPublicLocationDropdownSearch"
      placeholder="Location"
      selectOnBlur={false}
      search
      selection
      options={state}
      clearable
      defaultValue={location}
      value={location}
      onChange={(_, { value }) => setSelectedLocation(value ? value : null)}
      loading={isLoading}
    />
  );
};

export const SpecialAttributesRender = () => {
  const { timeSpans } = useFormatTimestamp();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const [superItem, setSuperItem] = useState();
  const { axiosInstance } = useContext(AxiosContext);
  const { dashboardAssets } = useContext(AssetsContext);
  console.log("dashboardAssets", dashboardAssets);

  const submit = async (systemErrorId) => {
    try {
      await axiosInstance.post(`/system_errors/${systemErrorId}`);
      setOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  const CategoryAttribut = ({ key, item }) =>
    item[key] && (
      <Label color={item[key] === "critical" ? "red" : "yellow"}>
        <p>{item[key]}</p>
      </Label>
    );
  const ACKAttribut = ({ key, item }) => (
    <Label color={item[key] === "Acknowleged" ? "green" : "red"}>
      <p>{item[key]}</p>
    </Label>
  );
  const ActionsAttribut = ({ key, item, reFetch }) => (
    <>
      <CustomButton
        onClick={() => {
          setSuperItem(item[key]);
          setOpen(true);
        }}
        circular
        icon="info"
        style={{ borderRadius: "50%", width: 40, height: 40 }}
      />
      <CustomButton
        circular
        color={item.has_been_ack ? "red" : "green"}
        style={{ borderRadius: "50%", width: 40, height: 40 }}
        onClick={() => {
          submit(item.error_hash_key);
          setTimeout(() => {
            reFetch();
          }, "1000");
        }}
        icon={item.has_been_ack ? "x" : "check"}
        negative
      />
      <Modal
        onClose={() => setOpen(false)}
        closeIcon
        content={
          <>
            <Modal.Header>Error Info</Modal.Header>

            <Modal.Content>
              {dashboardAssets ? (
                <Container style={{ width: "900px", margin: "auto" }} fluid>
                  <Grid verticalAlign="middle" relaxed>
                    <GridColumn
                      textAlign="left"
                      width="13"
                      style={{
                        padding: "0px 14px 0px 14px",
                        margin: "14px 14px 0px 14px",
                      }}
                    >
                      {!item.has_been_ack && (
                        <Label basic color="red" content="Not acknowleged" />
                      )}
                      {item.has_been_ack && (
                        <span>
                          {item.has_been_ack ? "Acknowledged" : "open"} by{" "}
                          <Link to={`/users/${item.ack_by_username}`}>
                            <Label size="tiny" as="a" image>
                              <Image
                                src={`https://eu.ui-avatars.com/api/?name=${
                                  item.ack_by_username
                                }&background=${
                                  dashboardAssets &&
                                  dashboardAssets.main_color.replace("#", "")
                                }c&color=fff&bold=true`}
                              />
                              {item.ack_by_username}
                            </Label>
                          </Link>{" "}
                        </span>
                      )}
                    </GridColumn>

                    <GridColumn
                      width="16"
                      style={{
                        padding: "0px 14px 0px 14px",
                        margin: "0px 14px 0px 14px",
                      }}
                    >
                      <Divider />
                    </GridColumn>
                    <GridColumn
                      textAlign="left"
                      width="11"
                      style={{
                        padding: "0px 14px 0px 14px",
                        margin: "0px 14px 0px 14px",
                      }}
                    >
                      {item && (
                        <Header as="h3" style={{ paddingTop: "0px" }}>
                          {item.short_description}
                        </Header>
                      )}
                    </GridColumn>
                    <GridColumn
                      width="3"
                      textAlign="center"
                      style={{
                        padding: "0px 14px 0px 14px",
                        margin: "0px 14px 0px 14px",
                      }}
                    >
                      {item && (
                        <p>
                          <Button
                            style={{ width: "120px" }}
                            size="small"
                            color={item.has_been_ack ? "red" : "green"}
                            onClick={() => {
                              submit(item.error_hash_key);
                              setTimeout(() => {
                                reFetch();
                              }, "1000");
                            }}
                          >
                            {item && item.has_been_ack ? "open" : "acknowledge"}
                          </Button>
                        </p>
                      )}
                    </GridColumn>
                    <GridColumn
                      style={{
                        padding: "0px 14px 0px 14px",
                        margin: "0px 14px 0px 14px",
                      }}
                      width="16"
                    >
                      <Divider />
                    </GridColumn>

                    <GridColumn width="2">
                      <p>Count</p>
                    </GridColumn>
                    <GridColumn width="14">{item.error_count}</GridColumn>

                    <GridColumn width="2">
                      <p>{t("error_overview.sourceId")}</p>
                    </GridColumn>
                    <GridColumn width="14">
                      {item && (
                        <Link to={`/sharingpoints/${item.error_source_id}`}>
                          <p>{item.error_source_id}</p>
                        </Link>
                      )}
                    </GridColumn>

                    <GridColumn width="2">
                      <p>{"Category"}</p>
                    </GridColumn>
                    <GridColumn width="14">
                      {item && (
                        <Label
                          color={
                            item.category === "critical" ? "black" : "yellow"
                          }
                        >
                          <p>{item.category}</p>
                        </Label>
                      )}
                    </GridColumn>

                    <GridColumn width="2">
                      <p>{t("error_overview.context")}</p>
                    </GridColumn>
                    <GridColumn width="14">
                      {item && <p>{item.context}</p>}
                    </GridColumn>
                    <GridColumn width="2">
                      <p>{t("error_overview.region")}</p>
                    </GridColumn>
                    <GridColumn width="14">
                      {item && <p>{item.region}</p>}
                    </GridColumn>

                    <GridColumn width="2">
                      <p>Created At</p>
                    </GridColumn>
                    <GridColumn width="14">
                      {item && <p>{timeSpans(item.created_at)}</p>}
                    </GridColumn>

                    <GridColumn
                      width="2"
                      verticalAlign="top"
                      textAlign="center"
                    >
                      <p>Description</p>
                    </GridColumn>
                    <GridColumn width="11">
                      <List>
                        {item && (
                          <p>
                            {item.long_description.split("##").map((item) => (
                              <ListItem>{item}</ListItem>
                            ))}
                          </p>
                        )}
                      </List>
                    </GridColumn>
                    <GridColumn
                      style={{
                        padding: "0px 14px 0px 14px",
                        margin: "0px 14px 0px 14px",
                      }}
                      width="16"
                    >
                      <Divider />
                    </GridColumn>

                    <GridColumn width="16">
                      {item && (
                        <CommentSection
                          {...{ dashboardAssets }}
                          selectedError={item}
                        />
                      )}
                    </GridColumn>
                  </Grid>
                </Container>
              ) : null}
            </Modal.Content>
          </>
        }
        open={item[key] == superItem && open}
        //trigger={<Button>Show Modal</Button>}
      />
    </>
  );
  return [CategoryAttribut, ACKAttribut, ActionsAttribut];
};

export const specialAttributes = [
  "category",
  "has_been_ack_readable",
  "error_hash_key",
];

export const SWBSqlTable = ({
  headers,
  data,
  isLoading,
  sum,
  reFetch,
  fetchData,
  sortParams,
  sortColumn,
  searchParams,
}) => {
  const classNameMapping = (index, headersLength) => {
    if (!index) return "left-header";
    if (headersLength - 1 === index) return "right-header";
    return "inner-header";
  };

  const [CategoryAttribut, ACKAttribut, ActionsAttribut] =
    SpecialAttributesRender();

  const ConditionsForSpecialAttributes = ({ key, item }) => {
    if (key === "has_been_ack_readable") {
      return ACKAttribut({ key, item });
    } else if (key === "category") {
      return CategoryAttribut({ key, item });
    } else if (key === "error_hash_key") {
      return ActionsAttribut({ key, item, reFetch });
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Table fixed sortable style={{ width: "100%" }}>
        <TableHeader>
          <TableRow>
            {headers &&
              headers.map(({ header, key }, index) =>
                header === "Action" ? (
                  <TableHeaderCell
                    key={key}
                    className={classNameMapping(index, headers.length)}
                    sorted={
                      sortParams && sortParams.sorted_by === key
                        ? sortParams.order === "ASC"
                          ? "descending"
                          : "ascending"
                        : null
                    }
                  >
                    {header}
                  </TableHeaderCell>
                ) : (
                  <TableHeaderCell
                    key={key}
                    className={classNameMapping(index, headers.length)}
                    sorted={
                      sortParams && sortParams.sorted_by === key
                        ? sortParams.order === "ASC"
                          ? "descending"
                          : "ascending"
                        : null
                    }
                    onClick={() => sortColumn(key)}
                  >
                    {header}
                  </TableHeaderCell>
                )
              )}
          </TableRow>
        </TableHeader>
        <Table.Body>
          {data &&
            data.map((item, index) => (
              <TableRow key={index}>
                {headers.map(
                  ({ key, isDate, dateFormat, isLink, navigateTo }) =>
                    specialAttributes.includes(key) ? (
                      <Table.Cell key={key}>
                        {ConditionsForSpecialAttributes({ key, item })}
                      </Table.Cell>
                    ) : (
                      <Table.Cell key={key}>
                        {isDate ? (
                          moment(item[key]).format(
                            dateFormat ? dateFormat : "DD-MM-YYYY HH:mm:ss"
                          )
                        ) : isLink ? (
                          <Link to={`${navigateTo}${item[key]}`}>
                            {item[key]}
                          </Link>
                        ) : (
                          item[key]
                        )}
                      </Table.Cell>
                    )
                )}
              </TableRow>
            ))}
        </Table.Body>
        {isLoading && (
          <TableFooter fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan={headers.length} textAlign="center">
                <Loader inline active size="large" />
              </Table.HeaderCell>
            </Table.Row>
          </TableFooter>
        )}

        {!isLoading && data && !data.length && (
          <TableFooter fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan={headers.length} textAlign="center">
                <span> no data available </span>
              </Table.HeaderCell>
            </Table.Row>
          </TableFooter>
        )}

        {data && data.length > 0 && (
          <TableFooter fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan={headers.length} textAlign="center">
                <Button
                  content={`Load More (${data.length}/${sum})`}
                  basic
                  disabled={sum === data.length}
                  circular
                  loading={isLoading}
                  onClick={() => fetchData(true, searchParams)}
                />
              </Table.HeaderCell>
            </Table.Row>
          </TableFooter>
        )}
      </Table>
    </div>
  );
};

export const SWBDatePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start && new Date(start).getTime());
    setEndDate(end && new Date(end).getTime());
  };

  return (
    <DatePicker
      placeholderText="start-end"
      style={{ width: "100%" }}
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      dateFormat="dd-MM-yyyy"
      isClearable
    />
  );
};

export const InputSearchCity = ({
  location,
  setSelectedLocation,
  style,
  city_search,
  setGeoCode,
}) => {
  const [search, setSearch] = useState();
  const [options, setOptions] = useState();
  const [loading, setIsLoading] = useState();

  const searchLocation = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${awsconfig.REACT_APP_PUBLIC_API}/${
          city_search ? "cities" : "aws-locations"
        }`,
        {
          params: { search },
        }
      );

      if (!city_search) {
        setOptions(
          data.data.map((item, index) => {
            return {
              text: item.Place.Label,
              key: index,
              value: item.Place.Label,
              item,
            };
          })
        );
      } else {
        setOptions(
          data.data.map((item, index) => {
            return {
              text: `${item.city} (${item.country})`,
              key: index,
              value: `${item.city} (${item.country})`,
              item,
            };
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!search) return;
    const delayDebounceFn = setTimeout(() => {
      console.log(search);
      searchLocation();
    }, 600);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    if (!search) return;
  }, [search]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }} className={style}>
      <Input
        placeholder={"Search..."}
        value={search ? search : location?.value}
        onChange={(event) => {
          console.log(event);
          setSearch(event.target.value);
          setSelectedLocation(null);
        }}
        icon="search"
        loading={loading}
      />
      {options && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: 300,
            border: "1px solid rgba(34,36,38,.15)",
            overflowY: "auto",
            borderRadius: 15,
            backgroundColor: "#fff",
          }}
        >
          {options.map((item, index) => (
            <div
              className="dropdown-item"
              onClick={() => {
                setGeoCode([item.item.latitude, item.item.longitude]);
                setSelectedLocation(item);
                setSearch(null);
                setOptions(null);
              }}
              key={index}
            >
              {item.text}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const BatteryImage = ({ type, height, width, float, margin }) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <>
      {type && (
        <img
          alt=""
          style={{
            height,
            width,
            display: loaded ? "block" : "none",
            margin: margin ? margin : "0 0 0 0",
            padding: 0,
            float,
          }}
          onLoad={() => {
            setLoaded(true);
          }}
          src={`https://swobbee-frontend-assets.s3.eu-central-1.amazonaws.com/${type
            .replaceAll("_", " ")
            .toLowerCase()}.png`}
        />
      )}
      {!loaded && (
        <div
          style={{
            margin: "auto",
            height,
            width,
          }}
        >
          <Loader active inline />
        </div>
      )}
    </>
  );
};
