/** @format */

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Popup,
  Grid,
  GridColumn,
  Image,
  Segment,
  Label,
  Button,
  Divider,
  Table,
  Loader,
  Icon,
} from "semantic-ui-react";
import ChangePassword from "./ChangePassWord";
import jwtDecode from "jwt-decode";
import "./style.scss";
import { Link } from "@reach/router";
import awsConfig from "../../config/awsconfig";
import { SWBLabel, SWBNavigateTo } from "../../components/UtilComponents";
import { useFormatTimestamp } from "../../hooks/use-format-timestamp";
import {
  EditSwapperGroupRestriction,
  EditSwapperUserRestriction,
} from "../../components/UserManagementComponent/EditSwapperAccess";
import { AxiosContext } from "../../context/AxiosContext";
import { AssetsContext, UserManagementContext } from "../../context";

export default ({ userId }) => {
  const [latestSwaps, setLatestSwaps] = useState();

  const [selectedGroup, setSelectedGroup] = useState();
  const [limitedGroupSwapAccess, setLimitedGroupSwapAccess] = useState();
  const [
    isOpenEditSwapperGroupAccessModal,
    setIsOpenEditSwapperGroupAccessModal,
  ] = useState();

  const [selectedUser, setSelectedUser] = useState();
  const [limitedUserSwapAccess, setLimitedUserSwapAccess] = useState();
  const [
    isOpenEditSwapperUserAccessModal,
    setIsOpenEditSwapperUserAccessModal,
  ] = useState();

  const { axiosInstance } = useContext(AxiosContext);

  const { fetchProfile, user, isFetchingUserProfile } = useContext(
    UserManagementContext
  );

  const decoded_jwt = useMemo(() => {
    const decoded_jwt = jwtDecode(
      localStorage.getItem(
        `swobbee.portal.${awsConfig.REACT_APP_REST_CLIENT_ID}.idToken`
      )
    );

    return decoded_jwt;
  }, []);

  const fetchSwapsByUsername = useCallback(
    async (username) => {
      const { data } = await axiosInstance.get(
        `/swaps/swap_actions?created_by=${username}&limit=10`
      );
      setLatestSwaps(data.data);
    },
    [setLatestSwaps, axiosInstance]
  );

  useEffect(() => {
    console.log("userId", userId)

    fetchProfile(userId || decoded_jwt["cognito:username"]);
    fetchSwapsByUsername(userId || decoded_jwt["cognito:username"]);
  }, [fetchProfile, userId, decoded_jwt, fetchSwapsByUsername]);

  const { timeSpans } = useFormatTimestamp();

  const swap_restricted_reason = useMemo(() => {
    // Initialize reason as an empty string
    let reason = "";

    // Check if is_group_restricted is not null and append to reason
    const is_group_restricted =
      user &&
      user.swapper_group_restriction_info &&
      user.swapper_group_restriction_info.is_limited
        ? user.swapper_group_restriction_info.reason
        : null;

    if (is_group_restricted) {
      reason += `Group Restriction: ${is_group_restricted}`;
    }

    // Check if is_user_restricted is not null and append to reason
    const is_user_restricted =
      user &&
      user.swapper_user_restriction_info &&
      user.swapper_user_restriction_info.is_limited
        ? user.swapper_user_restriction_info.reason
        : null;

    if (is_user_restricted) {
      if (reason) {
        reason += " | "; // Add separator if reason is not empty
      }
      reason += `User Restriction: ${is_user_restricted}`;
    }

    return reason;
  }, [user]);

  const { dashboardAssets } = useContext(AssetsContext);

  return (
    <Grid className="profile-grid" style={{ margin: "auto" }}>
      {isFetchingUserProfile ? <Loader active inline size="large" /> : null}
      {user && (
        <GridColumn width="16">
          <Segment style={{ borderRadius: 15 }}>
            <Grid columns={2}>
              <Divider vertical />
              <GridColumn>
                <Grid columns={1}>
                  <GridColumn textAlign="right">
                    {swap_restricted_reason && (
                      <Popup
                        hoverable
                        mouseEnterDelay={500}
                        style={{ fontSize: "16px" }}
                        trigger={
                          <Label
                            as="a"
                            size="large"
                            circular
                            style={{ width: 150 }}
                            color={"red"}
                            content={"NO SWAP ACCESS"}
                          />
                        }
                        content={swap_restricted_reason}
                      />
                    )}
                    {user.Enabled != true && (
                      <Popup
                        hoverable
                        mouseEnterDelay={500}
                        style={{ fontSize: "16px" }}
                        trigger={
                          <Label
                            as="a"
                            size="large"
                            circular
                            style={{ width: 100 }}
                            color={user.Enabled ? "green" : "red"}
                            content={user.Enabled ? "ACTIVE" : "BLOCKED"}
                          />
                        }
                        content={user.is_blocked.reason}
                      />
                    )}
                    {user.Enabled && (
                      <Label
                        as="a"
                        size="large"
                        circular
                        style={{ width: 100 }}
                        color={user.Enabled ? "green" : "red"}
                        content={user.Enabled ? "ACTIVE" : "BLOCKED"}
                      />
                    )}
                  </GridColumn>
                  <GridColumn style={{ paddingTop: "0px" }} textAlign="center">
                    {dashboardAssets && (
                      <Image
                        style={{
                          width: 120,
                          height: 120,
                          borderRadius: 60,
                          margin: "auto",
                        }}
                        src={`https://eu.ui-avatars.com/api/?name=${
                          user.name
                        }&background=${dashboardAssets.main_color.replace(
                          "#",
                          ""
                        )}&color=fff&bold=true`}
                      />
                    )}
                  </GridColumn>
                  <GridColumn textAlign="center">
                    <span className="profile-name-span">{user.name}</span>
                  </GridColumn>
                  <GridColumn textAlign="center">
                    <span className="profile-username-span">{user.email}</span>
                  </GridColumn>
                  <GridColumn textAlign="center">
                    <Segment.Group
                      horizontal
                      style={{ borderRadius: 15, width: "90%", margin: "auto" }}
                    >
                      <Segment>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "center",
                          }}
                        >
                          <span style={{ color: "#9A9A9A", fontSize: 16 }}>
                            SWAPS
                          </span>
                          <span
                            style={{
                              color: "#444",
                              fontSize: 30,
                              marginTop: 10,
                            }}
                          >
                            {user.count_swap_requests
                              ? user.count_swap_requests
                              : "-"}
                          </span>
                        </div>
                      </Segment>
                      <Segment>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "center",
                          }}
                        >
                          <span style={{ color: "#9A9A9A", fontSize: 16 }}>
                            OUT
                          </span>
                          <span
                            style={{
                              color: "#444",
                              fontSize: 30,
                              marginTop: 10,
                            }}
                          >
                            {user.count_out ? user.count_out : "-"}
                          </span>
                        </div>
                      </Segment>
                      <Segment>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "center",
                          }}
                        >
                          <span style={{ color: "#9A9A9A", fontSize: 16 }}>
                            IN
                          </span>
                          <span
                            style={{
                              color: "#444",
                              fontSize: 30,
                              marginTop: 10,
                            }}
                          >
                            {user.count_in ? user.count_in : "-"}
                          </span>
                        </div>
                      </Segment>
                    </Segment.Group>
                  </GridColumn>

                  <GridColumn>
                    <div className="swb-profile-section">
                      <header>Details</header>
                      <Divider style={{ with: "100%" }}></Divider>
                      <label> Name </label>
                      <span>{user.name}</span>
                      <label>Username </label>
                      <span>
                        <Grid>
                          <Grid.Row columns="2">
                            <Grid.Column width={14}>
                              <span>{user.user}</span>
                            </Grid.Column>
                            <Grid.Column width={2}>
                              <Button
                                title={
                                  user.swapper_user_restriction_info &&
                                  user.swapper_user_restriction_info.is_limited
                                    ? "reactivate user's swap permission"
                                    : "deactivate user's swap permission"
                                }
                                basic
                                circular
                                size="mini"
                                icon={
                                  user.swapper_user_restriction_info &&
                                  user.swapper_user_restriction_info.is_limited
                                    ? "checkmark"
                                    : "ban"
                                }
                                color={
                                  user.swapper_user_restriction_info &&
                                  user.swapper_user_restriction_info.is_limited
                                    ? "green"
                                    : "red"
                                }
                                onClick={() => {
                                  setSelectedUser(user.user);
                                  setLimitedUserSwapAccess(
                                    user.swapper_user_restriction_info &&
                                      user.swapper_user_restriction_info
                                        .is_limited
                                      ? true
                                      : false
                                  );
                                  setIsOpenEditSwapperUserAccessModal(true);
                                }}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </span>

                      <label>User group </label>
                      <span>
                        <Grid>
                          <Grid.Row columns="2">
                            <Grid.Column width={14}>
                              <span>{user.user_group}</span>
                            </Grid.Column>
                            <Grid.Column width={2}>
                              <Button
                                title={
                                  user.swapper_group_restriction_info &&
                                  user.swapper_group_restriction_info.is_limited
                                    ? "reactivate user group's swap permission"
                                    : "deactivate user group's swap permission"
                                }
                                basic
                                circular
                                size="mini"
                                icon={
                                  user.swapper_group_restriction_info &&
                                  user.swapper_group_restriction_info.is_limited
                                    ? "checkmark"
                                    : "ban"
                                }
                                color={
                                  user.swapper_group_restriction_info &&
                                  user.swapper_group_restriction_info.is_limited
                                    ? "green"
                                    : "red"
                                }
                                onClick={() => {
                                  setSelectedGroup(user.user_group_name);
                                  setLimitedGroupSwapAccess(
                                    user.swapper_group_restriction_info &&
                                      user.swapper_group_restriction_info
                                        .is_limited
                                      ? true
                                      : false
                                  );
                                  setIsOpenEditSwapperGroupAccessModal(true);
                                }}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </span>
                      {user.odoo_contact_profile_url && (
                        <>
                          <label>Odoo CRM Profile </label>
                          <a
                            style={{ width: 350, borderRadius: 5 }}
                            href={user.odoo_contact_profile_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="ui link"
                          >
                            <Icon
                              name="external"
                              style={{
                                color: "black",
                              }}
                            />
                          </a>
                        </>
                      )}
                    </div>
                  </GridColumn>

                  <GridColumn
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div className="swb-profile-section">
                      <header>Accounts details</header>
                      <Divider style={{ with: "100%" }}></Divider>

                      <label> User id </label>
                      <span>{user.sub}</span>

                      <label> Created at </label>
                      <span>{user.UserCreateDate}</span>

                      <label> Last profile update</label>
                      <span>{user.UserLastModifiedDate}</span>

                      <label> Allowed to swap</label>
                      <span>
                        {user.allowed_to_swap_batteries
                          ? user.allowed_to_swap_batteries.join(" , ")
                          : "-"}
                      </span>

                      <label> Service password</label>
                      <span>{user.user_service_password}</span>
                      <label> Email verified </label>
                      <span>
                        <SWBLabel
                          activated={user.email_verified}
                          content={
                            user.email_verified ? "SUCCESSFUL" : "PENDING"
                          }
                        />
                      </span>

                      <label> Acount status </label>
                      <span>
                        <SWBLabel
                          activated={user.UserStatus === "CONFIRMED"}
                          content={user.UserStatus}
                        />
                      </span>
                      <label> Allowed to fill in </label>
                      <span>
                        <SWBLabel activated={user.allowed_to_fill_in} />
                      </span>

                      <label> Allowed to force swap </label>
                      <span>
                        <SWBLabel activated={user.allowed_to_force_swap} />
                      </span>
                    </div>
                  </GridColumn>
                </Grid>
              </GridColumn>
              <GridColumn>
                <Grid columns={1}>
                  {!userId && (
                    <>
                      <GridColumn>
                        <span style={{ color: "#9A9A9", fontSize: 18 }}>
                          Change password
                        </span>
                        <Divider style={{ with: "100%" }}></Divider>

                        <ChangePassword />
                      </GridColumn>
                    </>
                  )}

                  <GridColumn>
                    <span style={{ color: "#9A9A9", fontSize: 18 }}>
                      Latest swap requests
                    </span>
                    <Divider style={{ with: "100%" }}></Divider>
                  </GridColumn>
                  <GridColumn>
                    <Table basic="very" fixed>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Swap id</Table.HeaderCell>
                          <Table.HeaderCell>Sharingpoint</Table.HeaderCell>
                          <Table.HeaderCell>Requested</Table.HeaderCell>
                          <Table.HeaderCell>Battery type</Table.HeaderCell>
                          <Table.HeaderCell>Out/In</Table.HeaderCell>
                          <Table.HeaderCell>Timestamp</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>

                      <Table.Body style={{ color: "#444" }}>
                        {latestSwaps &&
                          latestSwaps.map(
                            ({
                              dynamo_swap_id,
                              requested,
                              count_out,
                              count_in,
                              created_at,
                              station,
                              battery_type,
                            }) => (
                              <Table.Row>
                                <Table.Cell>
                                  <SWBNavigateTo
                                    propId={dynamo_swap_id}
                                    view="SwapDetailView"
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  {" "}
                                  <SWBNavigateTo
                                    propId={station}
                                    view="SharingpointDetailView"
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  {requested === -1 ? (
                                    <Label
                                      content={"FILL-IN"}
                                      circular
                                      color="blue"
                                    />
                                  ) : (
                                    requested
                                  )}
                                </Table.Cell>
                                <Table.Cell>{battery_type}</Table.Cell>
                                <Table.Cell>
                                  {count_out}/{count_in}
                                </Table.Cell>
                                <Table.Cell>{timeSpans(created_at)}</Table.Cell>
                              </Table.Row>
                            )
                          )}
                      </Table.Body>
                    </Table>
                  </GridColumn>
                  {!latestSwaps && (
                    <GridColumn textAlign="center">
                      <Loader inline active />
                    </GridColumn>
                  )}
                  {latestSwaps && latestSwaps.length === 0 && (
                    <GridColumn style={{ color: "#444" }} textAlign="center">
                      no swaps available
                    </GridColumn>
                  )}

                  {latestSwaps && latestSwaps > 0 && (
                    <GridColumn style={{ color: "#444" }} textAlign="center">
                      <Link to="/statistics">More swaps</Link>
                    </GridColumn>
                  )}
                </Grid>
              </GridColumn>
              <GridColumn></GridColumn>
            </Grid>
          </Segment>
        </GridColumn>
      )}

      <EditSwapperGroupRestriction
        groupName={selectedGroup}
        limitedSwapAccess={limitedGroupSwapAccess}
        isOpen={isOpenEditSwapperGroupAccessModal}
        setIsOpen={setIsOpenEditSwapperGroupAccessModal}
        fetchUpdate={fetchProfile}
      />

      <EditSwapperUserRestriction
        username={selectedUser}
        limitedSwapAccess={limitedUserSwapAccess}
        isOpen={isOpenEditSwapperUserAccessModal}
        setIsOpen={setIsOpenEditSwapperUserAccessModal}
        fetchUpdate={fetchProfile}
      />
    </Grid>
  );
};
