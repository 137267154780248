import React from "react";
import { useFieldArray, Controller } from "react-hook-form";
import { Grid, GridColumn, Button } from "semantic-ui-react";

import { TimeInput } from "semantic-ui-calendar-react";

const NestedOpeningHoures = ({ index, control }) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `openingTimes[${index}].openingHoures`
  });

  return (
    <Grid columns="3">
      {fields.map((item, i) => {
        return (
          <>
            <GridColumn>
              <Controller
                control={control}
                name={`openingTimes[${index}].openingHoures[${i}].start`}
                defaultValue={item.start}
                render={({ onChange, value }) => (
                  <TimeInput
                    popupPosition="bottom left"
                    animation="fade"
                    name="time"
                    placeholder="Time"
                    iconPosition="left"
                    timeFormat="24"
                    defaultValue={value}
                    value={value}
                    onChange={(event, { name, value }) => {
                      onChange(value);
                    }}
                  />
                )}
              />
            </GridColumn>
            <GridColumn>
              <Controller
                control={control}
                name={`openingTimes[${index}].openingHoures[${i}].end`}
                defaultValue={item.end}
                render={({ onChange, value }) => (
                  <TimeInput
                    popupPosition="bottom left"
                    animation="fade"
                    placeholder="Time"
                    iconPosition="left"
                    defaultValue={value}
                    timeFormat="24"
                    value={value}
                    onChange={(event, { name, value }) => {
                      onChange(value);
                    }}
                  />
                )}
              />
            </GridColumn>
            <GridColumn verticalAlign="middle">
              <Button
                type="button"
                primary
                icon="plus"
                circular
                size="mini"
                onClick={() => append({ start: "00:00", end: "00:00" })}
                disabled={fields.length > 1 && i === 0}
              />
              <Button
                type="button"
                style={{ marginLeft: "10px" }}
                basic
                icon="minus"
                circular
                size="mini"
                onClick={() => remove(i)}
                disabled={fields.length === 1 && i === 0}
              />
            </GridColumn>
          </>
        );
      })}
    </Grid>
  );
};

export default NestedOpeningHoures;
