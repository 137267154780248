import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AxiosContext } from "./AxiosContext";
import decodeJwt from "jwt-decode";
import { swobbeeColor } from "../helper/colors";
import {
  RESOURCE_METHODS,
  SWOBBEE_RESOURCES,
} from "../constants/app-constants";
import awsconfig from "../config/awsconfig";

import SharingPointsScreen from "../screens/sharingPoints";
import SharingPointsIndexScreen from "../screens/sharingPoints";
import SharingPointByStationId from "../screens/sharingPoints/show";
import LocationsScreen from "../screens/locations";
import ErrorHistoryScreen from "../screens/ErrorHistoryScreen";
import LocationsCreateScreen from "../screens/locations/create";
import LocationById from "../screens/locations/show";
import SwapsStatistics from "../components/SwapStatistics";
import UserManagementScreen from "../screens/UserManagementScreen";
import Profile from "../screens/Profile";
import SwapDetailView from "../components/SwapDetailView";
import axios from "axios";
import L from "leaflet";

let apiUrl = awsconfig.REACT_APP_PUBLIC_API;

export const AssetsContext = createContext();

export const AssetsProvider = ({ children }) => {
  const [assets, setAssets] = useState(null);
  const [userProfileInfo, setUserProfileInfo] = useState(null);
  const [resources, setResources] = useState(null);
  const [dashboardAssets, setDashboardAssets] = useState(null);

  const fetchAssets = useCallback(async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/assets/dashboard`);
      setDashboardAssets(data.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const changeFavicon = (newIcon) => {
    const link = document.querySelector("link[rel*='icon']");
    if (link) {
      link.href = newIcon;
    }
  };

  // Example usage:
  changeFavicon("/../src/context/enyring-browser-icon.png");

  const { axiosInstance, accessToken } = useContext(AxiosContext);

  const mainColor = useMemo(() => {
    if (!dashboardAssets) return;
    return dashboardAssets.main_color
      ? dashboardAssets?.main_color
      : swobbeeColor;
  }, [dashboardAssets]);

  const fetchProfile = useCallback(async () => {
    try {
      const decodedToken = decodeJwt(accessToken);
      const username = decodedToken.username;
      const { data } = await axiosInstance.get(`/users/profile/${username}`);
      setUserProfileInfo(data.data);
      setAssets(data.data?.frontend_assets);
      setResources(data.data?.resources);
    } catch (error) {
      console.log(error);
    }
  }, [axiosInstance, accessToken]);

  const userAllowedToAccess = useMemo(() => {
    if (!resources) return;
    return resources.reduce((acc, curr, idx) => {
      // Use the 'a' value from the corresponding first object as the key
      const key = resources[idx].name;

      // Set the key-value pair in the accumulator object
      acc[key] = curr;

      return acc;
    }, {});
  }, [resources]);

  const isAllowedToAccess = useCallback(
    (resource, method) => {
      if (!userAllowedToAccess) return false;
      const access = userAllowedToAccess[resource];
      if (!access) return false;
      return (
        access.allowed_to.includes("*") || access.allowed_to.includes(method)
      );
    },
    [userAllowedToAccess]
  );

  const stationFormRenderConditions = useMemo(() => {
    return userAllowedToAccess?.sharingpoints?.extra_request_method_settings
      ?.form;
  }, [userAllowedToAccess]);

  useEffect(() => {
    fetchAssets();
    if (!axiosInstance) return;
    fetchProfile();
  }, [axiosInstance, fetchProfile, fetchAssets]);

  const lang = useMemo(() => {
    return JSON.parse(localStorage.getItem("LANGUAGE"));
  }, []);

  const routes = useMemo(() => {
    return [
      {
        path: "/",
        component: ErrorHistoryScreen,
        resource: SWOBBEE_RESOURCES.ERROR_HISTORY,
        method: RESOURCE_METHODS.GET,
      },
      {
        path: isAllowedToAccess(
          SWOBBEE_RESOURCES.ERROR_HISTORY,
          RESOURCE_METHODS.GET
        )
          ? "/sharingpoints"
          : "/",
        component: SharingPointsScreen,
        children: [
          { path: "/", component: SharingPointsIndexScreen },
          { path: "/:sharingPointId", component: SharingPointByStationId },
        ],
        resource: SWOBBEE_RESOURCES.SHARINGPOINTS,
        method: RESOURCE_METHODS.GET,
      },
      {
        path: "/sharingpoints",
        component: SharingPointsScreen,
        children: [
          { path: "/", component: SharingPointsIndexScreen },
          { path: "/:sharingPointId", component: SharingPointByStationId },
        ],
        resource: SWOBBEE_RESOURCES.SHARINGPOINTS,
        method: RESOURCE_METHODS.GET,
      },
      {
        path: "/locations",
        component: LocationsScreen,
        props: { lang },
        resource: SWOBBEE_RESOURCES.LOCATIONS,
        method: RESOURCE_METHODS.GET,
      },
      {
        path: "/locations/create/*",
        component: LocationsCreateScreen,
        props: { lang },
        resource: SWOBBEE_RESOURCES.LOCATIONS,
        method: RESOURCE_METHODS.POST,
      },
      {
        path: "/locations/edit/:locationId/*",
        component: LocationsCreateScreen,
        props: { lang },
        resource: SWOBBEE_RESOURCES.LOCATIONS,
        method: RESOURCE_METHODS.POST,
      },
      {
        path: "/locations/:locationId",
        component: LocationById,
        resource: SWOBBEE_RESOURCES.LOCATIONS,
        method: RESOURCE_METHODS.GET,
      },
      {
        path: "/statistics",
        component: SwapsStatistics,
        props: { lang },
        resource: SWOBBEE_RESOURCES.SWAPS,
        method: RESOURCE_METHODS.GET,
      },
      {
        path: "/statistics/:swapId",
        component: SwapDetailView,
        props: { lang },
        resource: SWOBBEE_RESOURCES.SWAPS,
        method: RESOURCE_METHODS.GET,
      },
      {
        path: "/user-management",
        component: UserManagementScreen,
        resource: SWOBBEE_RESOURCES.USERS,
        method: RESOURCE_METHODS.GET,
      },
      {
        path: "/profile",
        component: Profile,
        resource: SWOBBEE_RESOURCES.USERS,
        method: RESOURCE_METHODS.GET,
      },
      {
        path: "/profile/:userId",
        component: Profile,
        resource: SWOBBEE_RESOURCES.USERS,
        method: RESOURCE_METHODS.GET,
      },
      {
        path: "/user-management/:userId",
        component: Profile,
        resource: SWOBBEE_RESOURCES.USERS,
        method: RESOURCE_METHODS.GET,
      },
    ].filter((route) => {
      return isAllowedToAccess(route.resource, route.method);
    });
  }, [isAllowedToAccess, lang]);

  const iconImg = require(`./../assets/images/Icon_active.svg`);

  const CustomMarker = useCallback(
    (type) => {
      return dashboardAssets
        ? L.icon({
            iconUrl: dashboardAssets.map_pin
              ? dashboardAssets.map_pin
              : iconImg,
            iconSize: [35, -1], // size of the icon
            shadowSize: [50, 64], // size of the shadow
            iconAnchor: [12, 50], // point of the icon which will correspond to marker's location
            shadowAnchor: [4, 62], // the same for the shadow
            popupAnchor: type && type === "sharingPoints" ? [0, 0] : [-3, -76],
          })
        : null;
    },
    [dashboardAssets, iconImg]
  );

  const createClusterIcon = (cluster) => {
    const count = cluster.getChildCount();

    return L.divIcon({
      html: `<div style="background-color: ${dashboardAssets.main_color}; border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; color: white; font-size: 14px;">${count}</div>`,
      className: "custom-cluster-icon",
      iconSize: L.point(40, 40),
    });
  };

  return (
    <AssetsContext.Provider
      value={{
        assets,
        setAssets,
        mainColor,
        userProfileInfo,
        isAllowedToAccess,
        userAllowedToAccess,
        routes,
        lang,
        dashboardAssets,
        CustomMarker,
        createClusterIcon,
        stationFormRenderConditions,
      }}
    >
      {children}
    </AssetsContext.Provider>
  );
};

// {
//   path: "/batteries",
//   component: BatteriesScreen,
//   condition: isAllowedToRead("NewBattery"),
//   children: [
//     { path: "/", component: BatteriesIndexScreen, props: { lang } },
//     { path: "/:serial_number", component: ShowBatteryScreen, props: { lang } }
//   ]
// },
// {
//   path: "/statistics",
//   component: SwapStatisticScreen,
//   condition: isAllowedToRead("SharingPoint"),
//   props: { lang }
// },
// {
//   path: "/release-notes",
//   component: ReleaseNotes,
//   condition: isAllowedToWrite("SharingPoint"),
//   props: { lang }
// },
// {
//   path: "/user-management",
//   component: UserManagementScreen
// },
// {
//   path: "/profile",
//   component: ProfileScreen
// },
// {
//   path: "/profile/:userId",
//   component: ProfileScreen
// },
// {
//   path: "/swaps",
//   component: SwapScreen
// },
// {
//   path: "/user-groups/:userGroupName",
//   component: UserGroupDetailScreen
// },
// {
//   path: "/battery-tracking",
//   component: BatteryTracking
// },
// {
//   path: "/swobbee-locations",
//   component: SwobbeePublicLocationMap
// }
// ];
