/** @format */

import React, { useEffect } from "react";
import { Container, GridColumn, Segment } from "semantic-ui-react";

import ErrorsHistoryTable from "./ErrorsHistoryTable";

const ErrorsHistory = () => {
  return (
    <Container
      className="statistic-container"
      style={{
        marginBottom: "50px",
      }}
    >
      <GridColumn width="16">
        <Segment style={{ height: "100% !important" }}>
          <ErrorsHistoryTable />
        </Segment>
      </GridColumn>
    </Container>
  );
};

export default ErrorsHistory;
