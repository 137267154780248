import React, { useEffect, useState, useMemo } from "react";
import {
  Select,
  Button,
  FormField,
  Form,
  Radio,
  FormGroup,
  Dropdown,
  Input,
} from "semantic-ui-react";
import { CustomButton } from "../../CustomComponents";

const StationForm = ({
  errors,
  mode,
  submissions,
  setSubmissions,
  stationIds,
  userGroupOptions,
  roles,
  onSubmitStepZero,
  setIsOpen,
  publicSharingpoints,
  Sharingpoints,
  Categories,
}) => {
  function union(setA, setB) {
    const _union = new Set(setA);
    for (const elem of setB) {
      _union.add(elem);
    }
    return _union;
  }
  function difference(setA, setB) {
    const _difference = new Set(setA);
    for (const elem of setB) {
      _difference.delete(elem);
    }
    return _difference;
  }

  return (
    <Form size="big">
      <FormGroup widths="equal">
        <FormField error={!submissions.group_name && errors.group_name}>
          <label htmlFor="name">Company name</label>
          <Input
            type="text"
            name="group_name"
            placeholder={"Input company name..."}
            id="name"
            defaultValue={submissions.group_name}
            disabled={mode === "edit"}
            onChange={(_, { value }) =>
              setSubmissions({ ...submissions, group_name: value })
            }
          />
        </FormField>
        {mode !== "edit" && (
          <FormField error={!submissions.role && errors.role}>
            <label>Role</label>

            <Select
              fluid
              placeholder="Role"
              defaultValue={submissions.role}
              options={roles}
              onChange={(_, { value }) =>
                setSubmissions({ ...submissions, role: value })
              }
              search
              selection
            />
          </FormField>
        )}
      </FormGroup>

      <FormField>
        <label>Parent user group</label>

        <Select
          fluid
          clearable
          placeholder="Parent user group"
          defaultValue={submissions.parent_company}
          options={userGroupOptions}
          onChange={(_, { value }) =>
            setSubmissions({ ...submissions, parent_company: value })
          }
          search
          selection
        />
      </FormField>

      <FormField
        error={
          submissions.sharingpoints
            ? submissions.sharingpoints.length === 0
              ? errors.sharingpoints
              : false
            : false
        }
      >
        <label>Sharingpoints</label>

        <Dropdown
          allowAdditions
          fluid
          placeholder="Sharingpoints"
          options={stationIds}
          value={Sharingpoints}
          defaultValue={Sharingpoints}
          onChange={(_, { value }) =>
            setSubmissions({ ...submissions, sharingpoints: value })
          }
          search
          selection
          multiple
        />
      </FormField>
      <FormField>
        <label>Category</label>
        <Select
          fluid
          clearable
          placeholder="Category"
          defaultValue={submissions.user_group_category}
          options={Categories}
          onChange={(_, { value }) => {
            setSubmissions({
              ...submissions,
              user_group_category: value,
            });
          }}
          search
          selection
        />
      </FormField>
      <FormGroup widths="equal">
        <FormField>
          <label>Forced swap</label>

          <Radio
            slider
            checked={submissions.allowed_to_force_swap}
            onChange={(_, { checked }) =>
              setSubmissions({ ...submissions, allowed_to_force_swap: checked })
            }
          />
        </FormField>
        <FormField>
          <label>Fill in batteries</label>
          <Radio
            checked={submissions.allowed_to_fill_in}
            slider
            onChange={(_, { checked }) =>
              setSubmissions({ ...submissions, allowed_to_fill_in: checked })
            }
          />
        </FormField>
      </FormGroup>
      <FormGroup widths="equal">
        <FormField>
          <label>Use Public Stations</label>

          <Radio
            slider
            checked={submissions.use_public_stations}
            onChange={(_, { checked }) => {
              const setA = new Set(submissions.sharingpoints);
              const setB = new Set(publicSharingpoints);
              if (checked === true) {
                const newSharingpoints = union(setA, setB);
                setSubmissions({
                  ...submissions,
                  use_public_stations: checked,
                  sharingpoints: [...newSharingpoints],
                });
              } else {
                const newSharingpoints = difference(setA, setB);
                setSubmissions({
                  ...submissions,
                  use_public_stations: checked,
                  sharingpoints: [...newSharingpoints],
                });
              }
            }}
          />
        </FormField>
      </FormGroup>
      <FormField>
        <CustomButton

          primary
          type="button"
          onClick={() => {
            onSubmitStepZero();
          }}
          size="big"
          title={"Next"}
          translate
          style={{width: "100%"}}
        />
      </FormField>
      <FormField>
        <CustomButton
          type="button"
          onClick={() => {
            setIsOpen(false);
          }}
          title={"Cancel"}
          negative
          translate
          style={{width: "100%"}}
        />
      </FormField>
    </Form>
  );
};

export default StationForm;
