import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";

import { TileLayer, MapContainer, useMap } from "react-leaflet";
import L from "leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { Marker, Popup } from "react-leaflet";

import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet/dist/leaflet.css";

import "./marker-map.scss";

import { AssetsContext } from "../../../context/AssetsContext";
import { CustomMarkerPopup } from "../../CustomComponents";

delete L.Icon.Default.prototype._getIconUrl;

const minZoom = 4;
const maxZoom = 18;
const tileUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const attribution =
  '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';

const MarkerMap = ({
  latLng,
  data,
  selectHandler,
  selectedHash,
  layout,
  type,
  height,
  pathName
}) => {
  /** find sharingPoint Object for selectedHash */
  const selectedSharingPoint = useMemo(() => {
    if (!data || !selectedHash) return;
    let found = data.find((sp) => {
      return sp.id === selectedHash;
    });
    if (found) {
      return found;
    }
  }, [selectedHash, data]);

  return (
    <MapContainer
      zoom={5}
      maxZoom={16}
      zoomControl={false}
      center={[latLng.latitude, latLng.longitude]}
      style={{
        height: height ? height : "400px",
        width: "100%",
        borderRadius: "3px",
      }}
    >
      {/* <Button
        style={{
          position: "absolute",
          zIndex: height ? 99999 : 1000,
          right: 10,
          top: 10,
          backgroundColor: "white",
          color: "black",
          boxShadow: "0.5px 0.5px 0.5px 0.5px #bbb",
        }}
        circular
        size="medium"
        icon={"expand"}
        onClick={() => setFullScreenModalMap(!showFullScreenModalMap)}
      /> */}
      <TileLayer
        attribution={attribution}
        url={tileUrl}
        minZoom={minZoom}
        maxZoom={maxZoom}
      />

      <MapChild
        {...{ pathName }}
        type={type}
        data={data}
        selected={selectedSharingPoint}
        layout={layout}
        selectHandler={selectHandler}
      />
    </MapContainer>
  );
};
/** helper component to create custom leaflet control */
const ShowAllControl = ({ parentMap, flyToAll }) => {
  /** create leaflet control with jsx content */
  useEffect(() => {
    if (!parentMap) return;
    const control = L.control({ position: "topleft" });
    control.onAdd = () => {
      const div = L.DomUtil.create("div", "");
      // render jsx component in leaflet control content
      // ReactDOM.render(<FlyToButton onClick={flyToAll} />, div);
      return div;
    };
    control.addTo(parentMap);
    return () => control.remove();
  }, [parentMap, flyToAll]);

  return null;
};

/** Helper Component needed to access map (in react-leaflet) with useMap() hook*/
const MapChild = ({
  data,
  selected,
  layout,
  selectHandler,
  type,
  pathName,
}) => {
  const map = useMap();
  const clusterGroup = useRef();
  const popupRef = useRef();
  const selectTime = useRef();

  const { dashboardAssets, CustomMarker, createClusterIcon } =
    useContext(AssetsContext);

  // const openIcon = (state = "active", type) => {
  //   return L.icon({
  //     iconUrl: dashboardAssets.map_pin? dashboardAssets.map_pin: iconImg ,
  //     iconSize: [28, 49], // size of the icon
  //     shadowSize: [50, 64], // size of the shadow
  //     iconAnchor: [12, 50], // point of the icon which will correspond to marker's location
  //     shadowAnchor: [4, 62], // the same for the shadow
  //     popupAnchor: type && type === "sharingPoints" ? [0, 0] : [-3, -76],
  //   });
  // };

  //redraw map if size/layout changes
  useEffect(() => {
    map && map.invalidateSize();
  }, [layout, map]);

  const flyToAll = useCallback(() => {
    let clusterCurrent = clusterGroup.current;
    map.flyToBounds(clusterCurrent.getBounds());
  }, [clusterGroup, map]);

  useEffect(() => {
    if (clusterGroup.current && data) {
      if (selected) {
        let found;
        clusterGroup.current.eachLayer((layer) => {
          if (layer.options["spName"] === selected.id) {
            found = layer;
          }
        });
        if (found) {
          popupRef.current = found;
          clusterGroup.current.zoomToShowLayer(found);
          found.openPopup();
        }
      } else {
        if (popupRef.current) {
          popupRef.current.closePopup();
          popupRef.current = undefined;
        }
        //let clusterCurrent = clusterGroup.current;
        //map.flyToBounds(clusterCurrent.getBounds());
      }
    }
  }, [selected, data, map]);

  //deselect sp on close if it is not closed by open another sp
  const closeHandler = useCallback(
    (spId) => () => {
      if (selected && spId === selected.id) {
        if (!selectTime.current || Date.now() - selectTime.current > 1000) {
          selectHandler();
          selectTime.current = undefined;
        }
      } else {
      }
    },
    [selectHandler, selected]
  );
  // BUGGY open a popup sets selectTime timestamp which is used to decide that the related closeEvent is not fired.
  const openHandler = useCallback(
    (spId) => () => {
      if (!spId) return;
      if (!selected || spId !== selected.id) {
        selectHandler(spId);
        selectTime.current = Date.now();
      }
    },
    [selectHandler, selected]
  );

  return (
    <>
      <ShowAllControl parentMap={map} flyToAll={flyToAll} />
      <MarkerClusterGroup
        key={selected && selected.id}
        ref={clusterGroup}
        disableClusteringAtZoom={17}
        zoomToBoundsOnClick={true}
        maxClusterRadius={10}
        iconCreateFunction={createClusterIcon}
      >
        {dashboardAssets && data
          ? data.map((sp) => {
              if (!sp.geocode || isNaN(sp.geocode[0])) return null;
              return (
                <Marker
                  spName={sp.sp_serial_number}
                  icon={CustomMarker(type)}
                  position={sp.geocode}
                  key={`marker-${sp.sp_serial_number}`}
                  options={{ riseOnHover: true }}
                  // eventHandlers={{
                  //   click: (e) => {
                  //     pathName === "locations"
                  //       ? navigate("/locations/" + sp.sp_serial_number)
                  //       : console.log("click marker", sp, e);
                  //   },

                  //   popupopen: (e) => {
                  //     pathName !== "locations" && openHandler(sp.id)();
                  //   },
                  //   popupclose: (e) => {
                  //     // remove it if the closing popup is equal the selected sp.
                  //     if (selected && sp.id === selected.id) {
                  //       closeHandler(sp.id)();
                  //     }
                  //   },
                  // }}
                >
                  <Popup className={type} position={sp.latLng}>
                    <CustomMarkerPopup
                      showDetailsButton
                      sp_serial_number={sp.sp_serial_number}
                      nick_name={sp.nick_name}
                      street={sp.address.street}
                      city={sp.address.city}
                      street_number={sp.address.street_number}
                      zipcode={sp.address.zipcode}
                      box_status_check={sp.batteries}
                      w3w={sp.w3w}
                    />
                  </Popup>
                </Marker>
              );
            })
          : null}
      </MarkerClusterGroup>
    </>
  );
};

export default MarkerMap;
