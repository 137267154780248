import React, { useState, useEffect } from "react"
import { List, Checkbox, Placeholder } from "semantic-ui-react"
import { useSelector, useDispatch } from "react-redux"
import { getSwapStatisticResponse } from "../../store/actions";

export default ({ itemList, setItemList }) => {
    const { statistic, fetching } = useSelector(state => state.statistics)
    const [filter, setFilter] = useState([])
    const [filteredItems, setFilteredItems] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {
        if (statistic && !itemList.length) setItemList(statistic.slice(0));
    }, [statistic])

    const handleCheckBox = async (_, { value, checked }) => {
        let filteredList = []
        if (!checked && value) {
            let temp = Array.from(new Set([...filter, value]))
            await setFilter(temp)
            filteredList = itemList.filter(item => {
                let bool = !temp.includes(item.sp_serial_number)
                if (!bool) setFilteredItems([...filteredItems, item])
                console.log("filteredItems false", [...filteredItems, item]);
                return bool
            })

            dispatch(getSwapStatisticResponse({ content: filteredList }))
        } else {
            let sp = filteredItems.find(item => item.sp_serial_number === value)
            if (sp) {
                dispatch(getSwapStatisticResponse({ content: [...statistic, sp] }))
                console.log("filteredItems true", filteredItems);
                setFilteredItems([...filteredItems, sp].filter(item => item.sp_serial_number !== sp.sp_serial_number))
                setFilter(filter.filter(item => item === value))
            }
        }
    }

    return (
        <List divided relaxed>

            {fetching &&
                [1, 2, 4, 5, 6, 7, 8].map((item, i) => (
                    <List.Item key={i}>
                        <List.Content >
                            <List.Header style={{ padding: "10px", marginLeft: "15px" }}>
                                <Placeholder>
                                    <Placeholder.Header >
                                        <Placeholder.Line />
                                    </Placeholder.Header>
                                </Placeholder>
                            </List.Header>
                        </List.Content>
                    </List.Item>

                ))
            }

            {statistic && itemList.map((item, i) => (
                <List.Item key={i}>
                    <List.Content>
                        <List.Header as="a"> <Checkbox defaultChecked value={item.sp_serial_number} onChange={handleCheckBox
                        } /> {item.sp_serial_number}  <span style={{ float: "right" }}>|{item.swap_sum}</span></List.Header>
                    </List.Content>
                </List.Item>
            ))}
        </List>
    )
}