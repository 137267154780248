import React, { useEffect } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Segment, Grid, GridColumn, Radio } from "semantic-ui-react";
import NestedOpeningHoures from "./NestedOpeningHoures";

export default ({ setValue, control, register, defaultValues }) => {
  const { t } = useTranslation();
  const { fields } = useFieldArray({
    control,
    name: "openingTimes"
  });

  useEffect(() => {
    fields.map((item, index) => {
      register(`openingTimes[${index}].day`);
      setValue(`openingTimes[${index}].day`, item.day);
    });
  }, []);

  return (
    <Grid>
      {fields.map((item, index) => {
        return (
          <GridColumn style={{ padding: "5px 14px 5px 14px" }} width="16">
            <Segment key={item.id}>
              <Grid>
                <GridColumn width="2">
                  <Grid>
                    <GridColumn style={{ color: "black" }} width="16">
                      {item.day}
                    </GridColumn>
                    <GridColumn verticalAlign="middle" width="16">
                      <Controller
                        control={control}
                        name={`openingTimes[${index}].disabled`}
                        render={(
                          { onChange, onBlur, value, name, ref },
                          { invalid, isTouched, isDirty }
                        ) => (
                          <Radio
                            defaultChecked={value}
                            toggle
                            onClick={e => onChange(e)}
                          />
                        )}
                      />
                    </GridColumn>
                  </Grid>
                </GridColumn>
                <GridColumn width="14">
                  <Grid>
                    <GridColumn style={{ color: "black" }} width="16">
                      <strong>Opening Hours Every {item.day}</strong>
                    </GridColumn>
                    <GridColumn style={{ color: "black" }} width="16">
                      <NestedOpeningHoures
                        {...{ index, item, control, defaultValues, setValue }}
                      />
                    </GridColumn>
                  </Grid>
                </GridColumn>
              </Grid>
            </Segment>
          </GridColumn>
        );
      })}
    </Grid>
  );
};
