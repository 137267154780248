/** @format */

import React, { useState } from "react";
import LandingScreen from "./screens/landing";
import AuthenticatedApp from "./screens/AuthenticatedApp";
import "./assets/styles/App.scss";

import AuthenticationCheck from "./components/AxiosWrapper";

import {
  AssetsProvider,
  SwapProvider,
  AxiosProvider,
  StationProvider,
  UserManagementProvider,
  CommandsProvider,
  NotifyProvider,
  LocationProvider,
} from "./context";

global.adminLikeGroups = [
  "overall_admin",
  "Connect44",
  "Connect44-service",
  "Connect44-swapper",
  "EMI Indonesia",
];

const App = () => {
  const [isSignedUp, setSignedUp] = useState(true);

  return (
    <AxiosProvider>
      <AuthenticationCheck {...{ setSignedUp }} />
      <NotifyProvider>
        <AssetsProvider>
          <StationProvider>
            <SwapProvider>
              <UserManagementProvider>
                <CommandsProvider>
                  <LocationProvider>
                    <div className="App" data-testid="app">
                      {!isSignedUp && <LandingScreen />}
                      {isSignedUp && <AuthenticatedApp />}
                    </div>
                  </LocationProvider>
                </CommandsProvider>
              </UserManagementProvider>
            </SwapProvider>
          </StationProvider>
        </AssetsProvider>
      </NotifyProvider>
    </AxiosProvider>
  );
};
export default App;
