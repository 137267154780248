import React, { useContext, useEffect, useMemo } from "react";
import LocationForm from "../../components/Locations/Form";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanies,
  getContacts,
  getLocationStates,
  getAddresses,
} from "../../store/actions";

import { useTranslation } from "react-i18next";

import { LocationContext } from "../../context/LocationContext";

const LocationsCreateScreen = ({ lang }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.session.socket);

  const locationIsSaving = useSelector((state) => state.locations.isSaving);
  const locationStates = useSelector((state) => state.locations.locationStates);
  const companies = useSelector((state) => state.companies.companies);
  const contacts = useSelector((state) => state.contacts.contacts);
  const addresses = useSelector((state) => state.addresses.adresses);
  const { createNewLocation } = useContext(LocationContext);

  useEffect(() => {
    if (!socket) return;
    if (!addresses || addresses.length === 0) {
      dispatch(getAddresses());
    }
    if (!companies || companies.length === 0) {
      dispatch(getCompanies());
    }
    if (!contacts || contacts.length === 0) {
      dispatch(getContacts());
    }
    if (!locationStates || locationStates.length === 0) {
      dispatch(getLocationStates());
    }
  }, [socket, dispatch, locationStates, contacts, companies, addresses]);

  const currentUrl = window.location.href;

  const locationHashKey = useMemo(() => {
    if (currentUrl.includes("edit")) {
      return currentUrl
        .split("edit/")[1]
        .split("/step1")[0]
        .split("/step2")[0]
        .split("/step3")[0]
        .split("/result")[0];
    }
  }, [currentUrl]);

  return (
    <div className="page page--create-locations">
      <LocationForm
        createNewLocation={createNewLocation}
        locationStates={locationStates}
        isSaving={locationIsSaving}
        locationHashKey={locationHashKey}
        formTitle={t("create.locations")}
      />
    </div>
  );
};
export default LocationsCreateScreen;
