import { Link } from "@reach/router";
import { upperFirst } from "lodash";
import React, { useContext } from "react";
import { Button } from "semantic-ui-react";
import { AssetsContext } from "../../context";

interface StationMarkerPopupProps {
  w3w?: string;
  sp_serial_number: string;
  nick_name?: string;
  city: string;
  street: string;
  street_number: string;
  zipcode: string;
  box_status_check: any;
  showDetailsButton?: boolean;
}

const CustomMarkerPopup = ({
  sp_serial_number,
  box_status_check,
  city,
  street,
  street_number,
  zipcode,
  nick_name,
  w3w,
  showDetailsButton,
}: StationMarkerPopupProps) => {
  const { dashboardAssets } = useContext(AssetsContext);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: dashboardAssets ? dashboardAssets.main_color : null,
        padding: 10,
        borderRadius: 10,
        color: "white !important",
      }}
    >
      <img
        height="120px"
        width="auto"
        src="https://swobbee-frontend-assets.s3.eu-central-1.amazonaws.com/Swobbee_Akkus_Frontal_closed.png"
        alt=""
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          color: "white !important",
          marginLeft: 16,
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <label>Sp serial number</label>
          <span>{sp_serial_number}</span>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <label>Nickname</label>
          <span>{nick_name}</span>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <label>Address</label>
          <span>{`${street} ${street_number}`}</span>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <label>Zip code & city </label>
          <span>{`${zipcode} ${city}`}</span>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <label>w3w</label>
          <span>{w3w || "-"}</span>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <label>Inside</label>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {Object.keys(box_status_check).map((key) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <span>{upperFirst(key).replaceAll("_", " ")}</span>
              <span>{box_status_check[key] || "-"}</span>
            </div>
          ))}
        </div>

        {showDetailsButton ? (
          <Link to={`/sharingpoints/${sp_serial_number}`}>
            <Button
              content="Details"
              style={{
                backgroundColor: "white",
                marginTop: "14px",
              }}
              circular
              size="mini"
              labelPosition="right"
              fluid
              icon="arrow alternate circle right"
            />
          </Link>
        ) : null}
      </div>
    </div>
  );
};

export { CustomMarkerPopup };
