/** @format */

import React, { useState, useEffect, useMemo, useContext } from "react";
import { Bar } from "@reactchartjs/react-chart.js";
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalContent } from "semantic-ui-react";
import { AxiosContext } from "../../context/AxiosContext";

const options = {
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true
        }
      }
    ],
    xAxes: [
      {
        stacked: true
      }
    ]
  }
};

export default ({ groupBy, showStatisticModal, setShowStatisticModal }) => {
  const { t } = useTranslation();
  const [state, setstate] = useState({
    loading: false,
    data: null
  });
  const { axiosInstance } = useContext(AxiosContext);
  const fetchMyAPI = async () => {
    setstate({
      ...state,
      loading: true
    });
    let { data } = await axiosInstance.get(
      `/authentications/statistic`
    );
    setstate({
      ...state,
      data: data.data,
      last_evaluated_key: data.last_evaluated_key,
      loading: false
    });
  };

  const { data } = state;

  useEffect(() => {
    fetchMyAPI();
  }, []);

  const states = useMemo(() => {
    if (!data) return;
    return {
      labels: Object.keys(data.res_success),
      datasets: [
        {
          label: "succeful",
          data: Object.values(data.res_success),
          backgroundColor: "#56B825"
        },
        {
          label: "failed",
          data: Object.values(data.res_failed),
          backgroundColor: "red"
        }
      ]
    };
  }, [data]);

  return (
    <Modal
      open={showStatisticModal}
      closeIcon
      closeOnEscape
      onClose={() => setShowStatisticModal(false)}
    >
      <ModalHeader>Authentications</ModalHeader>
      <ModalContent>
        <Bar data={states} options={options} />
      </ModalContent>
    </Modal>
  );
};
