import { navigate } from "@reach/router";
import React, { useEffect, useMemo, useState } from "react";
import { useAxiosService } from "../../helper/axiosService";
import { createBrowserHistory } from "history";
import {
  ExportDataAsCsv,
  SharingPointDropdownSearch,
  TableScreenTemplate,
  SWBSqlTable,
  LocationDropdownSearch,
} from "../UtilComponents";
import qs from "qs";

export default () => {
  const [sp_serial_number, setSelectedStation] = useState();
  const [selectedUserGroupName, setSelectedUserGroupName] = useState();
  const [location, setSelectedLocation] = useState();
  const [battery_type, setSelectedBatteryType] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [isInitialized, setIsInitialized] = useState();

  // fetch logic
  const {
    setFetchedData,
    fetchedData,
    isLoading,
    fetchData,
    sum,
    sortParams,
    setSortParams,
    sortColumn,
    setPagination,
    pagination,
  } = useAxiosService({
    backendRoute: "/station-usage",
  });

  useEffect(() => {
    return () => {
      setFetchedData(null);
    };
  }, []);

  const headers = useMemo(() => {
    return [
      {
        key: "station",
        header: "Station",
        isLink: true,
        navigateTo: "/sharingpoints/",
      },
      { key: "module_count", header: "Modules" },
      { key: "slot_count", header: "Slots" },
      { key: "swap_summary", header: "Swaps" },
      { key: "usage", header: "Usage" },
      { key: "location", header: "Location" },
    ];
  }, []);

  const resetFilterButtonHandler = () => {
    setSelectedStation(null);
    setSelectedUserGroupName(null);
    setSelectedLocation(null);
    setSelectedBatteryType(null);
    setStartDate(null);
    setEndDate(null);
    navigate("/statistics?tab=station-usage");
  };

  const history = createBrowserHistory();

  useEffect(() => {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);

    setSortParams({
      order: filtersFromParams.order,
      sorted_by: filtersFromParams.sorted_by,
    });

    setSelectedStation(filtersFromParams.sp_serial_number);
    setSelectedUserGroupName(filtersFromParams.user_group_name);
    setSelectedLocation(filtersFromParams.sp_serial_number);

    setSelectedLocation(filtersFromParams.endDate);

    setSelectedLocation(filtersFromParams.startDate);
    setPagination(pagination);

    fetchData(false, {
      sp_serial_number: filtersFromParams.sp_serial_number,
      startDate: filtersFromParams.startDate,
      endDate: filtersFromParams.endDate,
      user_group_name: filtersFromParams.user_group_name,
      battery_type: filtersFromParams.battery_type,
      location: filtersFromParams.location,
      sorted_by: filtersFromParams.sorted_by,
      order: filtersFromParams.order,
      page: pagination,
    });
    setIsInitialized(true);
  }, []);

  useEffect(() => {
    history.push(
      `?tab=station-usage&sp_serial_number=${
        sp_serial_number ? sp_serial_number : ""
      }&user_group_name=${
        selectedUserGroupName ? selectedUserGroupName : ""
      }&startDate=${startDate ? startDate : ""}&endDate=${
        endDate ? endDate : ""
      }&battery_type=${battery_type ? battery_type : ""}&location=${
        location ? location : ""
      }&sorted_by=${sortParams.sorted_by ? sortParams.sorted_by : ""}&order=${
        sortParams.order ? sortParams.order : ""
      }`
    );
    if (isInitialized) {
      fetchData(false, {
        sp_serial_number,
        startDate,
        endDate,
        user_group_name: selectedUserGroupName,
        battery_type,
        location,
        sorted_by: sortParams.sorted_by,
        order: sortParams.order,
        page: pagination,
      });
    }
  }, [
    sp_serial_number,
    startDate,
    endDate,
    selectedUserGroupName,
    battery_type,
    location,
    sortParams.order,
    sortParams.sorted_by,
  ]);

  return (
    <TableScreenTemplate
      hasFilter={true}
      hasSegment={true}
      hasFilterButton={true}
      actions={
        <>
          <ExportDataAsCsv
            data={() => fetchedData(null, { export: true })}
            filename={`station-usage-export-${new Date().toUTCString()}.csv`}
          />
        </>
      }
      {...{
        fetchData,
        sum,
        resetFilterButtonHandler,
      }}
      filter_components={
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
          }}>
          <div className="swap-table-filter-container">
            {/* <div className="filter-components">
              <SWBDatePicker
                {...{ endDate, setEndDate, setStartDate, startDate }}
              />
            </div> */}

            <div className="filter-components">
              <SharingPointDropdownSearch
                {...{ setSelectedStation, sp_serial_number }}
              />
            </div>
            {/* <div className="filter-components">
              <GroupsDropdownSearch
                {...{ selectedUserGroupName, setSelectedUserGroupName }}
              />
            </div> */}
            <div className="filter-components">
              <LocationDropdownSearch {...{ location, setSelectedLocation }} />
            </div>
            {/* <div className="filter-components">
              <BatteryTypeDropdownSearch
                {...{ setSelectedBatteryType, battery_type }}
              />
            </div> */}
          </div>
        </div>
      }
      table={
        <SWBSqlTable
          {...{
            sortColumn,
            data: fetchedData,
            headers,
            isLoading,
            fetchData: fetchData,
            sum,
            fetchData,
            sortParams,
            setSortParams,

            searchParams: {
              sp_serial_number,
              startDate,
              endDate,
              user_group_name: selectedUserGroupName,
              battery_type,
              location,
              sorted_by: sortParams.sorted_by,
              order: sortParams.order,
            },
          }}
        />
      }
      header_name="Station usage"
      description={
        "Track of your sharing-point usage, which station is being used more than others and balance your charging operations. "
      }
      data={fetchedData}
      sum_count={fetchedData && sum}
    />
  );
};
