import React from "react";
import { Loader } from "semantic-ui-react";

interface CustomLoaderProps {
  active?: boolean;
  inline?: boolean | "centered";
  size?: "small" | "medium" | "large" | "big" | "huge" | "massive";
  inverted?: boolean;
}

const CustomLoader: React.FC<CustomLoaderProps> = ({
  active = true,
  inline = true,
  size = "large",
  inverted = true,
}) => {
  return (
    <Loader active={active} inline={inline} size={size} inverted={inverted} />
  );
};

export { CustomLoader };
