import React, {CSSProperties} from "react";
import { useTranslation } from "react-i18next";

interface CustomTextProps {
  text: string;
  style?: CSSProperties;
  className?: string;
  translate?: boolean;
}

const CustomText = (props: CustomTextProps) => {
  const { text, style, className, translate } = props;
  let { t } = useTranslation();

  return (
    <span style={style} className={className}>
      {translate ? t(text) : text}
    </span>
  );
};

export {CustomText}
