/** @format */

import React, { useState, useMemo, useContext } from "react";
import { useForm } from "react-hook-form";
import { SaveButton } from "./../helpers/buttons/SaveButton";
import { Button, GridColumn, Header, Grid, Confirm } from "semantic-ui-react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import FormRow from "./../helpers/FormRow";
import { AssetsContext } from "../../context/AssetsContext";
import {
  RESOURCE_METHODS,
  SWOBBEE_RESOURCES,
} from "../../constants/app-constants";

const LocationSettingsCard = ({
  locationSettings,
  settingMetaData,
  isEditing = false,
  setIsEditing,
  isSaving = false,
  submitFunc,
  stationList,
}) => {
  const { t, i18n } = useTranslation();
  const { mainColor } = useContext(AssetsContext);
  const currentLanguage = i18n.language;
  const { isAllowedToAccess, userAllowedToAccess } = useContext(AssetsContext);

  const [showCancelEditingConfirmation, setShowCancelEditingConfirmation] =
    useState();
  const { handleSubmit, control, reset, watch, setValue } = useForm({
    defaultValues: locationSettings,
    mode: "onChange",
    shouldUnregister: false,
  });

  const locationSettingMetaData = useMemo(() => {
    let locationMetaData = settingMetaData;
    try {
      locationMetaData["shared_supply_sp_serial_numbers"]["dropdown_options"] =
        stationList.map((station) => ({
          key: station,
          text: station,
          value: station,
        }));
      return locationMetaData;
    } catch (error) {
      return locationMetaData;
    }
  }, [stationList, settingMetaData]);

  const handleEditButtonClick = (e) => {
    e.preventDefault();
    if (!isEditing) {
      resetHandler();
    } else {
      setShowCancelEditingConfirmation(true);
    }
  };

  const resetHandler = () => {
    if (!isEditing) {
      setIsEditing((current) => !current);
    } else {
      reset();
      setIsEditing((current) => !current);
    }
  };

  const onSubmit = (data) => {
    submitFunc(data);
  };

  const locationSettingsContent = useMemo(() => {
    if (settingMetaData && locationSettings) {
      let settingsContent = [];
      Object.keys(locationSettings).forEach((settingName) => {
        if (
          settingMetaData[settingName] &&
          settingMetaData[settingName].is_over_portal_configurable
        ) {
          if (settingName === "shared_supply_sp_serial_numbers") {
          }
          settingsContent.push(
            <Grid.Column
              style={{ padding: "15px" }}
              width="16"
              key={settingName}
            >
              <FormRow
                {...{ isEditing }}
                control={control}
                watch={watch}
                name={`${settingName}`}
                disabled={!isEditing}
                targetSettingMetaData={locationSettingMetaData[settingName]}
                currentLanguage={currentLanguage}
                setValue={setValue}
              />
            </Grid.Column>
          );
        }
      });

      return settingsContent;
    }
  }, [isEditing, watch, currentLanguage, settingMetaData, locationSettings]);

  const showEditButton = useMemo(() => {
    if (!userAllowedToAccess) return;
    return isAllowedToAccess(SWOBBEE_RESOURCES.LOCATIONS, RESOURCE_METHODS.PUT);
  }, [userAllowedToAccess, isAllowedToAccess]);

  return locationSettings ? (
    <form
      onSubmit={handleSubmit(onSubmit)}
      data-testid="settings-form"
      style={{ padding: "10px", width: "100%" }}
    >
      <Grid>
        <GridColumn
          width="16"
          style={{
            padding: "1px 30px 14px 5px",
          }}
        >
          <Header content={"Settings"} floated="left" />
          {showEditButton && (
            <div>
              <Button
                floated="right"
                circular
                size="mini"
                basic
                style={{ backgroundColor: mainColor, color: "white" }}
                icon={isEditing ? "close" : "edit"}
                onClick={handleEditButtonClick}
                title={isEditing ? t("settings.cancel") : t("settings.edit")}
              />
              <Confirm
                size="mini"
                open={showCancelEditingConfirmation}
                content={`Are you sure you want to quit without saving?`}
                header="Confirmation"
                onConfirm={() => {
                  resetHandler();
                  setShowCancelEditingConfirmation(false);
                }}
                onCancel={() => {
                  setShowCancelEditingConfirmation(false);
                }}
              />
            </div>
          )}
        </GridColumn>

        <GridColumn width="16">
          <div>{locationSettingsContent}</div>
        </GridColumn>

        <GridColumn
          textAlign="right"
          width="12"
          style={{ paddingRight: "30px" }}
        ></GridColumn>

        <GridColumn textAlign="right" width="2"></GridColumn>

        <GridColumn
          textAlign="right"
          width="2"
          style={{ paddingRight: "30px" }}
        >
          {showEditButton && (
            <SaveButton
              disabled={!isEditing}
              {...{
                isSaving,
              }}
            />
          )}
        </GridColumn>
      </Grid>
    </form>
  ) : (
    <></>
  );
};

export default LocationSettingsCard;
