import { Link, navigate } from "@reach/router";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  Dropdown,
  Grid,
  GridColumn,
  Label,
  Loader,
  Table,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { upperFirst } from "lodash";
import {
  BatteryTypeDropdownSearch,
  ExportDataAsCsv,
  GroupsDropdownSearch,
  LocationDropdownSearch,
  SharingPointDropdownSearch,
  SwapStateLabel,
  SWBNavigateTo,
  UsersDropdownSearch,
} from "../../components/UtilComponents";

import { useFormatTimestamp } from "./../../hooks/use-format-timestamp";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";
import SwapReportSummaryPlot from "./NivopBarPlot";
import { useDispatch } from "react-redux";
import { restApiResponse } from "../../store/actions";
import { AxiosContext } from "../../context/AxiosContext";


const stateColors = {
  COMPLETED: "green",
  CANCELED: "orange",
  ABORTED: "grey",
  COMPLETED_WITH_PROBLEMS: "red",
  ABORTED_WITH_PROBLEMS: "red",
  CANCELED_WITH_PROBLEMS: "red",
  OPEN: "blue",
  HAS_PROBLEMS: "yellow",
  LESS_TAKEN_THAN_ORDERED: "yellow",
  MORE_TAKEN_THAN_ORDERED: "yellow",
};

const BatteryStateOptions = Object.keys(stateColors).map((key) => {
  return {
    value: key,
    text: <Label content={key} color={stateColors[key]} size="mini" circular />,
    key: key,
  };
});

export default function BatteryCharging({ station_id }) {
  const [state, setstate] = useState({
    data: null,
    direction: "descending",
    count: null,
    pages: 0,
    column: null,
    page: 0,
  });

  const dispatch = useDispatch();

  const { t } = useTranslation("descriptions");
  const trs = useTranslation("sharingPointOverview");
  const { timeSpans } = useFormatTimestamp();
  const [batteryChargingStatistic, setBatteryChargingStatistic] = useState();
  const [selectedUsername, setSelectedUsername] = useState();
  const [selectedUserGroupName, setSelectedUserGroupName] = useState();

  const [swap_state, setBatteryStateSearch] = useState();
  const [battery_type, setSelectedBatteryType] = useState();
  const [sp_serial_number, setSelectedStation] = useState();
  const [isLoading, setIsLoading] = useState();
  const [exportData, setExportData] = useState();
  const [lower, setLower] = useState();
  const [upper, setUpper] = useState();
  const [location, setSelectedLocation] = useState();
  const [IsSwapExportIsLoading, setSwapExportIsLoading] = useState();


  const [isLoadingLoadMore, setIsLoadingLoadMore] = useState();
  const { axiosInstance } = useContext(AxiosContext);
  const fetchBatteryTracking = async (column, direction) => {
    setIsLoading(true);
    setstate({ ...state, data: null, count: null });
    fetchBatteryTrackingStatistic()
    const params = {
      state: swap_state,
      created_by: selectedUsername,
      user_group_name: selectedUserGroupName,
      battery_type,
      station: sp_serial_number ? sp_serial_number : station_id,
      lower: lower ? moment(lower, "DD/MM/YYYY").toDate().getTime() : null,
      upper: upper
        ? moment(upper, "DD/MM/YYYY").toDate().getTime() + 86400000
        : null,
      location_id: location,
    };

    if (!column) {
      const { data } = await axiosInstance.get("/swaps/swap_actions", {
        params,
      });
      setstate({
        ...state,
        data: data.data,
        count: data.count,
        pages: data.pages,
      });
      setIsLoading(false);
      return;
    }

    if (state.column === column) {
      const { data } = await axiosInstance.get("/swaps/swap_actions", {
        params: {
          ...params,
          sorted_by: column ? column : state.column,
          order: state.direction === "ascending" ? "DESC" : "ASC",
        },
      });
      setstate({
        ...state,
        data: data.data,
        count: data.count,
        pages: data.pages,
        direction: state.direction === "ascending" ? "descending" : "ascending",
      });
      setIsLoading(false);
      return;
    }
    const { data } = await axiosInstance.get("/swaps/swap_actions", {
      params: {
        ...params,
        sorted_by: column,
        order: state.direction === "ascending" ? "DESC" : "ASC",
      },
    });
    setstate({
      ...state,
      column: column ? column : state.column,
      data: data.data,
      count: data.count,
      pages: data.pages,
      direction: "ascending",
    });

    setIsLoading(false);
  };

  const fetchBatteryTrackingStatistic = async () => {
    const { data } = await axiosInstance.get(
      "/swaps/swap_action_report",
      {
        params: {
          state: swap_state,
          created_by: selectedUsername,
          user_group_name: selectedUserGroupName,
          battery_type,
          station: sp_serial_number ? sp_serial_number : station_id,
          lower: lower ? moment(lower, "DD/MM/YYYY").toDate().getTime() : null,
          upper: upper
            ? moment(upper, "DD/MM/YYYY").toDate().getTime() + 86400000
            : null,
          location_id: location,
        },
      }
    );

    setBatteryChargingStatistic(data.data);
  };

  const loadMore = async () => {
    setIsLoadingLoadMore(true);
    const { data } = await axiosInstance.get("/swaps/swap_actions", {
      params: {
        state: swap_state,
        created_by: selectedUsername,
        user_group_name: selectedUserGroupName,
        battery_type,
        station: sp_serial_number ? sp_serial_number : station_id,
        lower: lower ? moment(lower, "DD/MM/YYYY").toDate().getTime() : null,
        upper: upper
          ? moment(upper, "DD/MM/YYYY").toDate().getTime() + 86400000
          : null,
        page: state.page + 1,
      },
    });
    setstate({
      ...state,
      data: [...state.data, ...data.data],
      page: data.page,
    });
    setIsLoadingLoadMore(false);
  };

  useEffect(() => {
    fetchBatteryTracking();
    return () => {};
  }, []);


  const sortColumn = async (column) => {
    fetchBatteryTracking(
      column,
      state.direction === "ascending" ? "descending" : "ascending"
    );
  };

  const fetchExportData = async () => {
    try {
      setSwapExportIsLoading(true)
      const { data } = await axiosInstance.get("/swaps/swap_actions", {
        params: {
          state: swap_state,
          created_by: selectedUsername,
          user_group_name: selectedUserGroupName,
          battery_type,
          station: sp_serial_number ? sp_serial_number : station_id,
          export: true,
          lower: lower ? moment(lower, "DD/MM/YYYY").toDate().getTime() : null,
          upper: upper
            ? moment(upper, "DD/MM/YYYY").toDate().getTime() + 86400000
            : null,
        },
      });
  
      setExportData(
        data.data.map(
          ({
            battery_type,
            count_in,
            count_out,
            created_at,
            created_by,
            dynamo_swap_id,
            location,
            location_id,
            requested,
            state,
            station,
            updated_at,
            user_group_name,
            tracking_in,
            tracking_out,
          }) => {
            return {
              booking_id: dynamo_swap_id,
              sp_serial_number: station,
              user_name: created_by,
              battery_count: requested,
              count_in: count_in ? count_in : 0,
              count_out: count_out ? count_out : 0,
              count_in_new_logic: tracking_in ? tracking_in : 0,
              count_out_new_logic: tracking_out ? tracking_out : 0,
              timestamp: moment(created_at).format("DD-MM-YYYY HH:mm:ss"),
              state,
              user_group_name,
              updated_at,
              location,
              location_id,
              battery_type,
            };
          }
        )
      );
      setSwapExportIsLoading(false)
    } catch (error) {
      let response = {
        message: "Response size exceeds export limit. Apply filters to reduce size.",
        request_parameter: "",
        status_code: 400,
      };
      dispatch(restApiResponse(response));
      setExportData([])
      setSwapExportIsLoading(false)
    }
    
  };

  const resetFilterButtonHandler = () => {
    setBatteryStateSearch(null);
    setSelectedLocation(null);
    setSelectedLocation(null);
    setSelectedUserGroupName(null);
    setSelectedBatteryType(null);
    setSelectedStation(null);
    setSelectedUsername(null);
    setUpper(null);
    setLower(null);
  };

  let summary_report_new_lib = useMemo(() => {
    if (!batteryChargingStatistic) return;
    let res = Object.keys(batteryChargingStatistic).map((key) => {
      if (batteryChargingStatistic[key].sum_count_out)
        return {
          label: key,
          [key]: batteryChargingStatistic[key].sum_count_out,
          [`${key}Color`]: "hsl(121, 70%, 50%)",
        };
    });
    return res.filter(function (item) {
      return item !== undefined;
    });
  }, [batteryChargingStatistic]);

  return (
    <Grid columns={1}>
      <GridColumn style={{ minHeight: 800 }}>
        <Grid columns={1}>
          <GridColumn
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <ExportDataAsCsv
                fetchData={() => fetchExportData()}
                data={exportData}
                filename={`swap_export_${new Date().toISOString()}`}
                isLoading={IsSwapExportIsLoading}
              />
              <Button
                icon="close"
                content="Reset filter"
                onClick={() => resetFilterButtonHandler()}
                basic
                size="small"
              />
            </div>
          </GridColumn>
          <GridColumn
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span style={{ color: "#444", fontSize: 18 }}>
              {state.data ? `${state.data.length} / ${state.count}` : ""}{" "}
              {trs.t("swaps.swaps")}
            </span>
          </GridColumn>
          <GridColumn style={{ paddingTop: 0 }} width="12">
            <span style={{ color: "#687078" }}>{t("swap_order_view")}</span>
          </GridColumn>
          <GridColumn>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: "column",
                }}
              >
                <div className="swap-table-filter-container">
                  {!station_id && (
                    <div className="filter-components">
                      <SharingPointDropdownSearch
                        {...{
                          setSelectedStation,
                          sp_serial_number,
                        }}
                      />
                    </div>
                  )}

                  <div className="filter-components">
                    <BatteryTypeDropdownSearch
                      {...{
                        setSelectedBatteryType,
                        battery_type,
                      }}
                    />
                  </div>
                  <div className="filter-components">
                    <Dropdown
                      placeholder="Swap state"
                      clearable
                      selection
                      fluid
                      options={BatteryStateOptions}
                      defaultValue={swap_state}
                      value={swap_state}
                      onChange={(_, { value }) =>
                        setBatteryStateSearch(value ? value : null)
                      }
                    />
                  </div>
                  <div className="filter-components">
                    <UsersDropdownSearch
                      {...{
                        selectedUsername,
                        setSelectedUsername,
                        className: "filter-components",
                      }}
                    />
                  </div>
                </div>
                <div className="swap-table-filter-container">
                  <div className="filter-components">
                    <DateInput
                      localization="de"
                      clearable
                      animation="fade"
                      name="date"
                      placeholder={trs.t("swaps.start")}
                      value={lower}
                      dateFormat="DD-MM-YYYY"
                      iconPosition="left"
                      maxDate={new Date()}
                      onChange={(_, { value }) => setLower(value)}
                      closeOnMouseLeave
                    />
                  </div>

                  <div className="filter-components">
                    <DateInput
                      localization="de"
                      clearable
                      animation="fade"
                      name="date"
                      placeholder={trs.t("swaps.end")}
                      value={upper}
                      dateFormat="DD-MM-YYYY"
                      iconPosition="left"
                      onChange={(_, { value }) => setUpper(value)}
                      maxDate={new Date()}
                      closeOnMouseLeave
                    />
                  </div>

                  {!station_id && (
                    <div className="filter-components">
                      <LocationDropdownSearch
                        {...{ setSelectedLocation, location }}
                      />
                    </div>
                  )}

                  <div className="filter-components">
                    <GroupsDropdownSearch
                      {...{
                        selectedUserGroupName,
                        setSelectedUserGroupName,
                        width: "20%",
                        marginRight: 14,
                      }}
                    />
                  </div>
                </div>
              </div>

              <Button
                style={{ width: "8%", height: 37, margin: 5 }}
                icon="search"
                size="mini"
                basic
                onClick={() => fetchBatteryTracking()}
              />
            </div>
          </GridColumn>
          <GridColumn>
            <SwapReportSummaryPlot
              {...{
                data: summary_report_new_lib,
                keys: [
                  "okai b",
                  "segway",
                  "greenpack",
                  "okai a",
                  "torrot",
                  "aes ebike akku 2.0 48v",
                  "ymc neos",
                  "niu 60v"
                ],
                xLabel: "Battery",
                yLabel: "Battery out",
              }}
            />
          </GridColumn>
          <GridColumn>
            <Table fixed sortable style={{ width: "100%" }}>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell
                    className="left-header"
                    sorted={
                      state.column === "dynamo_swap_id" ? state.direction : null
                    }
                    onClick={() => sortColumn("dynamo_swap_id")}
                  >
                    Swap id
                  </TableHeaderCell>
                  <TableHeaderCell
                    className="inner-header"
                    sorted={
                      state.column === "location" ? state.direction : null
                    }
                    onClick={() => sortColumn("location")}
                  >
                    Location
                  </TableHeaderCell>
                  <TableHeaderCell
                    className="inner-header"
                    sorted={state.column === "station" ? state.direction : null}
                    onClick={() => sortColumn("station")}
                  >
                    Station
                  </TableHeaderCell>

                  <TableHeaderCell
                    className="inner-header"
                    sorted={
                      state.column === "battery_type" ? state.direction : null
                    }
                    onClick={() => sortColumn("battery_type")}
                  >
                    Battery type
                  </TableHeaderCell>
                  <TableHeaderCell
                    className="inner-header"
                    sorted={
                      state.column === "requested" ? state.direction : null
                    }
                    onClick={() => sortColumn("requested")}
                  >
                    Requested
                  </TableHeaderCell>
                  <TableHeaderCell
                    className="inner-header"
                    sorted={
                      state.column === "count_out" ? state.direction : null
                    }
                    onClick={() => sortColumn("count_out")}
                  >
                    Out
                  </TableHeaderCell>

                  <TableHeaderCell
                    className="inner-header"
                    sorted={
                      state.column === "count_in" ? state.direction : null
                    }
                    onClick={() => sortColumn("count_in")}
                  >
                    In
                  </TableHeaderCell>

                  <TableHeaderCell
                    className="inner-header"
                    sorted={
                      state.column === "created_by" ? state.direction : null
                    }
                    onClick={() => sortColumn("created_by")}
                  >
                    Username
                  </TableHeaderCell>
                  <TableHeaderCell
                    className="inner-header"
                    sorted={
                      state.column === "user_group_name"
                        ? state.direction
                        : null
                    }
                    onClick={() => sortColumn("user_group_name")}
                  >
                    User group
                  </TableHeaderCell>
                  <TableHeaderCell
                    className="inner-header"
                    sorted={
                      state.column === "created_at" ? state.direction : null
                    }
                    onClick={() => sortColumn("created_at")}
                  >
                    Created at
                  </TableHeaderCell>
                  <TableHeaderCell
                    className="inner-header"
                    sorted={
                      state.column === "updated_at" ? state.direction : null
                    }
                    onClick={() => sortColumn("updated_at")}
                  >
                    Updated at
                  </TableHeaderCell>

                  <TableHeaderCell
                    className="right-header"
                    sorted={state.column === "state" ? state.direction : null}
                    onClick={() => sortColumn("state")}
                  >
                    State
                  </TableHeaderCell>
                </TableRow>
              </TableHeader>
              <Table.Body>
                {state.data &&
                  state.data.map(
                    (
                      {
                        battery_type,
                        count_in,
                        count_out,
                        created_at,
                        created_by,
                        dynamo_swap_id,
                        requested,
                        state,
                        station,
                        updated_at,
                        user_group_name,
                        location,
                        swap_type
                      },
                      index
                    ) => (
                      <TableRow key={index}>
                        <Table.Cell
                          onClick={() => {
                            navigate();
                          }}
                        >
                          <Link to={`/statistics/${dynamo_swap_id}`}>
                            {dynamo_swap_id}
                          </Link>
                        </Table.Cell>
                        <Table.Cell>{location}</Table.Cell>
                        <Table.Cell>
                          <SWBNavigateTo
                            view={"SharingpointDetailView"}
                            propId={station}
                          />
                        </Table.Cell>

                        <Table.Cell>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {battery_type.split(",").map((item) => (
                              <span>{upperFirst(item)}</span>
                            ))}
                          </div>
                        </Table.Cell>

                        <Table.Cell>
                          {requested !== -1 ? (
                            requested
                          ) : (
                            <Label
                              content={"FILL-IN"}
                              color="blue"
                              circular
                              style={{ width: "100%" }}
                            />
                          )}
                        </Table.Cell>
                        <Table.Cell>{count_out}</Table.Cell>

                        <Table.Cell>{count_in}</Table.Cell>

                        <Table.Cell>
                          <SWBNavigateTo
                            view={"UserProfileView"}
                            propId={created_by}
                          />
                        </Table.Cell>
                        <Table.Cell>{user_group_name}</Table.Cell>
                        <Table.Cell>{timeSpans(created_at)}</Table.Cell>
                        <Table.Cell>{timeSpans(updated_at)}</Table.Cell>
                        <Table.Cell>
                          <SwapStateLabel {...{ state }} />
                        </Table.Cell>
                      </TableRow>
                    )
                  )}
              </Table.Body>
            </Table>
          </GridColumn>
          <GridColumn textAlign="center" width="16">
            {isLoading ? (
              <Loader inline active size="large" />
            ) : (
              <span style={{ color: "#444" }}>
                {(!state.data || state.data.length === 0) &&
                  "no data available"}
              </span>
            )}
          </GridColumn>
          {state.data && state.data.length > 0 && (
            <GridColumn textAlign="center" width="16">
              <Button
                content={`Load More (${state.data.length}/${state.count})`}
                basic
                disabled={state.count === state.data.length}
                circular
                loading={isLoadingLoadMore}
                onClick={() => {
                  loadMore();
                }}
              />
            </GridColumn>
          )}
        </Grid>
      </GridColumn>
    </Grid>
  );
}

