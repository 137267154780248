import React from "react";

import "./index.scss";
import { CustomText } from "../SwobbeeUtilComponents";

interface TableHeaderProps {
  header: string;
  subHeader: string;
  itemCount: string;
}

const CustomTableHeader = ({
  header,
  subHeader,
  itemCount,
}: TableHeaderProps) => {
  return (
    <div className="custom-table-header">
      <div
        style={{
          display: "flex",
          gap: "10px",
          fontWeight: "bold",
          fontSize: "18px",
        }}
      >
        <CustomText text={itemCount} />
        <CustomText text={header} translate />
      </div>
      <div style={{ display: "flex", gap: "10px", color: "grey" }}>
        <CustomText text={subHeader} translate />
      </div>
    </div>
  );
};

export { CustomTableHeader };
