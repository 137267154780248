import React, { useContext, useEffect } from "react";
import awsconfig from "../config/awsconfig";
import { AxiosContext } from "../context/AxiosContext";

interface AxiosWrapperProps {
  setSignedUp: any;
}

const AuthenticationCheck = (props: AxiosWrapperProps) => {
  const { setSignedUp } = props;
  const { setAccessToken } = useContext(AxiosContext);
  useEffect(() => {
    let access_token = localStorage.getItem(
      `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.token`
    );
    setSignedUp(access_token);
    if (access_token) {
      setAccessToken(access_token);
    }
    const handleStorage = () => {
      // When local storage changes, dump the list to
      // the console.

      let access_token = localStorage.getItem(
        `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.token`
      );
      setAccessToken(access_token);

      setSignedUp(access_token);
    };

    window.addEventListener("storage", handleStorage);

    return () => {
      window.removeEventListener("storage", handleStorage);
    };
  }, [setAccessToken, setSignedUp]);
  return <></>;
};

export default AuthenticationCheck;
