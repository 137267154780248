/** @format */

import React, { useContext, useEffect } from "react";
import { Icon, Menu } from "semantic-ui-react";
import { updateSideBarState } from "../../store/actions";

import "./index.scss";
import { Link } from "@reach/router";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { animated, useSpring } from "react-spring";
import { AssetsContext } from "../../context";

interface SideBarItemProps {
  item: any;
  collapsed: boolean;
}

const SideBarItem = (props: SideBarItemProps) => {
  const { item, collapsed } = props;
  const { t } = useTranslation();
  const { isAllowedToAccess } = useContext(AssetsContext);

  let dispatch = useDispatch();

  const [api] = useSpring(() => ({ opactiy: 0 }));

  useEffect(() => {
    // api.start({ opacity: collapsed ? 0 : 1 });
  }, [collapsed, api]);

  return isAllowedToAccess(item.resource, item.method) ? (
    <Menu.Item>
      <Link
        to={item.href}
        onClick={() => dispatch(updateSideBarState(!collapsed))}
      >
        <Icon name={item.icon} />
        <animated.div>{t(item.label)}</animated.div>
      </Link>
    </Menu.Item>
  ) : null;
};

export default SideBarItem;
