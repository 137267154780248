/** @format */

// /** @format */

import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import {
  Table,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Grid,
  GridColumn,
  Input,
  Button,
  Loader,
  Label,
} from "semantic-ui-react";
import { useFormatTimestamp } from "../../hooks/use-format-timestamp";
import { AxiosContext } from "../../context/AxiosContext";

export default () => {
  const [state, setState] = useState({
    column: null,
    data: null,
    direction: null,
    last_evaluated_key: null,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const { axiosInstance } = useContext(AxiosContext);
  const { timeSpans } = useFormatTimestamp();

  const fetchMyAPI = async () => {
    const { data } = await axiosInstance.get(`/rfid/validations`, {
      params: {
        search_term: searchTerm,
      },
    });
    setState({
      data: data.data,
      last_evaluated_key: data.last_evaluated_key,
    });
  };

  const loadMore = async () => {
    let { data } = await axiosInstance.get(`/rfid/validations`, {
      params: {
        search_term: searchTerm,
        ...state.last_evaluated_key,
      },
    });
    setState({
      ...state,
      data: [...state.data, ...data.data],
      last_evaluated_key: data.last_evaluated_key,
    });
  };

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     fetchMyAPI();
  //   }, 10000);
  //   return () => clearInterval(intervalId);
  // }, []);

  useEffect(() => {
    fetchMyAPI();
    return;
  }, []);

  const statusMapping = {
    SUCCESSFUL: "green",
    FAILED: "red",
    PENDING: "yellow",
  };

  const { column, data, direction } = state;

  const handleChange = event => {
    setSearchTerm(event.target.value);
  };

  const sortColumn = column => {
    if (state.column === column) {
      return setState({
        ...state,
        data: state.data.slice().reverse(),
        direction: state.direction === "ascending" ? "descending" : "ascending",
      });
    }
    return setState({
      ...state,
      column,
      data: _.sortBy(state.data, [column]),
      direction: "ascending",
    });
  };

  // useEffect(() => {
  //   const intervalId = () => {
  //     setInterval(() => {
  //       fetchMyAPI();
  //     }, 10000);
  //   };
  //   intervalId();
  //   return function cleanup() {
  //     clearInterval(intervalId);
  //   };
  // }, []);

  return (
    <Grid>
      {!data && (
        <GridColumn width="16">
          <Loader active inline size="large" />
        </GridColumn>
      )}

      {data && (
        <>
          {data && (
            <>
              <GridColumn
                verticalAlign="middle"
                width="16"
                style={{ padding: "30px 30px 0px" }}
              >
                <span
                  style={{
                    float: "left",
                    color: "#444",
                    marginTop: 2,
                    fontSize: 18,
                  }}
                >
                  {data.length} Rfid validations{" "}
                </span>
                <Button
                  basic
                  circular
                  onClick={() => {
                    setState({
                      column: null,
                      data: [],
                      direction: null,
                      last_evaluated_key: null,
                    });
                    fetchMyAPI();
                  }}
                  icon="search"
                  size="mini"
                  style={{ float: "right", marginLeft: 14 }}
                />
                <Input
                  style={{ float: "right", width: 300 }}
                  placeholder="sp serial number, username, rfid tag, name or company"
                  value={searchTerm}
                  onChange={handleChange}
                  size="mini"
                />
              </GridColumn>
            </>
          )}

          <GridColumn width="16" style={{ padding: "30px 30px 0px 30px" }}>
            {data && (
              <Table fixed sortable>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell
                      className="left-header"
                      sorted={column === "sp_serial_number" ? direction : null}
                      onClick={() => sortColumn("sp_serial_number")}
                    >
                      Sp Serial Number
                    </TableHeaderCell>
                    <TableHeaderCell
                      className="inner-header"
                      sorted={column === "username" ? direction : null}
                      onClick={() => sortColumn("username")}
                    >
                      Username
                    </TableHeaderCell>
                    <TableHeaderCell
                      className="inner-header"
                      sorted={column === "rfid_tag" ? direction : null}
                      onClick={() => sortColumn("rfid_tag")}
                    >
                      Rfid tag
                    </TableHeaderCell>

                    <TableHeaderCell
                      className="inner-header"
                      sorted={column === "user_group_name" ? direction : null}
                      onClick={() => sortColumn("user_group_name")}
                    >
                      Company
                    </TableHeaderCell>

                    <TableHeaderCell
                      className="inner-header"
                      sorted={column === "status" ? direction : null}
                      onClick={() => sortColumn("status")}
                    >
                      Status
                    </TableHeaderCell>

                    <TableHeaderCell
                      className="inner-header"
                      sorted={column === "error_message" ? direction : null}
                      onClick={() => sortColumn("error_message")}
                    >
                      Message
                    </TableHeaderCell>

                    <TableHeaderCell
                      className="right-header"
                      sorted={column === "created_at" ? direction : null}
                      onClick={() => sortColumn("created_at")}
                    >
                      Created at
                    </TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <Table.Body>
                  {data &&
                    data.map(
                      (
                        {
                          rfid_tag,
                          created_at,
                          email,
                          user_group_name,
                          status,
                          error_message,
                          sp_serial_number,
                        },
                        index
                      ) => (
                        <TableRow key={index}>
                          <Table.Cell>{sp_serial_number}</Table.Cell>
                          <Table.Cell>{email}</Table.Cell>
                          <Table.Cell>{rfid_tag.replace(/^0+/, '')}</Table.Cell>
                          <Table.Cell>{user_group_name}</Table.Cell>

                          <Table.Cell>
                            <Label
                              content={status}
                              color={statusMapping[status]}
                              circular
                            />
                          </Table.Cell>

                          <Table.Cell>{error_message}</Table.Cell>

                          <Table.Cell>{timeSpans(created_at)}</Table.Cell>
                        </TableRow>
                      )
                    )}
                </Table.Body>
              </Table>
            )}
          </GridColumn>
          <GridColumn textAlign="center" width="16" style={{ color: "#444" }}>
            {state.data && state.data.length === 0 && "no data available"}
            {state.data && state.data.length > 0 && (
              <Button
                disabled={!state.last_evaluated_key}
                content="Load More"
                basic
                circular
                onClick={() => {
                  loadMore();
                }}
              />
            )}
          </GridColumn>
        </>
      )}
    </Grid>
  );
};
