import React, { createContext, useCallback, useContext, useState } from "react";
import { AxiosContext } from "./AxiosContext";

import { navigate } from "@reach/router";
import { NotifyContext } from "./NotfyContext";

export const LocationContext = createContext({
  createNewLocation: () => null,
  isLoadingPostLocation: false,
});

export const LocationProvider = ({ children }) => {
  const [isLoadingPostLocation, setIsLoadingPostLocation] = useState(false);

  const { axiosInstance } = useContext(AxiosContext);
  const { showErrorNotification, showSuccessNotification } =
    useContext(NotifyContext);

  const createNewLocation = useCallback(
    async ({ location, locationHashKey }) => {
      try {
        setIsLoadingPostLocation(true);
        let newLocation = location;
        if (locationHashKey) {
          newLocation["hash_key"] = locationHashKey;
        }
        const { data } = await axiosInstance.post("/locations", newLocation);
        showSuccessNotification(`Location successfully ${locationHashKey ? "edited": "created"}`);
        setIsLoadingPostLocation(false);
        navigate(`/locations/${data.data.hash_key}`);
      } catch (error) {
        showErrorNotification(
          "Failed to create location:",
          error?.response?.data?.message
        );
        setIsLoadingPostLocation(false);
      }
    },
    [axiosInstance, showErrorNotification, showSuccessNotification]
  );

  return (
    <LocationContext.Provider
      value={{
        createNewLocation,
        isLoadingPostLocation,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
