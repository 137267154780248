import React from 'react';
import { Label } from 'semantic-ui-react';

// takes unix timestamp ms

const IsNewItem = ({created_at}) => {
    return (
        <>
        {new Date(+created_at).getFullYear() +
            " " +
            new Date(+created_at).getMonth() ===
            new Date().getFullYear() +
              " " +
              new Date().getMonth() && (
            <Label
              content="new"
              size="mini"
              color="orange"
              circular
              basic
            />
          )}
        </>
        
    );
}

export default IsNewItem;
