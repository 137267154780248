import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalActions,
  Form,
  Button,
  FormField,
} from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { restApiResponse } from "../../store/actions";
import { useDispatch } from "react-redux";
import { AxiosContext } from "../../context/AxiosContext";

const CreateRfid = ({
  companyDropdown,
  setShowRfidForm,
  showRfidForm,
  edit,
  rfidTag,
  setEdit,
  fetchMyAPI,
}) => {
  const { register, errors, handleSubmit, reset } = useForm();
  const dispatch = useDispatch();
  const { axiosInstance } = useContext(AxiosContext);
  useEffect(() => {
    register({ name: "rfid_tag" }, { required: true });
    register({ name: "name" }, { required: true });
    register({ name: "company" }, { required: true });
  }, []);
  const [rfid, setRfid] = useState();

  const fetchRfidByTag = async () => {
    const { data } = await axiosInstance.get("/rfid/" + rfidTag);
    setRfid(data.data);
  };

  const onSubmit = async (data, e) => {
    try {
      if (edit) {
        data["activated"] = data.activated == "true";
        await axiosInstance.put("/rfid", data);
      } else {
        await axiosInstance.post("/rfid", data);
      }
      let response = {
        message: `${
          edit ? "RFID successfuly updated" : "RFID successfuly created"
        }`,
        request_parameter: "",
        status_code: 200,
      };
      dispatch(restApiResponse(response));
      setShowRfidForm(false);
      fetchMyAPI();
    } catch (error) {
      let response = {
        message: `${edit ? "Failed to update RFID" : "Failed to create RFID "}`,
        request_parameter: "",
        status_code: 400,
      };
      dispatch(restApiResponse(response));
    }
  };

  useEffect(() => {
    if (!rfidTag) return;
    fetchRfidByTag();
  }, [rfidTag]);

  useEffect(() => {
    if (!edit && !rfid) return;
    reset(rfid);
  }, [rfid]);

  return (
    <Modal
      size="mini"
      open={showRfidForm}
      onClose={() => {
        setShowRfidForm(false);
        setEdit(false);
        setRfid({
          activated: true,
          card_number: null,
          company: null,
          name: null,
          rfid_tag: null,
        });
      }}
      closeOnEscape={true}
    >
      <ModalHeader>{edit ? "Edit" : "Create"} RFID</ModalHeader>
      <ModalContent>
        <Form onSubmit={handleSubmit(onSubmit)} required>
          <Form.Field disabled={edit} error={errors.rfid_tag}>
            <label htmlFor="name">Rfid tag</label>
            <input
              type="text"
              name="rfid_tag"
              placeholder={"rfid tag"}
              id="rfid_tag"
              ref={register({
                required: "rfid tag is required.",
              })}
            />
          </Form.Field>

          <Form.Field error={errors.rfid_card} required>
            <label htmlFor="name">Card number</label>
            <input
              type="text"
              name="card_number"
              placeholder={"rfid card"}
              id="card_number"
              ref={register({
                required: "rfid tag is required.",
              })}
            />
          </Form.Field>

          <Form.Field error={errors.name} required>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              placeholder={"Name"}
              id="name"
              ref={register({
                required: "Name tag is required.",
              })}
            />
          </Form.Field>

          <Form.Field error={errors.company} required>
            <label>User group</label>
            <select
              name="company"
              placeholder="Company"
              ref={register({
                required: "select one option",
              })}
            >
              {companyDropdown &&
                companyDropdown
                  .sort((a, b) =>
                    a["text"].toLowerCase() > b["text"].toLowerCase() ? 1 : -1
                  )
                  .map(({ value, text, key }) => (
                    <option value={value} key={key}>
                      {text}
                    </option>
                  ))}
            </select>
          </Form.Field>

          <FormField error={errors.activated}>
            <label>State</label>
            <select
              name="activated"
              placeholder="State"
              ref={register({
                required: false,
              })}
            >
              <option value={true} key={"Activated"}>
                Activated
              </option>

              <option value={false} key={"Deactivated"}>
                Deactivated
              </option>
            </select>
          </FormField>
        </Form>
      </ModalContent>
      <ModalActions>
        <Button
          content="Submit"
          primary
          onClick={() => {
            handleSubmit(onSubmit)();
          }}
        />
      </ModalActions>
    </Modal>
  );
};

export default CreateRfid;
