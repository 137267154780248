/** @format */

// /** @format */

import _ from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import "./style.scss";
import {
  Table,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Grid,
  GridColumn,
  Input,
  Button,
  Loader,
  Label,
} from "semantic-ui-react";
import DeleteGroup from "./DeleteGroup";
import CreateGroup from "./CreateGroup";
import { EditSwapperGroupRestriction } from "./EditSwapperAccess";

import { useFormatTimestamp } from "../../hooks/use-format-timestamp";
import IsNewItem from "../IsNewItem";

import { AssetsContext, UserManagementContext } from "../../context";
import { CustomTableHeader } from "../CustomComponents";
import {
  RESOURCE_METHODS,
  SWOBBEE_RESOURCES,
} from "../../constants/app-constants";

export default () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGroup, setSelectedGroup] = useState();
  const [isOpenCreateGroupModal, setIsOpenCreateGroupModal] = useState();
  const [isOpenDeleteGroupModal, setIsOpenDeleteGroupModal] = useState();
  const [isOpenEditSwapperAccessModal, setIsOpenEditSwapperAccessModal] =
    useState();

  const [editData, setEditData] = useState();
  const [batteryData, setBatteryData] = useState();
  const [groupName, setGroupName] = useState();
  const [allowedToForceSwap, setAllowedToForceSwap] = useState();
  const [limitedSwapAccess, setLimitedSwapAccess] = useState();
  const [reservationRequestLimit, setReservationRequestLimit] = useState();
  const [reservationInterval, setReservationInterval] = useState();

  const [reservationLimit, setReservationLimit] = useState();

  const { timeSpans } = useFormatTimestamp();

  const { userGroups, setUserGroups, isLoadingUserGroups, fetchUserGroups } =
    useContext(UserManagementContext);

  const { column, data: fetchedData, direction, searchResults } = userGroups;

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (!fetchedData) return;
    const results = fetchedData.filter((person) =>
      person.user_group_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setUserGroups({ ...userGroups, searchResults: results });
  }, [searchTerm]);

  const sortColumn = (column) => {
    if (userGroups.column === column) {
      return setUserGroups({
        ...userGroups,
        searchResults: userGroups.searchResults.slice().reverse(),
        direction:
          userGroups.direction === "ascending" ? "descending" : "ascending",
      });
    }
    return setUserGroups({
      ...userGroups,
      column,
      searchResults: _.sortBy(userGroups.searchResults, [column]),
      direction: "ascending",
    });
  };

  const { isAllowedToAccess } = useContext(AssetsContext);

  const allowedToPOST = useMemo(() => {
    return isAllowedToAccess(
      SWOBBEE_RESOURCES.USER_GROUPS,
      RESOURCE_METHODS.POST
    );
  }, []);

  const allowedToPUT = useMemo(() => {
    return isAllowedToAccess(
      SWOBBEE_RESOURCES.USER_GROUPS,
      RESOURCE_METHODS.PUT
    );
  }, []);

  return (
    <Grid>
      {!searchResults && (
        <GridColumn width="16">
          <Loader active inline size="large" />
        </GridColumn>
      )}

      {searchResults && (
        <>
          {searchResults && (
            <>
              {allowedToPOST ? (
                <GridColumn width="16" style={{ padding: "30px 30px 0px" }}>
                  <Button
                    basic
                    onClick={() => {
                      setGroupName();
                      setEditData();
                      setSelectedGroup(false);
                      setBatteryData();
                      setAllowedToForceSwap();
                      setReservationRequestLimit();
                      setReservationLimit();
                      setReservationInterval();
                      setIsOpenCreateGroupModal(true);
                    }}
                    icon="plus"
                    size="mini"
                    content="Create group"
                  />
                </GridColumn>
              ) : null}
              <GridColumn width="16" style={{ padding: "30px 30px 0px" }}>
                <CustomTableHeader
                  header={"Groups"}
                  itemCount={`${fetchedData.length}/${fetchedData.length}`}
                  subHeader={
                    "Manage your user groups by filtering by name, creating new groups, and editing, deleting, or blocking them as needed."
                  }
                />

                <Input
                  style={{ float: "right", width: 250 }}
                  icon="search"
                  placeholder="Search by group name..."
                  value={searchTerm}
                  onChange={handleChange}
                  size="mini"
                />
              </GridColumn>
            </>
          )}

          <GridColumn width="16" style={{ padding: "30px 30px 0px 30px" }}>
            {searchResults && (
              <Table fixed sortable>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell
                      className="left-header"
                      sorted={column === "group" ? direction : null}
                      onClick={() => sortColumn("group")}
                    >
                      User Group
                    </TableHeaderCell>
                    <TableHeaderCell
                      className="inner-header"
                      sorted={column === "company" ? direction : null}
                      onClick={() => sortColumn("company")}
                    >
                      Company
                    </TableHeaderCell>
                    <TableHeaderCell className="inner-header">
                      Force to Swap
                    </TableHeaderCell>
                    <TableHeaderCell className="inner-header">
                      Default username
                    </TableHeaderCell>
                    <TableHeaderCell
                      className="inner-header"
                      sorted={column === "created_by" ? direction : null}
                      onClick={() => sortColumn("created_by")}
                    >
                      Created By
                    </TableHeaderCell>

                    <TableHeaderCell
                      className="inner-header"
                      sorted={column === "created_at" ? direction : null}
                      onClick={() => sortColumn("created_at")}
                    >
                      Created At
                    </TableHeaderCell>
                    <TableHeaderCell
                      className="inner-header"
                      sorted={column === "updated_at" ? direction : null}
                      onClick={() => sortColumn("updated_at")}
                    >
                      Updated At
                    </TableHeaderCell>
                    <TableHeaderCell className="right-header">
                      Actions
                    </TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <Table.Body>
                  {searchResults &&
                    searchResults.map(
                      (
                        {
                          user_group_name,
                          created_by,
                          updated_at,
                          created_at,
                          sharingpoints,
                          parent_company,
                          allowed_to_force_swap,
                          reservation_request_limit,
                          reservation_interval,
                          reservation_limit,
                          batteries,
                          default_username,
                          swapper_group_restriction_info,
                        },
                        index
                      ) => (
                        <TableRow key={user_group_name}>
                          <Table.Cell>
                            {" "}
                            {user_group_name}{" "}
                            <IsNewItem created_at={+created_at} />
                          </Table.Cell>
                          <Table.Cell>{parent_company}</Table.Cell>
                          <Table.Cell>
                            {
                              <Label
                                content={
                                  allowed_to_force_swap
                                    ? "allowed"
                                    : "unallowed"
                                }
                                color={allowed_to_force_swap ? "green" : "grey"}
                                circular
                                style={{ width: 80 }}
                              />
                            }
                          </Table.Cell>
                          <Table.Cell>{default_username}</Table.Cell>
                          <Table.Cell>{created_by}</Table.Cell>
                          <Table.Cell>{timeSpans(created_at)}</Table.Cell>
                          <Table.Cell>{timeSpans(updated_at)}</Table.Cell>
                          <Table.Cell textAlign="center">
                            
                            {allowedToPUT ?<Button
                              basic
                              circular
                              onClick={() => {
                                if (batteries && batteries.items) {
                                  setBatteryData(batteries);
                                }
                                setGroupName(user_group_name);
                                setEditData(sharingpoints.items);
                                setAllowedToForceSwap(allowed_to_force_swap);
                                setReservationRequestLimit(
                                  reservation_request_limit
                                );
                                setReservationLimit(reservation_limit);
                                setReservationInterval(reservation_interval);
                                setIsOpenCreateGroupModal(true);
                              }}
                              disabled={user_group_name === "overall_admin"}
                              icon="edit"
                              size="mini"
                              floated="left"
                            />: null}

                            <Button
                              title={
                                swapper_group_restriction_info &&
                                swapper_group_restriction_info.is_limited
                                  ? "reactivate swapper access"
                                  : "deactivate swapper access"
                              }
                              basic
                              circular
                              size="mini"
                              icon={
                                swapper_group_restriction_info &&
                                swapper_group_restriction_info.is_limited
                                  ? "checkmark"
                                  : "ban"
                              }
                              floated="left"
                              onClick={() => {
                                setGroupName(user_group_name);
                                setLimitedSwapAccess(
                                  swapper_group_restriction_info &&
                                    swapper_group_restriction_info.is_limited
                                    ? true
                                    : false
                                );
                                setIsOpenEditSwapperAccessModal(true);
                              }}
                            />

                            <Button
                              floated="left"
                              basic
                              size="mini"
                              circular
                              disabled={user_group_name === "overall_admin"}
                              onClick={() => {
                                setSelectedGroup(user_group_name);
                                setIsOpenDeleteGroupModal(true);
                              }}
                              icon="trash alternate"
                            />
                          </Table.Cell>
                        </TableRow>
                      )
                    )}
                </Table.Body>
              </Table>
            )}
          </GridColumn>
        </>
      )}

      <DeleteGroup
        isOpen={isOpenDeleteGroupModal}
        setIsOpen={setIsOpenDeleteGroupModal}
        group={selectedGroup}
      />
      {isOpenCreateGroupModal && (
        <CreateGroup
          {...{
            fetchUserGroups,
            setAllowedToForceSwap,
            allowedToForceSwap,
            reservationRequestLimit,
            reservationLimit,
            reservationInterval,
          }}
          batteryData={batteryData}
          groupName={groupName}
          editData={editData}
          isOpen={isOpenCreateGroupModal}
          setIsOpen={setIsOpenCreateGroupModal}
        />
      )}

      <EditSwapperGroupRestriction
        groupName={groupName}
        limitedSwapAccess={limitedSwapAccess}
        isOpen={isOpenEditSwapperAccessModal}
        setIsOpen={setIsOpenEditSwapperAccessModal}
        fetchUpdate={fetchUserGroups}
      />
    </Grid>
  );
};
