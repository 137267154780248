/** @format */

import React, { useState, useContext } from "react";
import { Modal, Confirm, TextArea, Form, Button } from "semantic-ui-react";
import { AxiosContext } from "../../context/AxiosContext";
import { restApiResponse } from "../../store/actions/restApiActions";
import { useDispatch } from "react-redux";
import { CustomButton } from "../CustomComponents";

const EditSwapperGroupRestriction = ({
  groupName,
  limitedSwapAccess,
  isOpen,
  setIsOpen,
  fetchUpdate,
}) => {
  const dispatch = useDispatch();
  const { axiosInstance } = useContext(AxiosContext);

  const [blockReason, setBlockReason] = useState("");
  const [loading, setLoading] = useState(false);

  const deactivateSwapperAccess = async () => {
    try {
      await axiosInstance.post(
        `/user_group/${groupName}/deactivate_swapper_permission`,
        {
          reason: blockReason,
        }
      );

      fetchUpdate();

      setIsOpen(false);

      dispatch(
        restApiResponse({
          message: "Swapper access is successfully deactivated",
          request_parameter: "",
          status_code: 200,
        })
      );
    } catch (error) {
      console.log("error", error);
      dispatch(
        restApiResponse({
          message: "Failed to deactivate swapper access",
          request_parameter: "",
          status_code: 500,
        })
      );
    }
  };

  const reactivateSwapperAccess = async () => {
    try {
      await axiosInstance.post(
        `/user_group/${groupName}/reactivate_swapper_permission`
      );
      fetchUpdate();
      setIsOpen(false);

      dispatch(
        restApiResponse({
          message: "Swapper access is successfully reactivated",
          request_parameter: "",
          status_code: 200,
        })
      );
    } catch (error) {
      console.log("error", error);
      dispatch(
        restApiResponse({
          message: "Failed to reactivate swapper access",
          request_parameter: "",
          status_code: 500,
        })
      );
    }
  };

  return limitedSwapAccess ? (
    <Confirm
      size="mini"
      open={isOpen}
      content={`Are you sure you want to reactivate the swapper access?`}
      header="Confirmation"
      onConfirm={() => {
        reactivateSwapperAccess(groupName);
      }}
      onCancel={() => {
        setIsOpen(false);
      }}
    />
  ) : (
    <Modal
      onClose={() => setIsOpen(false)}
      open={isOpen}
      style={{ width: 640 }}
    >
      <Modal.Header>{"Confirmation"}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {`Are you sure you want to limit ${groupName}'s swap access? Please note that 
            all the users under the user group will be limited too. Give the reason. `}
          <Form>
            <TextArea onChange={(_, { value }) => setBlockReason(value)} />
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <CustomButton
          onClick={() => {
            setBlockReason("");
            setIsOpen(false);
          }}
          title={"Cancel"}
          negative
        />

        <CustomButton
          primary
          title={"OK"}
          loading={loading}
          onClick={() => {
            setLoading(true);
            deactivateSwapperAccess();
            setBlockReason("");
            setLoading(false);
            setIsOpen(false);
          }}
          pri
          disabled={blockReason ? false : true}
        />
      </Modal.Actions>
    </Modal>
  );
};

const EditSwapperUserRestriction = ({
  username,
  limitedSwapAccess,
  isOpen,
  setIsOpen,
  fetchUpdate,
}) => {
  const dispatch = useDispatch();
  const { axiosInstance } = useContext(AxiosContext);

  const [blockReason, setBlockReason] = useState("");
  const [loading, setLoading] = useState(false);

  const deactivateSwapperAccess = async () => {
    try {
      await axiosInstance.post(
        `/cognito_users/${username}/deactivate_swapper_permission`,
        {
          reason: blockReason,
        }
      );

      fetchUpdate();

      setIsOpen(false);

      dispatch(
        restApiResponse({
          message: "Swapper access is successfully deactivated",
          request_parameter: "",
          status_code: 200,
        })
      );
    } catch (error) {
      console.log("error", error);
      dispatch(
        restApiResponse({
          message: "Failed to deactivate swapper access",
          request_parameter: "",
          status_code: 500,
        })
      );
    }
  };

  const reactivateSwapperAccess = async () => {
    try {
      await axiosInstance.post(
        `/cognito_users/${username}/reactivate_swapper_permission`
      );
      fetchUpdate();
      setIsOpen(false);

      dispatch(
        restApiResponse({
          message: "Swapper access is successfully reactivated",
          request_parameter: "",
          status_code: 200,
        })
      );
    } catch (error) {
      console.log("error", error);
      dispatch(
        restApiResponse({
          message: "Failed to reactivate swapper access",
          request_parameter: "",
          status_code: 500,
        })
      );
    }
  };

  return limitedSwapAccess ? (
    <Confirm
      size="mini"
      open={isOpen}
      content={`Are you sure you want to reactivate the swapper access?`}
      header="Confirmation"
      onConfirm={() => {
        reactivateSwapperAccess(username);
      }}
      onCancel={() => {
        setIsOpen(false);
      }}
    />
  ) : (
    <Modal
      onClose={() => setIsOpen(false)}
      open={isOpen}
      style={{ width: 640 }}
    >
      <Modal.Header>{"Confirmation"}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {`Are you sure you want to limit ${username}'s swap access? Please note that 
            the other users under the same user group wouldn't be affected. Give the reason. `}
          <Form>
            <TextArea onChange={(_, { value }) => setBlockReason(value)} />
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions style={{display: "flex", justifyContent: "flex-end", gap: "14px"}}>
        <CustomButton
          onClick={() => {
            setBlockReason("");
            setIsOpen(false);
          }}
          title={"Cancel"}
          negative
        />

        <CustomButton
          primary
          title={"OK"}
          loading={loading}
          onClick={() => {
            setLoading(true);
            deactivateSwapperAccess();
            setBlockReason("");
            setLoading(false);
            setIsOpen(false);
          }}
          positive
          disabled={blockReason ? false : true}
        />
      </Modal.Actions>
    </Modal>
  );
};

export { EditSwapperGroupRestriction, EditSwapperUserRestriction };
