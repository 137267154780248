import React, { useEffect, useState } from "react";
import Breadcrumbs from "../BreadCrumbs";
import MainHeader from "./MainHeader";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Layout = ({ children }) => {
  const session = useSelector((state) => state.session);
  const collapsed = useSelector((state) => {
    return state.appSettings.sidebarIsCollapsed;
  });

  const [lang, setLang] = useState();
  const { i18n } = useTranslation();

  const LANGUAGE = JSON.parse(localStorage.getItem("LANGUAGE"));

  useEffect(() => {
    if (!lang && LANGUAGE) {
      setLang(LANGUAGE);
    }
  }, [LANGUAGE]);

  useEffect(() => {
    if (lang) i18n.changeLanguage(lang);
  }, [lang]);
  return (
    <div className="layout">
      <MainHeader {...{ collapsed, lang, session, setLang }} />
      <Breadcrumbs />
      <div className="main-content">{children}</div>
      {/* <ScrollTopButton /> */}
    </div>
  );
};

export { Layout };
