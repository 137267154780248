import React from "react";
import "./style.scss"
import { MainLogo } from "../../components/NewLayout/MainLogo";
import { Loader } from "semantic-ui-react";

const SplashScreen = () => {
  return (
    <div className="splash-screen">
      <Loader active inline="centered" size="massive"/>
      <MainLogo/>
    </div>
  );
};

export default SplashScreen;
