/** @format */

import { configureStore, getDefaultMiddleware } from "redux-starter-kit";
import {
  acksReducer,
  addressesReducer,
  appSettingsReducer,
  batteriesReducer,
  companiesReducer,
  contactsReducer,
  locationsReducer,
  manufacturersReducer,
  ownersReducer,
  providersReducer,
  regionsReducer,
  registrationReducer,
  sessionReducer,
  sharingPointsReducer,
  techCVsReducer,
  uiReducer,
  notesReducer,
  filesReducer,
  statistcsReducer,
  errorHistoryReducer,
  restApiReducer,
} from "./reducers";
import createSocketMiddleware from "../middlewares/websocket";

// redux-starter-kit includes the redux-thunk middleware by default
const store = configureStore({
  reducer: {
    acks: acksReducer,
    addresses: addressesReducer,
    appSettings: appSettingsReducer,
    batteries: batteriesReducer,
    companies: companiesReducer,
    contacts: contactsReducer,
    files: filesReducer,
    locations: locationsReducer,
    manufacturers: manufacturersReducer,
    notes: notesReducer,
    owners: ownersReducer,
    providers: providersReducer,
    regions: regionsReducer,
    registration: registrationReducer,
    restApi: restApiReducer,
    session: sessionReducer,
    sharingPoints: sharingPointsReducer,
    techCVs: techCVsReducer,
    ui: uiReducer,
    errorHistory: errorHistoryReducer,
    statistics: statistcsReducer,
  },
  middleware: [...getDefaultMiddleware(), createSocketMiddleware],
});

export default store;

// used by next-redux-wrapper in _app.js
export const makeStore = () => {
  return store;
};
