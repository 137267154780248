import React from "react";
import * as L from "leaflet";
import "./../../assets/styles/leaflet.css";
import { Segment } from "semantic-ui-react";

let map = undefined;
const markerIcon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: require("./../../assets/images/Icon_active.svg"),
  shadowUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png",
});

class ReactMap extends React.Component {
  state = {
    tileUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    marker: null,
    zoom: 15,
    latitude: null,
    longitude: null,
  };

  constructor(props) {
    super(props);
    this.map = React.createRef();
    

  }

  componentDidUpdate(prevProps) {
    if (!this.props.latitude || !this.props.longitude) return;
    if (
      this.props.latitude === prevProps.latitude &&
      this.props.longitude === prevProps.longitude
    ) {
      return;
    }
    this.updateMap([this.props.latitude, this.props.longitude]);
  }

  componentDidMount() {
    try {
      map = L.map(this.map.current, {
        scrollWheelZoom: true,
        zoomControl: false,
      });
      let osmLayer = new L.TileLayer(this.state.tileUrl, {
        minZoom: 3,
        maxZoom: 20,
        attribution:
          'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
      });
      const latLng = this.props.latitude
        ? new L.LatLng(this.props.latitude, this.props.longitude)
        : new L.LatLng(52.4305068, 13.5328499);
      map.setView(latLng, 10);
      map.addLayer(osmLayer);
      map.on("zoomend", (e) => {
        this.setState({
          zoom: e.target._zoom,
        });
      });
      if (this.props.latLng) {
        this.updateMap(this.props.latLng);
      }
    } catch (e) {
      throw ("cant instantiate leaflet", e);
    }
  }

  updateMap(latLng) {
    const { changeLatLng } = this.props;

    let marker =
      this.state.marker ||
      L.marker(latLng, {
        draggable: true,
        title: "hover",
        opacity: 0.8,
        icon: markerIcon,
      }).addTo(map);
    this.setState({
      marker: marker,
    });
    marker.on("dragend", (e) => {
      let latLng = e.target._latlng;
      changeLatLng && changeLatLng(latLng);
    });
    let zoom = this.state.zoom || 9;
    marker.setLatLng(latLng);

    map.flyTo(latLng, zoom);
  }

  render() {
    return (
      <Segment
        style={{
          overflow: "hidden",
          height: "100%",
          padding: 0,
          borderRadius: 5,
        }}
      >
        <div
          className="map-wrapper"
          data-testid="map-wrapper"
          style={{ overflow: "hidden", height: "100%" }}
        >
          <div id="map" ref={this.map} />
        </div>
      </Segment>
    );
  }
}
export default ReactMap;
