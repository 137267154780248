/** @format */

import React, { useState, useEffect } from "react";
import axios from "axios";
import { InputFile } from "semantic-ui-react-input-file";
import {
  Grid,
  Button,
  Progress,
  Modal,
  Header,
  ModalContent,
  Input,
  Divider,
  GridColumn,
} from "semantic-ui-react";
import { useForm, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

const UploadForm = ({ onClose, uploadUrl, getUploadFileUrl, fetchFiles }) => {
  const { t } = useTranslation();

  const defaultValues = { files: [{ key: "", fileName: "", type: "" }] };
  const { register, handleSubmit, setValue, watch, control } = useForm({
    defaultValues,
  });
  const { append, remove } = useFieldArray({
    control,
    name: "files",
  });

  const [localFile, setLocalFile] = useState([]);
  const [uploadedProgress, setUploadedProgress] = useState();

  const handleUpload = (file, index) => {
    register(`files[${index}].key`);
    register(`files[${index}].fileName`);
    register(`files[${index}].type`);
    setLocalFile([...localFile, file]);
    const { type, name } = file;
    setValue(`fileName`, name);

    setValue(`files[${index}].key`, name);
    setValue(`files[${index}].fileName`, name);
    setValue(`files[${index}].type`, type);
    const reader = new FileReader();
    reader.addEventListener("load", () => {});
    reader.readAsText(file);
  };

  useEffect(() => {
    if (uploadUrl && localFile.length > 0) {
      localFile.forEach(item => {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-amz-acl": "private",
          },
        };
        axios
          .put(uploadUrl, item, config)
          .then(result => {
            onClose();
          })
          .catch(error => console.log("error with upload", error));
      });
    }
    fetchFiles();
    setLocalFile([]);
  }, [uploadUrl]);

  const onSubmit = () => {
    watchedFiles.forEach(item => {
      getUploadFileUrl(item, localFile);
    });
  };

  const watchedFiles = watch("files");

  return (
    <>
      <Modal.Header>
        <Header as="h3">{t("files.file_upload")}</Header>
      </Modal.Header>
      <ModalContent>
        <Grid centered>
          {watchedFiles.map((item, index) => (
            <>
              <Grid.Column width={6}>
                <InputFile
                  input={{
                    id: "input-control-id",
                    onChange: e => {
                      handleUpload(e.target.files[0], index);
                    },
                  }}
                />
              </Grid.Column>

              <Grid.Column width={8}>
                <Input
                  fluid
                  placeholder="file name..."
                  value={watchedFiles[index].fileName}
                  disabled
                />
              </Grid.Column>
              <Grid.Column width={2}>
                <Button
                  size="tiny"
                  basic
                  circular
                  icon="close"
                  onClick={() => {
                    remove(index);
                  }}
                  disabled={watchedFiles.length === 1}
                />
              </Grid.Column>
            </>
          ))}
          <GridColumn width="16" textAlign="center">
            <Button
              circular
              icon="plus"
              size="mini"
              content="Add field"
              basic
              onClick={() => append({ key: "", fileName: "", type: "" })}
            />
          </GridColumn>
        </Grid>

        {uploadedProgress && (
          <Progress
            percent={uploadedProgress}
            inverted
            color="orange"
            progress
          />
        )}
      </ModalContent>
      <Divider />

      <form className="ui form" onSubmit={handleSubmit(onSubmit)}>
        <Button
          style={{ marginRight: "14px", marginBottom: "14px" }}
          floated="right"
          type="submit"
          primary
          disabled={!localFile.length > 0}
        >
          Upload
        </Button>
      </form>
    </>
  );
};

export default UploadForm;
