import React, { createContext } from "react";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // Import CSS for Notyf

// Create an instance of Notyf

export const NotifyContext = createContext({
  showSuccessNotification: (message) => {},
  showErrorNotification: (message) => {},
});

export const NotifyProvider = ({ children }) => {
  const notyf = new Notyf({
    duration: 3000,
    position: { x: "left", y: "bottom" },
    
  });

  // Function to show a success notification
  const showSuccessNotification = (message) => {
    notyf.success(message || "Success! Operation was completed.");
  };

  // Function to show an error notification
  const showErrorNotification = (message) => {
    notyf.error(message || "Something went wrong. Please try again.");
  };

  return (
    <NotifyContext.Provider
      value={{
        showSuccessNotification,
        showErrorNotification,
      }}
    >
      {children}
    </NotifyContext.Provider>
  );
};
