/** @format */

import React from "react";
import { Button, ModalContent, Divider } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import "./style.scss";
import { useTranslation } from "react-i18next";

const DeleteFileForm = ({ file, onClose, fileToDelete }) => {
  const { t } = useTranslation();
  const { handleSubmit } = useForm({});

  const onSubmit = () => {
    fileToDelete({
      hash_key: file.hash_key,
      context_hash_key: file.context_hash_key,
      file_name: file.file_name,
    });
    onClose();
  };

  return (
    <>
      <ModalContent>{t("files.message_to_confirm")}</ModalContent>
      <Divider />
      <form className="ui form">
        <Button
          primary
          style={{ marginBottom: "14px", marginRight: "14px" }}
          onClick={handleSubmit(onSubmit)}
          floated="right"
          type="submit"
        >
          {t("files.button_delete")}
        </Button>
      </form>
    </>
  );
};

export default DeleteFileForm;
