import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AxiosContext } from "./AxiosContext";
import { toast } from "react-toastify";
import { StationContext } from "./StationContext";
import { NotifyContext } from "./NotfyContext";
import { navigate } from "@reach/router";

export const SwapContext = createContext({
  fetchSwapsBySwapId: (stationId) => {},
  isLoadingSwaps: false,
  swaps: [],
});

export const SwapProvider = ({ children }) => {
  const [swaps, setSwaps] = useState([]);
  const [swap, setSwap] = useState();

  const [isLoadingSwaps, setIsLoadingSwaps] = useState(false);
  const [isLoadingSwap, setIsLoadingSwap] = useState(false);


  const { axiosInstance } = useContext(AxiosContext);
  const { station } = useContext(StationContext);
  const { showErrorNotification, showSuccessNotification } =
    useContext(NotifyContext);

  const fetchSwapsByStationId = useCallback(
    async (stationId) => {
      try {
        setIsLoadingSwaps(true);
        const { data } = await axiosInstance.get(
          "/swaps/swap_actions?station=" + stationId
        );
        setSwaps(data.data);
        setIsLoadingSwaps(false);
      } catch (error) {
        console.log(error);

        setIsLoadingSwaps(false);
        showErrorNotification(
          `Failed to fetch swap: ${error.response?.data?.message}`
        );
      }
    },
    [axiosInstance, showErrorNotification]
  );

  const fetchSwapById = useCallback(
    async (swapId) => {
      try {
        setIsLoadingSwap(true)
        const { data } = await axiosInstance.get("/swaps/" + swapId);
        console.log("swaps", data.data);
        setSwap(data.data);
        setIsLoadingSwap(false)
      } catch (error) {
        toast.error(error.response?.data?.message);
        // navigate("/statistics");
        showErrorNotification(
          `Failed to fetch swap: ${error.response?.data?.message}`
        );
        setIsLoadingSwap(false)
        navigate("/statistics")
      }
    },
    [axiosInstance, setIsLoadingSwap, showErrorNotification]
  );

  useEffect(() => {
    if (!station) return;
    fetchSwapsByStationId(station.sp_serial_number);

    return () => {};
  }, [fetchSwapsByStationId, station]);

  return (
    <SwapContext.Provider
      value={{
        fetchSwapsByStationId,
        isLoadingSwaps,
        swaps,
        fetchSwapById,
        swap,
        isLoadingSwap,
        setSwap
      }}
    >
      {children}
    </SwapContext.Provider>
  );
};
