/** @format */

import React, { useState, useEffect, useMemo, useContext } from "react";
import {
  Dropdown,
  GridColumn,
  Button,
  Grid,
  Loader,
  Icon,
} from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import { useTranslation } from "react-i18next";
import "moment/locale/de";
import {
  BatteryTypeDropdownSearch,
  GroupsDropdownSearch,
  LocationDropdownSearch,
  SharingPointDropdownSearch,
  UsersDropdownSearch,
} from "../UtilComponents";
import moment from "moment";
import NivopBarPlot from "./NivopBarPlot";
import NivopLinePlot from "./NivopLinePlot";
import { AxiosContext } from "../../context/AxiosContext";

export default () => {
  const { t } = useTranslation();
  const groupByOptions = [
    { key: "day", value: "day", text: t("statistic.day") },
    { key: "week", value: "week", text: t("statistic.week") },
    { key: "month", value: "month", text: t("statistic.month") },
    { key: "year", value: "year", text: t("statistic.year") },
    { key: "location", value: "location", text: "Location" },
    { key: "city", value: "city", text: "City" },
    { key: "country", value: "country", text: "Country" },
  ];
  const [selectedUsername, setSelectedUsername] = useState();
  const [selectedUserGroupName, setSelectedUserGroupName] = useState();
  const [plotType, setPlotType] = useState(["bar"]);

  const [battery_type, setSelectedBatteryType] = useState();
  const [sp_serial_number, setSelectedStation] = useState();

  const [lower, setLower] = useState();
  const [upper, setUpper] = useState();
  const [location, setSelectedLocation] = useState();
  const [statsData, setSwapStatisticData] = useState();
  const [group_by, setGroupBy] = useState();
  const [isLoading, setLoading] = useState();
  const trs = useTranslation("descriptions");

  const init_date = useMemo(() => {
    var start_time = moment().toDate();
    var end_time = moment().toDate();
    start_time.setMonth(start_time.getMonth() - 6);
    start_time.setHours(0);
    start_time.setMinutes(0);
    start_time.setSeconds(0);
    start_time.setMilliseconds(0);
    
    let lower = new Date(start_time).getTime();
    end_time.setDate(end_time.getDate() + 1);
    end_time.setHours(0);
    end_time.setMinutes(0);
    end_time.setSeconds(0);
    end_time.setMilliseconds(0);
    let upper = new Date(end_time).getTime() -1 ;
    return {lower, upper };
  }, []);
  const { axiosInstance } = useContext(AxiosContext);
  const fetchStats = async () => {
    setLoading(true);
    
    try {
      const params = {
        created_by: selectedUsername,
        user_group_name: selectedUserGroupName,
        battery_type,
        station: sp_serial_number,
        lower: lower
          ? moment(lower, "DD/MM/YYYY").toDate().getTime()
          : init_date.lower,
        upper: upper
          ? moment(upper, "DD/MM/YYYY").toDate().getTime() + 86400000
          : init_date.upper,
        location_id: location,
        group_by,
      };

      const { data } = await axiosInstance.get("/swap-statistics", {
        params,
      });
      setSwapStatisticData(data.data);

    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchStats();
  }, []);


  return (
    <>
      <GridColumn
        width={16}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <span style={{ color: "#444", fontSize: 18 }}>
          Swaps by battery type graph
        </span>
      </GridColumn>
      <GridColumn style={{ paddingTop: 0 }} width="12">
        <span style={{ color: "#687078" }}>
          {trs.t("swap_order_chart_overview")}
        </span>
      </GridColumn>

      <GridColumn width="16" verticalAlign="top">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
            }}
          >
            <div className="swap-table-filter-container">
              <div className="filter-components">
                <Dropdown
                  placeholder="Group by"
                  clearable
                  selection
                  fluid
                  options={groupByOptions}
                  onChange={(_, { value }) => {
                    setGroupBy(value);
                  }}
                />
              </div>
              <div className="filter-components">
                <SharingPointDropdownSearch
                  {...{
                    setSelectedStation,
                    sp_serial_number,
                  }}
                />
              </div>

              <div className="filter-components">
                <BatteryTypeDropdownSearch
                  {...{
                    setSelectedBatteryType,
                    battery_type,
                  }}
                />
              </div>

              <div className="filter-components">
                <UsersDropdownSearch
                  {...{
                    selectedUsername,
                    setSelectedUsername,
                    className: "filter-components",
                  }}
                />
              </div>
            </div>
            <div className="swap-table-filter-container">
              <div className="filter-components">
                <DateInput
                  localization="de"
                  clearable
                  animation="fade"
                  name="date"
                  placeholder={"Start"}
                  value={lower}
                  dateFormat="DD-MM-YYYY"
                  iconPosition="left"
                  maxDate={new Date()}
                  onChange={(_, { value }) => setLower(value)}
                  closeOnMouseLeave
                />
              </div>

              <div className="filter-components">
                <DateInput
                  localization="de"
                  clearable
                  animation="fade"
                  name="date"
                  placeholder={"End"}
                  value={upper}
                  dateFormat="DD-MM-YYYY"
                  iconPosition="left"
                  onChange={(_, { value }) => setUpper(value)}
                  maxDate={new Date()}
                  closeOnMouseLeave
                />
              </div>

              <div className="filter-components">
                <LocationDropdownSearch
                  {...{ setSelectedLocation, location }}
                />
              </div>

              <div className="filter-components">
                <GroupsDropdownSearch
                  {...{
                    selectedUserGroupName,
                    setSelectedUserGroupName,
                    width: "20%",
                    marginRight: 14,
                  }}
                />
              </div>
            </div>
          </div>

          <Button
            style={{ width: "8%", height: 37, margin: 5 }}
            icon="search"
            size="mini"
            basic
            onClick={() => fetchStats()}
          />
        </div>
      </GridColumn>
      <GridColumn width="16" textAlign="center">
        {isLoading ? (
          <Loader inline active size="big" />
        ) : (
          <Grid>
            <Plot
              statsData={statsData}
              {...{ plotType, battery_type, group_by }}
            />
          </Grid>
        )}
      </GridColumn>
    </>
  );
};

const colorMapping = {
  greenpack: "#E8C1A0",
  torrot: "#F47560",
  kumpan: "#61CDBB",
  "okai a": "#F1E15B",
  "okai b": "#9867C5",
  segway: "#003f5c",
  "aes ebike akku 2.0 48v": "##ff6361",
};

const Plot = ({ statsData, group_by }) => {
  const { t } = useTranslation();
  return (
    <GridColumn style={{ height: 1400 }}>
      <GridColumn width={16}>
        {statsData && (
          <NivopBarPlot
            {...{
              colorMapping,
              data: statsData.new_lib_template,
              keys: statsData.keys,
              xLabel: group_by ? group_by : "day",
              yLabel: "battery out",
            }}
          />
        )}
      </GridColumn>

      <GridColumn width={16}>
        {statsData && (
          <NivopLinePlot
            {...{
              data: statsData.line_plot_data,
              xLabel: group_by ? group_by : "day",
              yLabel: "battery out",
            }}
          />
        )}
      </GridColumn>
    </GridColumn>
  );
};
