/** @format */

import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "@reach/router";
import { isAllowedToRead } from "../../helper/permissions";
import { GridColumn, Container } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { AxiosContext } from "../../context/AxiosContext";
import {
  RESOURCE_METHODS,
  SWOBBEE_RESOURCES,
} from "../../constants/app-constants";
import { AssetsContext } from "../../context";

interface SharingPointDetailTabProps {
  locationPath?: string;
}

const SubNav = ({ locationPath }: SharingPointDetailTabProps) => {
  const { t } = useTranslation("sharingPointOverview");
  const [subLocation, setSubLocation] = useState<string | null | undefined>();
  const [countNotes, setCountNotes] = useState<number>(0);
  const [countFiles, setCountFiles] = useState<number>(0);
  const { axiosInstance } = useContext(AxiosContext);

  const fetchNotes = async (spSerialNumber: string) => {
    try {
      const { data } = await axiosInstance.get(
        `/notes/${spSerialNumber}?context=SharingPoints`
      );
      setCountNotes(data.data.length);
    } catch (error) {
      console.log("notes error", error);
    }
  };

  const fetchFiles = async (spSerialNumber: string) => {
    try {
      let params = {
        context: "SharingPoints",
        context_hash_key: spSerialNumber,
      };
      const { data } = await axiosInstance.get("files", { params });
      setCountFiles(data.data.length);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (!locationPath) return;
    let index = locationPath.split("/");
    fetchNotes(index[2].replaceAll("%20", " "));
    fetchFiles(index[2].replaceAll("%20", " "));
    const pathes = locationPath.split("/");

    if (pathes.length === 3) {
      setSubLocation("");
    } else {
      const last = pathes.pop();
      setSubLocation(last);
    }
  }, [locationPath]);

  const linkObjects = [
    {
      to: "",
      name: "",
      linkText: t("global_header.overview"),

      resource: SWOBBEE_RESOURCES.SHARINGPOINTS,
    },
    {
      to: "swaps",
      name: "swaps",
      linkText: t("global_header.swaps"),

      resource: SWOBBEE_RESOURCES.SWAPS,
    },
    {
      to: "interactions",
      name: "interactions",
      linkText: t("global_header.interactions"),

      resource: SWOBBEE_RESOURCES.ERROR_HISTORY,
    },
    {
      to: "settings",
      name: "settings",
      linkText: t("global_header.settings"),

      resource: SWOBBEE_RESOURCES.STATION_SETTINGS,
    },
    {
      to: "notes",
      name: "notes",
      linkText: `${t("global_header.notes")} (${countNotes})`,

      resource: SWOBBEE_RESOURCES.NOTES,
    },
    {
      to: "files",
      name: "files",
      linkText: `${t("global_header.files")} (${countFiles})`,

      resource: SWOBBEE_RESOURCES.FILES,
    },

    {
      to: "error-history",
      name: "error-history",
      linkText: t("global_header.error"),

      resource: SWOBBEE_RESOURCES.ERROR_HISTORY,
    },
  ];

  const { isAllowedToAccess, userAllowedToAccess } = useContext(AssetsContext);

  const filteredPanes = useMemo(() => {
    if (!userAllowedToAccess) return;
    const res = linkObjects.filter((item) => {
      return isAllowedToAccess(item.resource, RESOURCE_METHODS.GET);
    });
    console.log("res", res);
    return res;
  }, [userAllowedToAccess, isAllowedToAccess, linkObjects]);

  const navigationItems = useMemo(() => {
    if (!filteredPanes) return null;
    return (
      <nav className="sub-nav">
        {filteredPanes.map((linkObject, index) => {
          return (
            <div className="sub-nav-link" key={index}>
              <Link
                key={linkObject.name}
                to={linkObject.to}
                className={subLocation === linkObject.name ? "active" : ""}
              >
                {linkObject.linkText}
              </Link>
            </div>
          );
        })}
      </nav>
    );
  }, [subLocation, filteredPanes]);

  return (
    <Container columns="1" fluid>
      <GridColumn>
        <div
          style={{ boxShadow: "0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5" }}
          className="sub-nav-wrapper"
        >
          {navigationItems}
        </div>
      </GridColumn>
    </Container>
  );
};
export { SubNav };
