/** @format */

import React, { useState, useEffect, useMemo, useContext } from "react";
import {
  Modal,
  ModalHeader,
  ModalContent,
  Grid,
  GridRow,
  GridColumn,
  Button,
  Form,
  Segment,
  Message,
  FormField,
  FormGroup,
  Dropdown,
  Divider,
  Header,
} from "semantic-ui-react";

import { useForm, useFieldArray, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { AxiosContext } from "../../context/AxiosContext";

import { CustomButton } from "../CustomComponents";

import { UserManagementContext } from "../../context";

const validationSchema = yup.object().shape({
  user_group: yup.string().required(),
  segments: yup.array().of(
    yup.object().shape({
      email: yup.string().email().required(),
      firstName: yup.string().required(),
      lastName: yup.string().required(),
    })
  ),
});

export default ({ user_groups }) => {
  const [batteryTypeIds, setBatteryTypeIds] = useState([]);
  const { createUser, isCreateUserLoading, showUserForm, setShowUserForm } =
    useContext(UserManagementContext);
  const [userGroupCategory, setUserGroupCategory] = useState([]);
  const { axiosInstance } = useContext(AxiosContext);

  const fetchBatteryTypeIds = async () => {
    const { data } = await axiosInstance.get("/battery_type_ids");
    setBatteryTypeIds(data.data);
  };

  const defaultValues = {
    segments: [
      {
        email: "",
        firstName: "",
        lastName: "",
        subscription_battery_type: "",
        amount: "",
      },
    ],
    user_group: "",
  };

  const { register, errors, handleSubmit, control, watch } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "segments",
  });

  const segmentsWatched = watch("segments", []);

  const subInfoIsSet = useMemo(() => {
    return segmentsWatched.every(
      (segment) => segment.subscription_battery_type && segment.amount
    );
  }, [segmentsWatched]);

  useEffect(() => {
    if (showUserForm) {
      fetchBatteryTypeIds();
      return;
    }
  }, []);

  const mapUserGroupCategory = useMemo(() => {
    if (!user_groups) return [];

    const result = user_groups.reduce((acc, item) => {
      acc[item.value] = item;
      return acc;
    }, {});
    return result;
  }, [user_groups]);
  const showSubscriptionInfo = useMemo(() => {
    return ["vaas", "baas"].includes(userGroupCategory);
  }, [userGroupCategory]);

  return (
    <Modal
      open={showUserForm}
      onClose={() => setShowUserForm(false)}
      onOpen={() => {
        fetchBatteryTypeIds();
        setShowUserForm(true);
      }}
      size="small"
    >
      <ModalHeader>Invite Users</ModalHeader>
      <ModalContent scrolling>
        <Grid>
          <GridColumn width={16}>
            <Message
              icon="info"
              size="tiny"
              header="Information"
              content="Note that you can add multiple users and assign them to a designated user group. Once you submit the information, each user will receive an onboarding email containing their login credentials."
              info
            />
          </GridColumn>
          <GridColumn width="16">
            <Form onSubmit={handleSubmit(createUser)}>
              <Controller
                control={control}
                name="user_group"
                selection
                render={({ onChange, value }) => (
                  <FormField>
                    <label>User Group</label>
                    <Dropdown
                      fluid
                      search
                      clearable
                      selection
                      defaultValue={value}
                      placeholder="User Group"
                      options={user_groups}
                      onChange={(e, data) => {
                        setUserGroupCategory(
                          mapUserGroupCategory[data?.value]?.data
                            ?.user_group_category
                        );
                        onChange(data.value);
                      }}
                    />
                  </FormField>
                )}
              />
              {errors && errors.user_group && (
                <span style={{ color: "#8B0000" }}>
                  Please choose a User Group
                </span>
              )}
              <GridColumn with="16">
                <Grid columns="1">
                  {fields.map((item, index) => {
                    return (
                      <GridColumn width="16">
                        <Segment key={item.id}>
                          <Header>User Info</Header>
                          <FormGroup>
                            <FormField width="5">
                              <label>Email</label>
                              <input
                                fluid
                                type="email"
                                placeholder="Email"
                                name={`segments[${index}].email`}
                                defaultValue={`${item.email}`}
                                ref={register()}
                              />
                              {errors.segments &&
                                errors.segments[index] &&
                                errors.segments[index].email && (
                                  <span style={{ color: "#8B0000" }}>
                                    Please enter a valid email address
                                  </span>
                                )}
                            </FormField>

                            <FormField width="5">
                              <label>Firstname</label>
                              <input
                                fluid
                                type="text"
                                placeholder="Firstname"
                                name={`segments[${index}].firstName`}
                                defaultValue={`${item.firstName}`}
                                ref={register()}
                              />
                              {errors.segments &&
                                errors.segments[index] &&
                                errors.segments[index].firstName && (
                                  <span style={{ color: "#8B0000" }}>
                                    A first name is required
                                  </span>
                                )}
                            </FormField>
                            <FormField width="5">
                              <label>Lastname</label>
                              <input
                                fluid
                                type="text"
                                placeholder="Lastname"
                                name={`segments[${index}].lastName`}
                                defaultValue={`${item.lastName}`}
                                ref={register()}
                              />
                              {errors.segments &&
                                errors.segments[index] &&
                                errors.segments[index].lastName && (
                                  <span style={{ color: "#8B0000" }}>
                                    A last name is required
                                  </span>
                                )}
                            </FormField>
                            <FormField width="1">
                              <Button
                                style={{ marginTop: "25px" }}
                                icon="close"
                                basic
                                circular
                                floated="right"
                                size="mini"
                                disabled={index === 0}
                                onClick={() => remove(index)}
                              />
                            </FormField>
                          </FormGroup>
                          <Divider></Divider>
                          {["vaas", "baas"].includes(userGroupCategory) && (
                            <>
                              <Header>Subscription</Header>
                              <FormGroup>
                                <Controller
                                  control={control}
                                  name={`segments[${index}].subscription_battery_type`}
                                  selection
                                  render={({ onChange, value }) => (
                                    <FormField width="8">
                                      <label>Battery Type</label>
                                      <Dropdown
                                        fluid
                                        search
                                        clearable
                                        selection
                                        defaultValue={value}
                                        placeholder="Battery Type"
                                        options={batteryTypeIds}
                                        onChange={(e, data) =>
                                          onChange(data.value)
                                        }
                                      />
                                    </FormField>
                                  )}
                                />
                                <FormField width="8">
                                  <label>Amount</label>
                                  <input
                                    fluid
                                    type="number"
                                    placeholder="Amount"
                                    name={`segments[${index}].amount`}
                                    defaultValue={`${item.amount}`}
                                    ref={register()}
                                  />
                                </FormField>
                              </FormGroup>
                            </>
                          )}
                        </Segment>
                      </GridColumn>
                    );
                  })}
                </Grid>
              </GridColumn>

              <GridColumn style={{ paddingTop: "20px" }}>
                <Button
                  type="button"
                  circular
                  size="small"
                  onClick={() =>
                    append({
                      email: "",
                      firstName: "",
                      lastName: "",
                    })
                  }
                  icon="plus"
                  basic
                  content="Add"
                />
              </GridColumn>
              <Grid>
                <GridRow>
                  <GridColumn
                    textAlign="right"
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 10,
                    }}
                  >
                    <CustomButton
                      onClick={() => setShowUserForm(false)}
                      style={{ background: "#fff", color: "#444" }}
                      title="Cancel"
                    />

                    <CustomButton
                      type="submit"
                      disabled={showSubscriptionInfo && !subInfoIsSet}
                      title="Submit"
                      isLoading={isCreateUserLoading}
                    />
                  </GridColumn>
                </GridRow>
              </Grid>
            </Form>
          </GridColumn>
        </Grid>
      </ModalContent>
    </Modal>
  );
};
