import React, { useContext } from "react";
import PropTypes from "prop-types";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import { Loader } from "semantic-ui-react";
import { AssetsContext } from "../../../context";

const propTypes = {
  latLng: PropTypes.array,
};
delete L.Icon.Default.prototype._getIconUrl;

const minZoom = 4;
const maxZoom = 20;
const tileUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const attribution =
  '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';

const SimpleMap = ({ latLng }) => {
  const { CustomMarker } = useContext(AssetsContext);

  return latLng ? (
    <MapContainer
      center={latLng}
      zoom={15}
      style={{ height: "100%", borderRadius: "5px" }}
      zoomControl={false}
    >
      <TileLayer
        attribution={attribution}
        url={tileUrl}
        minZoom={minZoom}
        maxZoom={maxZoom}
      />

      <Marker icon={CustomMarker()} position={latLng} />
    </MapContainer>
  ) : (
    <Loader active inline size="large" />
  );
};
SimpleMap.propTypes = propTypes;
export { SimpleMap };
