import React, { useContext, useMemo } from "react";
import {
  Grid,
  Checkbox,
  Form,
  Button,
  Popup,
  Dropdown,
  Label,
} from "semantic-ui-react";
import { Link } from "@reach/router";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import Tooltip from "rc-tooltip";
import { Controller } from "react-hook-form";
import _ from "lodash";
import { AssetsContext } from "../../context/AssetsContext";

const Handle = Slider.Handle;

const FormRow = ({
  name,
  disabled,
  control,
  watch,
  isEditing,
  targetSettingMetaData,
  currentLanguage,
  setValue,
  slotName,
  settingAttrKey,
}) => {
  const label = useMemo(() => {
    const settingName =
      targetSettingMetaData.display_name[currentLanguage] ||
      targetSettingMetaData.display_name.en;
    return slotName ? slotName + " " + settingName : settingName;
  }, [targetSettingMetaData, currentLanguage, slotName]);
  const { mainColor } = useContext(AssetsContext);
  const hint = useMemo(() => {
    return (
      targetSettingMetaData.setting_description[currentLanguage] ||
      targetSettingMetaData.setting_description.en
    );
  }, [targetSettingMetaData, currentLanguage]);

  const type = targetSettingMetaData.setting_type;
  const min = targetSettingMetaData.min_value || null;
  const max = targetSettingMetaData.max_value || null;
  const step = targetSettingMetaData.value_step_size || null;
  const unit = targetSettingMetaData.unit_name || "";
  const dropdownOptions = targetSettingMetaData.dropdown_options || [];

  // Get current value from react-hook-form watch
  const value = watch(name);

  return (
    <Grid className="form-row">
      <Grid.Column width="4" textAlign="right" verticalAlign="middle">
        {/* <label style={{ color: "black" }} htmlFor={name}>
          {label}
        </label> */}
        <Popup
          hoverable
          position="top right"
          mouseEnterDelay={500}
          style={{ fontSize: "12px" }}
          trigger={
            <label style={{ color: "black" }} htmlFor={name}>
              {label}
            </label>
          }
          content={settingAttrKey}
        />
      </Grid.Column>
      <Grid.Column width="11" textAlign="left" verticalAlign="middle">
        {control && (
          <Controller
            control={control}
            name={name}
            render={({ onChange, value }) => (
              <>
                {isEditing && (
                  <>
                    {type === "checkbox" || type === "boolean" ? (
                      <Checkbox
                        slider
                        id={name}
                        aria-label={name}
                        data-testid={name}
                        name={name}
                        value={value}
                        checked={value}
                        onChange={(e, data) => onChange(data.checked)}
                        disabled={disabled}
                      />
                    ) : type === "textArea" ||
                      type === "text" ||
                      type === "string" ? (
                      <Form.TextArea
                        style={{ width: "100%" }}
                        data-testid={name}
                        aria-label={name}
                        rows="1"
                        id={name}
                        onChange={(e, data) => onChange(data.value)}
                        value={value}
                        disabled={disabled}
                      />
                    ) : type === "number" ? (
                      <NumberInput
                        min={min}
                        max={max}
                        control={control}
                        name={name}
                        step={step}
                        unit={unit}
                        label={label}
                        hint={hint}
                        watch={watch}
                        setValue={setValue}
                      />
                    ) : type === "dropdown" ? (
                      <Dropdown
                        options={dropdownOptions}
                        defaultValue={value}
                        selection
                      />
                    ) : type === "list" ? (
                      <Dropdown
                        options={dropdownOptions}
                        defaultValue={value}
                        onChange={(e, data) => onChange(data.value)}
                        fluid
                        multiple
                        selection
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
                {!isEditing && (
                  <>
                    <div>
                      {type === "list" ? (
                        Array.isArray(value) &&
                        value.map((item, index) => (
                          <Link key={index} to={`/sharingpoints/${item}`}>
                            <Label
                              circular
                              style={{
                                margin: "5px",
                                backgroundColor: mainColor,
                                color: "white",
                              }}
                            >
                              {item}
                            </Label>
                          </Link>
                        ))
                      ) : (
                        <strong style={{ color: mainColor }}>
                          {_.isUndefined(value) ? "None" : `${value} ${unit}`}
                        </strong>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          />
        )}
      </Grid.Column>

      <Grid.Column width="1" textAlign="right">
        <Popup
          trigger={
            <Button
              size="mini"
              icon="info"
              circular
              basic
              onClick={(e) => e.preventDefault()}
            />
          }
          position="right center"
          content={hint}
        />
      </Grid.Column>
    </Grid>
  );
};

const NewFormRowBackUp = ({
  name,
  disabled,
  control,
  watch,
  isEditing,
  targetSettingMetaData,
  currentLanguage,
  setValue,
}) => {
  const { mainColor } = useContext(AssetsContext);

  const label = useMemo(() => {
    if (targetSettingMetaData.display_name[currentLanguage]) {
      return targetSettingMetaData.display_name[currentLanguage];
    } else {
      return targetSettingMetaData.display_name.en;
    }
  }, [targetSettingMetaData, currentLanguage]);

  const hint = useMemo(() => {
    if (targetSettingMetaData.setting_description[currentLanguage]) {
      return targetSettingMetaData.setting_description[currentLanguage];
    } else {
      return targetSettingMetaData.setting_description.en;
    }
  }, [targetSettingMetaData, currentLanguage]);

  const type = targetSettingMetaData.setting_type;

  const min = targetSettingMetaData["min_value"]
    ? targetSettingMetaData.min_value
    : null;

  const max = targetSettingMetaData["max_value"]
    ? targetSettingMetaData.max_value
    : null;

  const step = targetSettingMetaData["value_step_size"]
    ? targetSettingMetaData.value_step_size
    : null;

  const unit = targetSettingMetaData["unit_name"]
    ? targetSettingMetaData.unit_name
    : null;

  const value = watch(name);

  return (
    <Grid className="form-row">
      <Grid.Column
        width="4"
        htmlFor={name}
        textAlign="right"
        verticalAlign="middle"
      >
        <label style={{ color: "black" }} htmlFor={name}>
          {label}
        </label>
      </Grid.Column>
      <Grid.Column width="11" textAlign="left" verticalAlign="middle">
        {control && isEditing && (
          <>
            {type === "number" ? (
              <NumberInput
                min={min}
                max={max}
                control={control}
                name={name}
                step={step}
                unit={unit}
                label={label}
                hint={hint}
                watch={watch}
                setValue={setValue}
              />
            ) : (
              ""
            )}
          </>
        )}
        {!isEditing && (
          <strong style={{ color: mainColor }}>
            {_.isUndefined(value) ? "None" : value + " "}
            {_.isUndefined(unit) ? "" : unit}
          </strong>
        )}
      </Grid.Column>

      <Grid.Column width="1" textAlign="right">
        <Popup
          trigger={
            <Button
              size="mini"
              icon="info"
              circular
              basic
              onClick={(e) => e.preventDefault()}
            />
          }
          position="right center"
          content={hint}
        />
      </Grid.Column>
    </Grid>
  );
};

const NumberInput = ({
  control,
  min,
  max,
  name,
  step,
  disabled,
  unit,
  watch,
  setValue,
}) => {
  const watchedValue = watch(name);
  const { mainColor } = useContext(AssetsContext);
  function generateSliderMarks(min, max) {
    let marks = {};
    let interval = (max - min) / 10;
    for (let i = 0; i <= 10; i++) {
      let value = min + interval * i;
      // Check if value is an integer
      if (Number.isInteger(value)) {
        marks[value] = value;
      } else {
        value = Math.round(value * 10) / 10; // Round to 1 decimal place
        marks[value] = value;
      }
    }
    return marks;
  }

  return (
    <Grid>
      <>
        <Grid.Column width="14">
          <Controller
            control={control}
            name={name}
            render={(
              { onChange, value, name },
              { invalid, isTouched, isDirty }
            ) => {
              return (
                <div>
                  <Slider
                    id={name}
                    ariaLabelForHandle={name}
                    ariaLabel={name}
                    max={max}
                    min={min}
                    defaultValue={value}
                    value={value}
                    handle={SliderTooltip}
                    step={step}
                    onChange={onChange}
                    marks={generateSliderMarks(min, max)}
                    disabled={disabled}
                  />
                </div>
              );
            }}
          />
        </Grid.Column>
        <Grid.Column width="2" textAlign="right">
          <strong style={{ color: mainColor }}>
            {_.isUndefined(watchedValue) ? "None" : watchedValue}
            {_.isUndefined(unit) ? "" : unit}
          </strong>
        </Grid.Column>
      </>
    </Grid>
  );
};

const NumberInputInBoxes = ({
  control,
  min,
  max,
  name,
  step,
  disabled,
  unit,
  watch,
  setValue,
}) => {
  const watchedValue = watch(name);

  function generateSliderMarks(min, max) {
    let marks = {};
    let interval = (max - min) / 10;
    for (let i = 0; i <= 10; i++) {
      let value = min + interval * i;
      marks[value] = value;
    }
    return marks;
  }

  const { mainColor } = useContext(AssetsContext);

  return (
    <Grid>
      <>
        <Grid.Column width="14">
          <Controller
            control={control}
            name={name}
            render={(
              { onChange, value, name },
              { invalid, isTouched, isDirty }
            ) => {
              return (
                <div>
                  <Slider
                    id={name}
                    ariaLabelForHandle={name}
                    ariaLabel={name}
                    max={max}
                    min={min}
                    defaultValue={value}
                    value={value}
                    handle={SliderTooltip}
                    step={step}
                    onChange={onChange}
                    marks={generateSliderMarks(min, max)}
                    disabled={disabled}
                  />
                </div>
              );
            }}
          />
        </Grid.Column>
        <Grid.Column width="2" textAlign="right">
          <strong style={{ color: mainColor }}>
            {_.isUndefined(watchedValue) ? "None" : watchedValue}
            {_.isUndefined(unit) ? "" : unit}
          </strong>
        </Grid.Column>
      </>
    </Grid>
  );
};

const SliderTooltip = (props) => {
  const { value, dragging, index, ...restProps } = props;

  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

export default FormRow;
