import React, { createContext, useCallback, useContext, useState } from "react";
import { AxiosContext } from "./AxiosContext";
import { NotifyContext } from "./NotfyContext";

export const CommandsContext = createContext();

export const CommandsProvider = ({ children }) => {
  const { axiosInstance } = useContext(AxiosContext);
  const { showSuccessNotification, showErrorNotification } =
    useContext(NotifyContext);

  const [showBlockNoteModal, setShowBlockNoteModal] = useState(false);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [blockNote, setBlockNote] = useState("");
  const [blockSlotLoading, setBlockSlotLoading] = useState(false);
  const [resetBatteryErrorLoading, setResetBatteryErrorLoading] = useState(false);


  const blockSlot = useCallback(
    async (
      spSerialNumber,
      boxNumber,
      slotNumber,
      blockedNote,
      slotDoorBlocked
    ) => {
      try {
        setBlockSlotLoading(true);
        console.log("payload", {
          sp_serial_number: spSerialNumber,
          box_number: boxNumber,
          slot_number: slotNumber,
          note: blockedNote,
          door_blocked: !slotDoorBlocked,
        });
        const { data } = await axiosInstance.post(`/commands`, {
          request: "blockSlot",
          data: {
            sp_serial_number: spSerialNumber,
            box_number: boxNumber,
            slot_number: slotNumber,
            note: blockedNote,
            door_blocked: !slotDoorBlocked,
          },
        });
        setBlockSlotLoading(false);
        setShowBlockNoteModal(false);

        showSuccessNotification(data.message);
      } catch (error) {
        let response = {
          message: error.response.data.message,
          status_code: error.response.data.status_code,
        };
        setBlockSlotLoading(false);
        setShowBlockNoteModal(false);
        showErrorNotification(response.message);
      }
    },
    [axiosInstance, showErrorNotification, showSuccessNotification]
  );

  const resetBatteryErrors = useCallback(
    async (spSerialNumber, boxNumber, slotNumber) => {
      try {
        setResetBatteryErrorLoading(true)
        const { data } = await axiosInstance.post(
          `/commands`,
          {
            request: "resetBattErrors",
            data: {
              sp_serial_number: spSerialNumber,
              charge_module: boxNumber,
              battery: slotNumber
            }
          }
        );

        showSuccessNotification(data.message);
        setResetBatteryErrorLoading(false)
      } catch (error) {
        let response = {
          message: error.response?.data?.message,
          status_code: error.response?.data?.status_code,
        };
        showErrorNotification(response.message);
        setResetBatteryErrorLoading(false)
      }
    },
    [axiosInstance, showErrorNotification, showSuccessNotification]
  );

  return (
    <CommandsContext.Provider
      value={{
        blockSlot,
        resetBatteryErrors,
        showBlockNoteModal,
        setShowBlockNoteModal,
        blockModalOpen,
        setBlockModalOpen,
        blockNote,
        setBlockNote,
        blockSlotLoading,
        setBlockSlotLoading,
        resetBatteryErrorLoading
      }}
    >
      {children}
    </CommandsContext.Provider>
  );
};
