/** @format */

import React, { useState, useMemo, useEffect } from "react";
import _ from "lodash";
import "./style.scss";
import { useFormatTimestamp } from "../../hooks/use-format-timestamp";
import {
  Button,
  Icon,
  Modal,
  GridColumn,
  Table,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Header,
  ModalHeader,
  GridRow,
  Loader,
} from "semantic-ui-react";
import { formatTimestamp } from "../../helper/sp-utils";
import DeleteFileForm from "./DeleteFileForm";
import UploadForm from "./Fileform";
import { isAllowedToWrite } from "../../helper/permissions";
import { useTranslation } from "react-i18next";

function FilesTable(props) {
  const {
    files,
    fileToDelete,
    getDownloadFileUrl,
    uploadUrl,
    getUploadFileUrl,
    fetchFiles,
  } = props;
  const { t } = useTranslation();
  const timeFormat = "DDxMMxYY";
  const filterTypes = ["timestamp", "creator", "file_name"];

  const [filterType, setFilterType] = useState(filterTypes[1]);

  const [filterValue, setFilterValue] = useState();

  const filteredFiles = useMemo(() => {
    if (!files) return [];
    else {
      if (!filterValue) return files;
      let filtered = files;
      if (filterType === "timestamp")
        filtered = filtered.filter(
          (file) =>
            formatTimestamp(file.timestamp, timeFormat) ===
            formatTimestamp(new Date(filterValue).getTime(), timeFormat)
        );
      if (filterType === "creator" || filterType === "file_name")
        filtered = filtered.filter((file) => {
          return (
            file[filterType] &&
            file[filterType].toUpperCase().includes(filterValue.toUpperCase())
          );
        });
      return filtered;
    }
  }, [filterValue, filterType, files]);

  const [selectedFile, setSelectedFile] = useState(false);

  return (
    <>
      <Modal
        style={{ width: "500px" }}
        onClose={() => setSelectedFile(false)}
        open={selectedFile}
        closeIcon
      >
        <UploadForm
          fetchFiles={fetchFiles}
          uploadUrl={uploadUrl}
          getUploadFileUrl={getUploadFileUrl}
          onClose={() => setSelectedFile(false)}
        />
      </Modal>
      <GridRow style={{ padding: "20px" }}>
        <GridColumn width="8">
          <Header style={{ float: "left" }}>
            {filteredFiles.length} Files
          </Header>
        </GridColumn>

        {filteredFiles && (
          <GridColumn width="8">
            <Button
              style={{ float: "right" }}
              onClick={() => setSelectedFile(true)}
              icon="plus"
              basic
              size="mini"
              circular
            />
          </GridColumn>
        )}
      </GridRow>

      <GridColumn width="16" textAlign="center" style={{ padding: "10px" }}>
        {/*<Loader size="large" inline active />*/}
        {filteredFiles.length > 0 ? (
          <TableComponent
            {...{
              fileToDelete,
              getDownloadFileUrl,
              filteredFiles,
            }}
          />
        ) : (
          <GridColumn
            width="16"
            textAlign="center"
            style={{ textAlign: "center" }}
          >
            <span style={{ fontColor: "#333", color: "#555", margin: "auto" }}>
              {" "}
              <Icon name="search" /> {t("files.no_files_available")}
            </span>
          </GridColumn>
        )}
      </GridColumn>
    </>
  );
}

export default FilesTable;

const TableComponent = ({
  fileToDelete,
  getDownloadFileUrl,
  filteredFiles,
}) => {
  const [state, setState] = useState({
    column: null,
    data: null,
    direction: null,
    searchResults: null,
    searchTerm: null,
    last_evaluated_key: null,
  });

  const { column, data, direction } = state;
  const { timeSpans } = useFormatTimestamp();
  const [deleteFile, setDeleteFile] = useState(false);

  useEffect(() => {
    setState({
      ...state,
      data: filteredFiles,
      searchResults: filteredFiles,
    });
    return () => {};
  }, [filteredFiles]);

  const sortColumn = (column) => {
    if (state.column === column) {
      return setState({
        ...state,
        data: state.data.slice().reverse(),
        direction: state.direction === "ascending" ? "descending" : "ascending",
      });
    }
    return setState({
      ...state,
      column,
      data: _.sortBy(state.data, [column]),
      direction: "ascending",
    });
  };

  return (
    <>
      {data && (
        <>
          {data.length > 0 && (
            <>
              {data && (
                <Table sortable fixed>
                  <TableHeader>
                    <TableRow>
                      <TableHeaderCell
                        className="left-header"
                        sorted={column === "file_name" ? direction : null}
                        onClick={() => sortColumn("file_name")}
                      >
                        name
                      </TableHeaderCell>
                      <TableHeaderCell
                        className="inner-header"
                        sorted={column === "battery_count" ? direction : null}
                        onClick={() => sortColumn("battery_count")}
                      >
                        uploaded by
                      </TableHeaderCell>
                      <TableHeaderCell
                        className="inner-header"
                        sorted={column === "battery_type" ? direction : null}
                        onClick={() => sortColumn("battery_type")}
                      >
                        uploaded at
                      </TableHeaderCell>
                      <TableHeaderCell className="right-header">
                        actions
                      </TableHeaderCell>
                    </TableRow>
                  </TableHeader>
                  <Table.Body>
                    {data.map(
                      ({
                        file_name,
                        creator,
                        timestamp,
                        hash_key,
                        context_hash_key,
                      }) => (
                        <TableRow>
                          <Table.Cell>{file_name}</Table.Cell>
                          <Table.Cell>{creator}</Table.Cell>
                          <Table.Cell>{timeSpans(timestamp)}</Table.Cell>
                          <Table.Cell>
                            <Button
                              icon="download"
                              circular
                              primary
                              value={hash_key}
                              onClick={() =>
                                getDownloadFileUrl({ hash_key: hash_key })
                              }
                              size="tiny"
                            />
                            <Button
                              icon="trash"
                              basic
                              onClick={() =>
                                setDeleteFile({
                                  file_name,
                                  creator,
                                  context_hash_key,
                                  timestamp,
                                  hash_key,
                                })
                              }
                              circular
                              size="tiny"
                            />
                          </Table.Cell>
                        </TableRow>
                      )
                    )}
                  </Table.Body>
                </Table>
              )}

              <Modal
                size="mini"
                closeIcon
                onClose={() => setDeleteFile(false)}
                onOpen={() => setDeleteFile(true)}
                open={deleteFile}
              >
                <ModalHeader>
                  <Header as="h3">Confirmation</Header>
                </ModalHeader>
                <DeleteFileForm
                  onClose={() => setDeleteFile(false)}
                  file={deleteFile}
                  fileToDelete={fileToDelete}
                />
              </Modal>

              {data && (
                <>
                  {data.length === 0 && (
                    <GridColumn
                      style={{ height: "200px" }}
                      textAlign="center"
                      verticalAlign="middle"
                      width="16"
                    >
                      <Header as="h4" style={{ marginTop: "190px" }}>
                        No data available
                      </Header>
                    </GridColumn>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
